<template>
  <div class="text-center mt-4">

    <div class="row my-5">
      <div class="col">
        <div class="py-5 text-center text-muted">
          <icon :icon="['fal', 'compass']" size="4x" class="mb-3" />
          <h2>Location access is required!</h2>
        </div>
      </div>
    </div>

    <div class="row my-5">
      <div class="col">
        <div class="py-2 text-center text-muted">
          <p>To enable location services for the Risky app:</p>
          <p>
            <ul v-if="isIOS" style="padding-inline-start: 0px; list-style: none">
              <li>1. Go to your device Settings > Safari</li>
              <li>2. Go to Settings for Websites, and select Location</li>
              <li>3. Set location access to 'Allow'</li>
              <li>4. Refresh this page</li>
            </ul>
            <ul v-if="!isIOS" style="padding-inline-start: 0px; list-style: none">
              <li>1. Tap the padlock on the left of the address bar</li>
              <li>2. Set Location permissions to 'Allow'</li>
              <li>3. Refresh this page</li>
            </ul>
          </p>
          <button
              @click="$router.go(-1)"
              type="button"
              class="tw-mb-2 lg:tw-w-auto tw-w-full tw-inline-flex tw-justify-center tw-py-3 tw-px-4 tw-rounded-md tw-bg-gray-100 tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500 hover:tw-bg-gray-200 hover:tw-text-gray-600 focus:tw-outline-none focus:tw-border-gray-300 focus:tw-shadow-outline-gray tw-transition tw-duration-150 tw-ease-in-out"
            >
              Go back
            </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { isSafari, isIOS, isMobileSafari } from 'mobile-device-detect';

export default {
  name: "RequestLocationPermission",
  props: [],
  components: {},
  data() {
    return {};
  },
  computed: {
    isIOS: function() {
      if (isSafari || isIOS || isMobileSafari ){
        return true
      }
    }},
  mounted() {
    const successCallback = (postion) => {
      // once location permission is restored, redirect to "/incidents/new" route
      this.$router.push("/incidents/new");
      };
    const failureCallback = (postion) => {
      // Do nothing. User must change device settings
    };

    // Call navigator.geolocation.getCurrentPosition to trigger browser's permission API
    navigator.geolocation.getCurrentPosition(
        successCallback,
        failureCallback
      );
  },
  methods: {}
};
</script>

<style>
</style>
