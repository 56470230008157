<template>
    <div
        id="status"
        class="tw-mr-1"
    >
        <span
            v-if="status == true"
            class="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium tw-bg-red-100 tw-text-red-800"
        >
            Open
        </span>
        <span
            v-if="status == false"
            class="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium tw-bg-green-100 tw-text-green-800"
        >
            Resolved
        </span>
    </div>
</template>


<script>
export default {
    name: "EventStatus",
    props: ['closed_at'],
    computed: {
        status() {
            if (this.$props.closed_at === null) {
                return true
            } else {
                return false
            }
        }
    },
};
</script>
