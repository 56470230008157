<template>
    <TableContainer>
        <template slot="header">
            <TableHeaderItem>
                <span class="lg:tw-pl-2">Name</span>
            </TableHeaderItem>
            <TableHeaderItem class="tw-whitespace-nowrap">
                Risk level
            </TableHeaderItem>
            <TableHeaderItem class="tw-whitespace-nowrap">
                Reaction
            </TableHeaderItem>
            <TableHeaderItem v-if="hasFeatureEnabled('incident_resolve')">
                Status
            </TableHeaderItem>
            <TableHeaderItem>
                Reporter
            </TableHeaderItem>
            <TableHeaderItem>
                Opened
            </TableHeaderItem>
            <TableHeaderItem></TableHeaderItem>
        </template>
        <template slot="rows">
            <TableRow v-if="loading">
                <TableField colspan="6" class="tw-text-center tw-py-12">
                    <clip-loader :loading="loading" color="#63c2de"></clip-loader>
                </TableField>
            </TableRow>
            <TableRow v-if="!loading && incidents.length === 0">
                <TableField colspan="6" class="tw-text-center">
                    <ZeroIncidents/>
                </TableField>
            </TableRow>
            <TableRow 
                v-for="incident in incidents"
                :key="incident.id" 
                class="tw-group"
                @click.stop="$router.push({ name: 'Incident', params: { eventID: incident.id}})"
            >
                <TableField class="tw-max-w-0 tw-w-full">
                    <div class="tw-flex tw-items-center tw-space-x-3 lg:tw-pl-2">
                       <div
                        v-if="incident.image_url"
                        class="tw-flex-shrink-0 tw-rounded-md tw-w-11 tw-h-9 tw-mt-1 tw-mr-2 tw-bg-center tw-bg-gray-200 tw-bg-no-repeat tw-bg-cover focus:tw-outline-none tw-hidden xl:tw-block"
                        :style="{ backgroundImage: `url(${incident.image_url})` }"
                        ></div>
                        <div 
                            v-else 
                            class="tw-flex-shrink-0 tw-rounded-md tw-w-11 tw-h-9 tw-mt-1 tw-mr-2 tw-bg-gray-200 tw-bg-center tw-bg-no-repeat tw-bg-cover focus:tw-outline-none tw-hidden xl:tw-block"
                            :style="{ backgroundImage: `url(${incident.map_url})` }"
                        >
                        </div>
                            <span class="tw-truncate group-hover:tw-text-orange-500">
                                {{ incident.title }}
                                <span class="tw-ml-2"><EventTags :tags="incident.tags"></EventTags></span>
                            </span>
                    </div>
                </TableField>
                <TableField class="">
                    <EventSeverityIndicator :severity="incident.severity" :label="true"></EventSeverityIndicator>
                </TableField>
                <TableField class="text-center">
                    <EventReaction :reaction="incident.reaction" :size="25" class="tw-mx-auto tw-mt-1.5"></EventReaction>
                </TableField>
                <TableField v-if="hasFeatureEnabled('incident_resolve')">
                    <EventStatusTag :closed_at="incident.closed_at "></EventStatusTag>
                </TableField>
                <TableField>
                    <EventContributorAvatars :reporter="incident.created_user" :size="30" class="tw-text-center tw-mx-auto"></EventContributorAvatars>
                </TableField>
                <TableField>
                    {{ incident.time_ago }}
                </TableField>
                <TableField>
                    <div class="tw-relative tw-flex tw-justify-end tw-items-center">
                        <EventActionDropdownV2 :incident_id="incident.id" @downloadPDF="getPDFReport(incident.id)"></EventActionDropdownV2>
                    </div>
                </TableField>
            </TableRow>
        </template>
    </TableContainer>
</template>

<script>
import { mapGetters } from "vuex";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import EventTags from "@/components/Events/Meta/EventTags";
import EventStatusTag from "@/components/Events/Meta/EventStatusTag";
import ZeroIncidents from "@/components/Events/ZeroData/ZeroIncidents";
import EventSeverityIndicator from "@/components/Events/Meta/EventSeverityIndicator";
import EventContributorAvatars from "@/components/Events/Meta/EventContributorAvatars";
import EventActionDropdownV2 from "@/components/Events/Actions/EventActionDropdownV2";
import SeverityDropdown from "@/components/Inputs/SeverityDropdown";
import TableContainer from '@/components/ui/Table/TableContainer.vue';
import TableHeaderItem from "@/components/ui/Table/TableHeaderItem.vue"
import TableRow from "@/components/ui/Table/TableRow.vue"
import TableField from "@/components/ui/Table/TableField.vue"
import EventReaction from './Meta/EventReaction.vue';

export default {
    name: "events-desktop",
    props: ["loading", "incidents"],
    components: {
        ClipLoader,
        EventTags,
        EventStatusTag,
        EventSeverityIndicator,
        EventReaction,
        EventContributorAvatars,
        EventActionDropdownV2,
        SeverityDropdown,
        TableContainer,
        TableHeaderItem,
        TableRow,
        TableField,
        ZeroIncidents
    },
    data() {
        return {
            columns: [
                { label: 'Name', field_name: 'name', feature_flag: null },
                { label: 'Severity', field_name: 'name', feature_flag: null },
                { label: 'Reaction', field_name: 'reaction', feature_flag: null },
                { label: 'Status', field_name: 'name', feature_flag: 'incident_resolve' },
                { label: 'Reporter', field_name: 'reporter', feature_flag: null },
                { label: 'Since', field_name: 'reporter', feature_flag: null },
            ]
        }
    },
    computed: {
        ...mapGetters("user", ["hasFeatureEnabled"]),
    },
    methods: {
        async getPDFReport(incident_id) {
            this.$http({
                url: `${process.env.VUE_APP_API_ROOT}incidents/${incident_id}/pdf/`,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.setAttribute("download", "report.pdf");
                document.body.appendChild(fileLink);
                fileLink.click();
            })
                .catch(error => {
                    console.log(error);
                });
        },
        clickTableRow(event, to) {
            this.$router.push(to);
        }
    }
};
</script>

<style>

</style>