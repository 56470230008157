<template>
   <div>
       <div>
        <div class="tw-mx-auto tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-full tw-bg-green-100">
          <!-- Heroicon name: check -->
          <svg class="tw-h-6 tw-w-6 tw-text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
        </div>
        <div class="tw-mt-3 tw-text-center sm:tw-mt-5">
          <h3 class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900" id="modal-headline">
            Feedback sent
          </h3>
          <div class="tw-mt-2">
            <p class="tw-text-sm tw-text-gray-500">
              Thank you for submitting your feedback. If you have requested support, a member of our team will be in touch with you shortly. 
            </p>
          </div>
        </div>
      </div>
      <div class="tw-mt-5 sm:tw-mt-6">
        <button @click="$emit('close')" type="button" class="tw-w-full tw-inline-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-base tw-font-medium tw-text-gray-800 tw-bg-gray-100 hover:tw-bg-gray-200 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-orange-500">
          Close
        </button>
      </div>
    </div>
</template>

<script>
export default {};
</script>

<style>
</style>