var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-hidden lg:tw-flex lg:tw-flex-shrink-0"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-w-64"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-h-0 tw-flex-1 tw-bg-black-800"},[_c('div',{staticClass:"tw-flex-1 tw-flex tw-flex-col tw-pt-5 tw-pb-4 tw-overflow-y-auto"},[_c('div',{staticClass:"tw-flex tw-items-center tw-flex-shrink-0 tw-px-7"},[_c('router-link',{staticClass:"tw-text-4xl tw-font-extralight focus:tw-outline-none",attrs:{"to":"/"}},[_c('span',{staticClass:"tw-text-orange-500"},[_vm._v("risky.")]),_c('span',{staticClass:"tw-text-white"},[_vm._v("live")])])],1),_c('nav',{staticClass:"tw-flex tw-flex-col tw-mt-5 tw-px-2 tw-bg-black-800 tw-h-full"},[_c('div',{staticClass:"tw-flex-1 tw-flex-grow tw-space-y-1"},_vm._l((_vm.navItems),function(item){return _c('router-link',{key:item.name,staticClass:"tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-sm tw-rounded-md",class:[
                _vm.currentRoute.includes(item.url)
                  ? _vm.activeClasses
                  : _vm.inactiveClasses,
              ],attrs:{"to":item.to}},[_c('icon',{staticClass:"tw-mx-3 tw-h-6 tw-w-6",class:[
                  _vm.currentRoute.includes(item.url)
                    ? _vm.iconActiveClasses
                    : _vm.iconInactiveClasses,
                ],attrs:{"icon":item.icon,"fixed-width":""}}),_c('span',{class:[
                  _vm.currentRoute.includes(item.url)
                    ? _vm.textActiveClasses
                    : _vm.textInactiveClasses,
                ]},[_vm._v(_vm._s(item.name))])],1)}),1),_c('div',{staticClass:"tw-flex-shrink-0 tw-space-y-1"},[_c('button',{staticClass:"tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-sm tw-rounded-md tw-w-full tw-text-gray-300 hover:tw-bg-black-200 hover:tw-text-white focus:tw-outline-none",on:{"click":function($event){return _vm.$modal.show()}}},[_c('icon',{staticClass:"tw-mx-3 tw-h-6 tw-w-6",attrs:{"icon":['fal', 'bullhorn'],"fixed-width":""}}),_c('span',{},[_vm._v("Have feedback?")])],1),_c('router-link',{staticClass:"tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-sm tw-rounded-md",class:[
                _vm.currentRoute.includes('support')
                  ? _vm.activeClasses
                  : _vm.inactiveClasses,
              ],attrs:{"to":{ name: 'Help & support' }}},[_c('icon',{staticClass:"tw-mx-3 tw-h-6 tw-w-6",class:[
                  _vm.currentRoute.includes('support')
                    ? _vm.iconActiveClasses
                    : _vm.iconInactiveClasses,
                ],attrs:{"icon":['fal', 'question-circle'],"fixed-width":""}}),_c('span',{class:[
                  _vm.currentRoute.includes('support')
                    ? _vm.textActiveClasses
                    : _vm.textInactiveClasses,
                ]},[_vm._v("Help & support")])],1)],1)])]),_c('div',{staticClass:"tw-px-3 tw-mb-4 tw-relative tw-inline-block tw-text-left"},[_c('ProfileDropdown',[_c('ProfileDropdownContent',[_c('div',{staticClass:"tw-py-1"},[_c('span',{staticClass:"tw-block tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-text-gray-500",attrs:{"role":"menuitem"}},[_vm._v("Switch team")]),_c('div',{staticClass:"tw-pb-1"},[_c('ProfileDropdownTeamsList')],1)]),_c('div',{staticClass:"tw-border-t tw-border-gray-100"}),_c('div',{staticClass:"tw-py-1"},[_c('ProfileDropdownItem',{attrs:{"to":{name: 'Profile'}}},[_vm._v("Account & Password")]),_c('ProfileDropdownItem',{attrs:{"to":{name: 'Logout'}}},[_vm._v("Sign out")])],1)])],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }