<template>
  <div class="animated fadeIn">
    <div class="row mb-3">
      <div class="col">
        <div v-if="isLoading" class="text-center py-5">
          <pulse-loader :loading="isLoading" color="#63c2de"></pulse-loader>
        </div>
        <div v-else>
          <button
            v-for="team in teams"
            :key="team.id"
            type="button"
            class="btn btn-light btn-block pt-3 pb-2 mb-3"
            v-bind:class="{ 'disabled' : isLoading }"
            @click="switchCurrentTeam(team.id)"
          >
            <h4>
              <i v-if="team.selected" class="fas fa-check-circle float-left"></i>
              {{ team.name }}
            </h4>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "switch-team",
  components: {
    PulseLoader
  },
  data() {
    return {
      teams: "",
      team_id: "",
      errors: "",
      isLoading: true
    };
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      this.postMessage();
    },
    async fetchTeams() {
      try {
        let uri = process.env.VUE_APP_API_ROOT + "teams/";
        const response = await axios.get(uri);
        this.teams = response.data.results;
        this.isLoading = false;
      } catch (e) {
        // catch exception
        console.log(e);
      }
    },
    async switchCurrentTeam(teamID) {
      this.isLoading = true;
      this.$store.dispatch('user/switchTeam', teamID).then((res) => {
        this.isLoading = false;
        this.$router.push({name: 'Incidents'})
      })
      .catch((error) => {
        // handle authentication and validation errors here
        this.errors = error.response.data.errors;
        this.isLoading = false;
      })
    }
  },
  created() {
    this.fetchTeams();
  }
};
</script>
