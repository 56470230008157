<template lang="html">
<transition 
        enter-active-class="tw-transition tw-ease-out tw-duration-300"
        enter-class="tw-opacity-0"
        enter-to-class="tw-opacity-100"
        appear
        >
  <div>
    <FormTextField
        name="name"
        label="Team name"
        :value="name"
        @textchange="updateValue"
        :error="errors.name"
        class="tw-my-8"
    />

    <FormTextField
        name="basic_info"
        label="Basic Info"
        text_type="long"
        :value="basic_info"
        @textchange="updateValue"
        :error="errors.basic_info"
        class="tw-my-8"
    />

    <FormActions
        @submit="validateForm"
        :loading="loading"
        @cancelchanges="refreshValues"
    />
  </div>
  </transition>
</template>

<script>
import FormTextField from "@/vue-prism/components/form/FormTextField.vue";
import FormActions from "@/vue-prism/components/form/FormActions.vue";

export default {
  name: "TeamDetailsForm",
  components: {
    FormTextField,
    FormActions
  },
  props: {
    team: {
      type: Object,
      default: () => ({
        name: "",
        basic_info: ""
      })
    }
  },
  data: function () {
    return {
      name: this.team.name,
      basic_info: this.team.basic_info,
      errors: {},
      loading: false
    }
  },
  methods: {
    updateValue(name, value) {
      this[name]  = value;
    },
    refreshValues() {
      this.errors = {};
      this.name = this.team.name || "";
      this.basic_info = this.basic_info || "";
      this.$emit("cancel");
    },
    validateForm() {
      this.errors = {};
      let errors = {};

      const name_regex = /^[\.|\d|@|-|*|$|/]/;

      if (!this.name || name_regex.test(this.name)) {
        errors.name = "invalid team name";
      }

      if (Object.keys(errors).length > 0) {
        this.errors = errors;
      } else {
        this.postForm();
      }
    },
    postForm() {
      if (this.loading) return;
      this.loading = true;

      this.$store.dispatch("user/updateTeam", {...this.$data})
          .then((response) => {
            // potential updates here
            this.refreshValues()
          })
          .catch((error) => {
            // set global error alert
            if (error.response) {
              const { data } = error.response.data;

              /** 405 error could be handled better **/
              Object.keys(data).map(field => {
                this.errors[field] = data[field][0];
              })
            }
          })
          .finally(() => {
            this.loading = false;
          });
    }
  }
}
</script>

<style lang="css" scoped>
</style>
