<template>
  <span @click="addMessage()">
      <span class="text-muted align-top" style="font-size: 16px">Add message...</span>
      <!-- <icon
        :icon="['fal','arrow-circle-up']"
        fixed-width
        class="text-primary ml-2 float-right"
        style="font-size: 1.5rem;"
      /> -->
    </span>
</template>

<script>
export default {
  name: "AddMessageNavItem",
  methods: {
    addMessage() {
      this.$router.push({
        name: "Add comment",
        params: { eventID: this.$route.params.eventID }
      });
    }
  }
};
</script>

<style>
</style>