<template>
  <div class="tw-w-full">
    <b-form-radio-group
      buttons
      id="risk_level"
      v-model="selected"
      name="reaction"
      class="d-flex"
      @input="setReaction"
    >
      <b-form-radio
        v-for="(reaction, i) in options"
        button-variant="outline-light"
        :value="reaction"
        v-bind:key="i"
      >
        <emoji
          :data="index"
          :emoji="reaction"
          :size="20"
          set="apple"
          class="align-top"
          style="padding: 0"
        />
      </b-form-radio>
    </b-form-radio-group>
    <p
      class="tw-mt-2 tw-text-sm tw-text-red-600"
      v-if="isError && error"
      style="margin-bottom: 0 !important;"
    >
      {{ error }}
    </p>
  </div>
</template>


<script>
import data from "emoji-mart-vue-fast/data/all.json";
import { Emoji, EmojiIndex } from "emoji-mart-vue-fast";
import "emoji-mart-vue-fast/css/emoji-mart.css";

export default {
  name: "ChooseReactionInput",
  props: ["validate", "reaction"],
  components: {
    Emoji,
  },
  data() {
    return {
      selected: null,
      index: new EmojiIndex(data),
      options: [
        ":slightly_smiling_face:",
        ":slightly_frowning_face:",
        ":neutral_face:",
        ":thinking_face:",
        ":fearful:",
        ":triumph:",
      ],
      error: "",
      isError: false,
      fieldName: "reaction",
    };
  },
  watch: {
    validate(newValue, oldValue) {
      if (newValue === true) {
        this.validateField();
        this.$emit("updateReaction", this.selected);
      }
    },
  },
  methods: {
    setReaction(option) {
      this.validateField();
      this.$emit("updateReaction", option);
    //   this.$emit("nextStep");
    },
    validateField() {
      if (
        this.selected === null ||
        this.selected === "" ||
        this.selected.length === 0
      ) {
        //   Check if a tag is set
        this.isError = true;
        this.error = "Please select your reaction.";
        this.$emit("updateErrors", { name: this.fieldName, error: true });
      } else {
        //   Clear errors
        this.isError = false;
        this.error = "";
        this.$emit("updateErrors", { name: this.fieldName, error: false });
      }
    },
  },
};
</script>
