<template>
  <div class="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto">
    <button
      v-for="team in teams"
      v-bind:key="team.id"
      @click="switchCurrentTeam(team.id)"
      type="button"
      class="tw-w-full focus:tw-outline-none tw-flex tw-items-center tw-text-gray-300 hover:tw-bg-black-200 hover:tw-text-white tw-px-2 tw-py-2 tw-text-base tw-font-light tw-rounded-md"
    >
      <span class="tw-truncate tw-text-gray-300"># {{ team.name }}</span>
      <span
        v-if="team.id === currentTeamId && changingTeam !== team.id"
        class="tw-w-2.5 tw-h-2.5 tw-ml-auto tw-bg-orange-500 tw-rounded-full"
        aria-hidden="true"
      ></span>
      <clip-loader
        :loading="changingTeam === team.id"
        color="#63c2de"
        size="15px"
        class="tw-ml-auto"
      ></clip-loader>
    </button>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  components: {
    ClipLoader,
  },
  data() {
    return {
      changingTeam: null,
    };
  },
  computed: {
    teams() {
      return this.$store.getters["user/teams"];
    },
    currentTeamId() {
      if (this.$store.getters["user/currentTeam"]) {
        return this.$store.getters["user/currentTeam"].id;
      }
      return undefined;
    },
  },
  methods: {
    async switchCurrentTeam(teamID) {
      if (teamID !== this.currentTeamId) {
        this.changingTeam = teamID;
        this.$store
          .dispatch("user/switchTeam", this.changingTeam)
          .then((res) => {
            this.changingTeam = null;
            if (this.$route.name !== "Incidents") {
              this.$router.push({ name: "Incidents" });
            } else {
              this.$store.commit("closeSidebar");
            }
          })
          .catch((error) => {
            // handle authentication and validation errors here
            console.log(error);
            this.changingTeam = null;
          });
      } else {
        this.$store.commit("closeSidebar");
      }
    },
  },
};
</script>

<style>
</style>