<template>
  <span class="text-dark">CE {{ ce }}%</span>
</template>


<script>
export default {
  name: "EventCERating",
  props: ["ce"],
  computed: {}
};
</script>
