<template>
  <p
  class="tw-absolute tw-left-1.5 tw-top-full tw-px-2 tw-text-sm tw-text-red-500 tw-rounded-b"
  :class="{'tw-invisible': is_visible}"
  >
    {{error_message}}
  </p>
</template>

<script>

export default {
    name: "invalid-input",
    props: ["message", "show"],
    components: {

    },
    computed: {
      "error_message": function() {
        return this.message || "Invalid input";
      },
      "is_visible": function() {
        return !this.show;
      }
    },
    methods: {

    },
};
</script>
