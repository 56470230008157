<template>
  <div class="timeline">
    <table class="table table-borderless table-hover">
      <tbody v-if="isLoading">
        <tr>
          <td colspan="2">
            <vcl-bullet-list v-if="isMobileOrTablet()"></vcl-bullet-list>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <TimelineItem v-for="item in activity" :key="item.id" :item="item"></TimelineItem>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { VclBulletList } from "vue-content-loading";
import Avatar from "vue-avatar";
import TimelineItem from "@/components/Activity/TimelineItem";

export default {
  name: "EventActivityCard",
  props: ["event_pk", "solution"],
  components: {
    VclBulletList,
    Avatar,
    TimelineItem
  },
  data() {
    return {
      activity: [],
      errors: "",
      isLoading: true
    };
  },
  methods: {
    async fetchActivity() {
      try {
        // console.log(this.$props.event_pk)
        if (this.$props.event_pk) {
          var uri = process.env.VUE_APP_API_ROOT + "incidents/" + this.$props.event_pk + "/activity/";
        } else {
          var uri = process.env.VUE_APP_API_ROOT + "activity/";
        }
        const response = await axios.get(uri);
        this.activity = response.data.results;
        this.isLoading = false;
      } catch (e) {
        // catch exception
        console.log(e);
      }
    }
  },
  created() {
      console.log('tes2t')
    this.fetchActivity();
  }
};
</script>
