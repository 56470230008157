import Activity from "@/views/Activity/Activity";
import AddMessage from "@/views/Activity/AddMessage";

const routes = [
  {
    path: "",
    name: "Activity",
    component: Activity,
    meta: {
      title: "Activity"
    }
  },
  {
    path: "add",
    name: "Add message",
    component: AddMessage,
    meta: {
      title: "Add message"
    }
  },
  {
    path: "add/:eventID",
    name: "Add message to event",
    component: AddMessage
  }
]

export default routes;
