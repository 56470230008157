<template>
  <div class="tw-z-10 tw-relative tw-inline-block tw-text-left">
    <div>
      <button
        @click="isOpen = !isOpen"
        @blur="close()"
        type="button"
        class="btn-gray"
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
      >
        Actions
        <svg
          class="tw--mr-1 tw-ml-2 tw-h-5 tw-w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>

    <transition
      enter-active-class="tw-transition tw-ease-out tw-duration-75"
      enter-class="tw-transform tw-opacity-0 tw-scale-95"
      enter-to-class="tw-transform tw-opacity-100 tw-scale-100"
      leave-active-class="tw-transition tw-ease-in tw-duration-75"
      leave-class="tw-transform tw-opacity-100 tw-scale-100"
      leave-to-class="tw-transform tw-opacity-0 tw-scale-95"
    >
      <div
        v-show="isOpen"
        class="tw-origin-top-right tw-absolute tw-right-0 tw-mt-2 tw-w-56 tw-shadow-lg tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-divide-y tw-divide-gray-100"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        <div class="tw-py-1">
          <router-link
            :to="{ name: 'Add message to event', params: { eventID: this.$route.params.eventID } }"
            class="group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
            role="menuitem"
          >
            <icon :icon="['fal','comment']" fixed-width class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"/> 
            Add message
          </router-link>
          <router-link
            :to="{ name: 'Upload image', params: { eventID: this.$route.params.eventID } }"
            class="group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
            role="menuitem"
          >
            <icon :icon="['fal','image']" fixed-width class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"/> 
            Upload image
          </router-link>
          <router-link
            :to="{ name: 'Change severity', params: { eventID: this.$route.params.eventID } }"
            class="group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
            role="menuitem"
          >
            <icon :icon="['fal','exchange-alt']" rotation="90" class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"/> 
            Change risk level
          </router-link>
          <router-link
            v-if="hasFeatureEnabled('incident_resolve') && incident.closed_at === null"
            :to="{ name: 'Mark resolved', params: { eventID: this.$route.params.eventID } }"
            class="group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-green-400 hover:tw-bg-gray-100 hover:tw-text-green-600"
            role="menuitem"
          >
            <icon :icon="['fal','check-double']" class="tw-mr-3 tw-h-5 tw-w-5 tw-text-green-400 group-hover:tw-text-green-500"/> 
            Resolve
          </router-link>
        </div>
        <div class="tw-py-1">
          <router-link
            :to="{ name: 'Rename', params: { eventID: this.$route.params.eventID } }"
            class="group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
            role="menuitem"
          >
            <icon :icon="['fal','pen']" fixed-width class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"/> 
            Edit name
          </router-link>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["incident"],
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
      ...mapGetters("user", ["hasFeatureEnabled"]),
  },
  methods: {
      close() {
        setTimeout(() => {
            this.isOpen = false;
        }, 200);
    }
  }
};
</script>

<style>
</style>