<template>
  <main class="tw-flex-1 tw-relative tw-z-0 tw-mt-8 lg:tw-mt-0 tw-mb-0 lg:tw-mb-0" tabindex="-1">
    <slot name="header" />
    <div class="tw-px-4 sm:tw-px-6 md:tw-px-8 tw-mt-6 tw-mb-16">
      <slot></slot>
    </div>
  </main>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader/PageHeader.vue";

export default {
  components: {
    PageHeader,
  },
};
</script>