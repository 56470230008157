<template>
  <tr @click="changeRoute()">
    <th scope="row" width="1%" class="pl-3 pr-2 text-center">
      <icon :icon="['fal','camera']" />
    </th>
    <td class="pl-0">Add photo</td>
    <form @submit.prevent="upload" style="display: none !important;">
      <input
        type="file"
        name="file"
        ref="fileInput"
        @change="onFileChange"
        style="opacity:0; height:0px;width:0px;"
      />
    </form>
  </tr>
</template>

<script>
export default {
  name: "UploadImageActionButton",
  props: [],
  data() {
    return {
      file: ""
    };
  },
  methods: {
    changeRoute() {
      this.$router.push({
            name: "Upload image",
            params: { eventID: this.$route.params.eventID }
          });
    },
    addPhoto() {
      this.$emit("closeActionSheet");
      this.$refs.fileInput.click();
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.uploadImage(files[0]);
    },
    uploadImage(file) {
      let reader = new FileReader();
      reader.onload = e => {
        this.file = e.target.result;
        this.$http
          .post(
            process.env.VUE_APP_API_ROOT +
              "events/" +
              this.$route.params.eventID +
              "/upload",
            {
              file: e.target.result
            }
          )
          .then(response => {
            this.$emit("uploading", false);
            this.$emit("update-attachments", response.data.data);
            console.log(response.data.data);
          })
          .catch(error => {
            // handle authentication and validation errors here
            this.errors = error.response.data.errors;
            this.$emit("uploading", "FALSE");
          });
      };
      reader.readAsDataURL(file);
    },
  }
};
</script>

<style>
</style>