<template>
    <div class="tw-flex tw-my-2">
        <div class="tw-flex-shrink-0">
            <svg
                v-if="type == 'error'"
                class="tw-h-5 tw-w-5 tw-text-red-400"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
                <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clip-rule="evenodd"
                />
            </svg>

            <svg
                v-else
                class="tw-h-5 tw-w-5 tw-text-green-400"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
                <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                />
            </svg>
        </div>

        <h3
            class="tw-ml-2 tw-text-sm tw-leading-5 tw-font-normal tw-mb-0"
            :class="
                type != 'error'
                    ? 'tw-text-green-800'
                    : 'tw-text-red-800'
            "
        >
            {{ text }}
        </h3>
    </div>
</template>

<script>
export default {
    name: 'FormError',
    props: {
        text: { type: String, default: '' },
        type: { type: String, default: 'error' },
    },
}
</script>
