<template>
  <div class="animated fadeIn">
    <UploadImageForm></UploadImageForm>
  </div>
</template>

<script>
import UploadImageForm from "@/components/Events/Forms/UploadImageForm";

export default {
  name: "add-image",
  components: {
    UploadImageForm
  }
};
</script>
