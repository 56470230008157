<template>
  <span class="text-muted"
    ><icon :icon="['fal', 'calendar-alt']" class="tw-mr-1"></icon> {{ date_created
    }}<icon :icon="['fal', 'clock']" class="tw-mr-1 tw-ml-2"></icon>
    {{ time_created }}</span
  >
</template>


<script>
export default {
  name: "EventTimestamp",
  props: ["date_created", "time_created"],
  computed: {},
};
</script>
