<template>
  <div class="ml-3 mt-3">
    <avatar :username="full_name" :size="60" class="mb-3"></avatar>
    <h4>{{ full_name }}</h4>
    <hr />
    <span @click="hideSidebarMobile()">
      <router-link :to="{ name: 'Event info' }" class="nav-item text-dark d-block my-4">
        <icon :icon="['fal','info-circle']" fixed-width class="mr-2" style="font-size: 1.25rem" />
        <span class="align-top">Event info</span>
      </router-link>
    </span>
    <span @click="hideSidebarMobile()">
      <router-link :to="{ name: 'Emergency info' }" class="nav-item text-danger d-block my-4">
        <icon :icon="['fal','phone-volume']" fixed-width class="mr-2" style="font-size: 1.25rem" />
        <span class="align-top">Emergency info</span>
      </router-link>
    </span>
    <span @click="hideSidebarMobile()">
      <router-link :to="{ name: 'Add message' }" class="nav-item text-dark d-block my-4">
        <icon :icon="['fal','comments']" fixed-width class="mr-2" style="font-size: 1.25rem" />
        <span class="align-top">Send message</span>
      </router-link>
    </span>
    <hr />
    <span @click="hideSidebarMobile()">
      <a
        href="https://riskfacilitator.com/risky"
        target="_blank"
        class="nav-item text-dark d-block my-4"
      >
        <icon
          :icon="['fal','external-link-alt']"
          fixed-width
          class="mr-2"
          style="font-size: 1.25rem"
        />
        <span class="align-top">About risky app</span>
      </a>
    </span>
    <span @click="hideSidebarMobile()">
      <router-link :to="{ name: 'Switch team' }" class="nav-item text-dark d-block my-4">
        <icon :icon="['fal','users']" fixed-width class="mr-2" style="font-size: 1.25rem" />
        <span class="align-top">Switch event</span>
      </router-link>
    </span>
    <!-- <span @click="hideSidebarMobile()">
      <router-link :to="{ name: 'Switch team' }" class="nav-item text-dark d-block my-4">
        <icon :icon="['fal','cog']" fixed-width class="mr-2" style="font-size: 1.25rem" />
        <span class="align-top">Settings</span>
      </router-link>
    </span> -->
    <span @click="hideSidebarMobile()">
      <router-link :to="{ name: 'Logout' }" class="nav-item text-danger my-4">
        <icon :icon="['fal','sign-out-alt']" fixed-width class="mr-2" style="font-size: 1.25rem" />
        <span class="align-top">Sign out</span>
      </router-link>
    </span>
  </div>
</template>

<script>
import Avatar from "vue-avatar";

export default {
  name: "SidebarProfileComponent",
  components: {
    Avatar
  },
  computed: {
    full_name() {
      return this.$store.getters['user/fullName'];
    },
  }
};
</script>

<style>
</style>