<template>
  <div
    v-if="!isMobileOnly"
    class="tw-border-b tw-border-gray-200 tw-px-4 tw-py-4 sm:tw-flex sm:tw-items-center sm:tw-justify-between sm:tw-px-6 lg:tw-px-8"
  >
    <div class="tw-flex-1 tw-min-w-0">
      <h1 v-if="title" class="page-title">{{ title }}</h1>
      <slot v-else name="page-title" />
    </div>
    <div class="tw-mt-4 tw-flex sm:tw-mt-0 sm:tw-ml-4">
      <slot name="page-buttons" />
    </div>
  </div>
</template>

<script>
import { isMobileOnly } from "mobile-device-detect";

export default {
  props: ["title"],
  computed: {
    isMobileOnly: function () {
      if (isMobileOnly) {
        return true;
      }
    },
  },
};
</script>

<style>
</style>