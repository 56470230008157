<template>
  <div class="animated fadeIn">
    <div class="row my-4">
      <div class="col">
        <h2>Switch event</h2>
      </div>
    </div>
    <SwitchTeam></SwitchTeam>
  </div>
</template>

<script>
import SwitchTeam from "@/components/SwitchTeam";

export default {
  name: "change-severity",
  components: {
    SwitchTeam
  }
};
</script>
