<template>
  <div>
    <button
      v-for="p in options"
      v-bind:key="p.id"
      type="button"
      class="tw-mr-1 tw-mb-1 tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md hover:tw-bg-black-50"
      :class="[
        checkIfSelected(p.id)
          ? 'tw-bg-black-500 tw-text-white tw-border-black-300'
          : 'tw-bg-white tw-text-gray-500 tw-border-gray-100',
      ]"
      @click="tagClicked(p.id)"
    >
      {{ p.name }}
    </button>
    <p
      class="tw-mt-2 tw-text-sm tw-text-red-600"
      v-if="isError && error"
      style="margin-bottom: 0 !important"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  name: "TagInput",
  props: ["errors", "validate"],
  data() {
    return {
      options: [],
      selected: [],
      error: "",
      isError: false,
      fieldName: "tags",
    };
  },
  watch: {
    validate(newValue, oldValue) {
      if (newValue === true) {
        this.validateField();
        this.$emit("updateTags", this.selected);
      }
    },
  },
  methods: {
    checkIfSelected(id) {
      return this.selected.includes(id);
    },
    tagClicked(id) {
      if (this.checkIfSelected(id)) {
        this.removeItem(id);
      } else {
        this.addItem(id);
      }
      this.validateField();
      this.$emit("updateTags", this.selected);
    },
    addItem(id) {
      this.selected.push(id);
    },
    removeItem(id) {
      var index = this.selected.indexOf(id);
      this.$delete(this.selected, index);
    },
    getTags() {
      try {
        this.$http
          .get(`${process.env.VUE_APP_API_ROOT}tags/`)
          .then((response) => {
            this.options = response.data.results;
          });
      } catch (e) {
        // catch exception
      }
    },
    emitToParent(event) {
      this.$emit("updateTags", this.selected);
    },
    validateField() {
      if (this.selected.length === 0) {
        //   Check if a tag is set
        this.isError = true;
        this.error = "Choose at least 1 tag.";
        this.$emit("updateErrors", { name: this.fieldName, error: true });
      } else {
        //   Clear errors
        this.isError = false;
        this.error = "";
        this.$emit("updateErrors", { name: this.fieldName, error: false });
      }
    },
  },
  async created() {
    if (this.options.length == 0) {
      this.getTags();
    }
  },
};
</script>