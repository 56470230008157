<template>
  <div class="timeline">
    <table class="table table-borderless table-hover">
      <tbody v-if="isLoading">
        <tr>
          <td colspan="2">
            <vcl-bullet-list></vcl-bullet-list>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr v-if="checkTitle()">
          <td width="1%" class="pr-0 align-top">
            <avatar username="risky" backgroundColor="#000000" color="#FFFFFF" size="25"></avatar>
          </td>
          <td>
            <span>
              <b>risky</b>
            </span>
            <br />
            <span class="text-muted">
              <i class="fas fa-question-circle text-secondary mr-1"></i>
              <router-link
                :to="{ name: 'Rename', params: { eventID: this.$route.params.eventID }}"
              >Add a title</router-link>for this incident
            </span>
          </td>
        </tr>
        <tr v-if="solution === null && closed_at === null">
          <td width="1%" class="pr-0 align-top">
            <avatar username="risky" backgroundColor="#000000" color="#FFFFFF" size="25"></avatar>
          </td>
          <td>
            <span class="align-top">
              <b>risky</b>
            </span>
            <br />
            <span class="text-muted">
              <i class="fas fa-question-circle text-secondary mr-1"></i>
              <a href="#">Suggest something</a> that could resolve this incident
            </span>
          </td>
        </tr>
        <tr v-for="item in activity" :key="item.id">
          <td width="1%" class="px-0 pt-3 align-top">
            <avatar :username="item.created_user" size="25"></avatar>
          </td>
          <td>
            <span>
              <b>{{item.created_user}}</b>
              <span class="text-muted ml-1">{{ item.time_ago }}</span>
            </span>
            <br />
            <span v-html="item.message"></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { VclBulletList } from "vue-content-loading";
import Avatar from "vue-avatar";

export default {
  name: "EventActivityCard",
  props: ["solution", "closed_at", "title", "update"],
  components: {
    VclBulletList,
    Avatar
  },
  data() {
    return {
      activity: [],
      errors: "",
      isLoading: true
    };
  },
  watch: {
    update: function(newVal, oldVal) {
      if (newVal == true) {
        this.fetchActivity();
      }
      this.$emit("updated");
    }
  },
  methods: {
    checkTitle() {
      // if not null, then return false
      // as we don't even want to show this
      if (this.$props.closed_at === null) {
        // if title variable is undefined then
        // return true
        if (typeof this.$props.title !== "undefined") {
          // if the title variable is defined
          if (this.$props.title.includes("Observation #")) {
            // Return true if the default title is set
            return true;
          } else {
            // If there is already a custom title set
            // i.e. doesn't start with 'Observation #'
            return false;
          }
        } else return true;
      } else return false;
    },
    async fetchActivity() {
      try {
        let uri =
          process.env.VUE_APP_API_ROOT +
          "incidents/" +
          this.$route.params.eventID +
          "/activity/";
        const response = await axios.get(uri);
        this.activity = response.data.results;
        this.isLoading = false;
      } catch (e) {
        // catch exception
        console.log(e);
      }
    }
  },
  created() {
    this.fetchActivity();
  }
};
</script>
