<template>
  <tr class="tw-cursor-pointer hover:tw-bg-gray-50 tw-rounded-l-md tw-rounded-r-md" @click="onClick">
    <slot></slot>
  </tr>
</template>

<script>
export default {
  name: "TableRow",
  props: {
    to: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    onClick(event) {
      this.$emit("click", event, this.to);
    },
  },
};
</script>