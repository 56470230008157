<template>
    <form @submit.prevent="onSubmit">
        <div class="mb-3">
            <div class="tw-space-y-6 px-2">
                <FormError
                    v-for="(error, i) in errors"
                    type="error"
                    :text="error"
                    :key="i"
                />

                <div class="">
                    <input
                        class="field"
                        name="title"
                        placeholder="Type a new title"
                        v-model="title"
                        @keydown="errors = ''"
                    />
                </div>
        <div class="tw-flex tw-flex-col tw-justify-stretch tw-space-y-2 lg:tw-space-y-0 lg:tw-justify-start lg:tw-flex-row">
                    <button
                        type="submit"
                        class="btn-orange lg:tw-mr-2"
                        v-bind:class="{ disabled: isLoading }"
                    >
                        Change title
                    </button>
                    <button type="button" class="btn-gray" @click="$router.go(-1)">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import axios from 'axios'
import FormError from './FormError.vue'

export default {
    name: 'RenameEventForms',
    components: {
        FormError,
    },
    data() {
        return {
            title: '',
            event: '',
            errors: '',
            isLoading: false,
        }
    },
    methods: {
        onSubmit() {
            if (this.isLoading) return

            this.postMessage()
        },
        async postMessage() {
            this.isLoading = true
            this.event = this.$route.params.eventID

            axios
                .patch(
                    process.env.VUE_APP_API_ROOT +
                        'incidents/' +
                        this.$route.params.eventID +
                        '/',
                    this.$data
                )
                .then((response) => {
                    this.$emit('completed', response.data.data)
                    this.$router.back()
                })
                .catch((error) => {
                    // handle authentication and validation errors here
                    if (error.response) {
                        this.errors = error.response.data.message
                    }
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        getIncident() {
            this.event = this.$route.params.eventID;
            axios
                .get(
                process.env.VUE_APP_API_ROOT +
                    "incidents/" +
                    this.$route.params.eventID +
                    "/",
                )
                .then(response => {
                    this.isLoading = false;
                    this.title = response.data.title
                })
                .catch(() => {});
            }
    },
    created() {
        this.getIncident()
    }
}
</script>
