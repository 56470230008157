<template>
  <router-link
    :to="{ name: 'Switch team' }"
    class="tw-flex-shrink-0 tw-w-full group tw-block"
  >
    <div class="tw-flex tw-items-center">
      <div>
        <avatar :username="full_name" :size="35" class="tw-inline-block tw-h-9 tw-w-9 tw-rounded-full"></avatar>
      </div>
      <div class="tw-ml-3">
        <p class="tw-mb-1 tw-text-sm tw-font-medium tw-text-white">{{ full_name }}</p>
        <p
          class="tw-mb-2 tw-text-xs tw-font-medium tw-text-gray-300 group-hover:tw-text-gray-200"
        >
          #{{ current_team_name }}<icon :icon="['fal', 'chevron-down']" class="tw-ml-1"/>
        </p>
      </div>
    </div>
  </router-link>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from "@coreui/vue";
import Avatar from "vue-avatar";

export default {
  name: "DefaultHeaderDropdownAccnt",
  components: {
    AppHeaderDropdown,
    Avatar,
  },
  data: () => {
    return { itemsCount: 42 };
  },
  computed: {
    full_name() {
      return this.$store.getters['user/fullName'];
    },
    current_team_name() {
        if (this.$store.getters['user/currentTeam']) {
            return this.$store.getters['user/currentTeam'].name;
        }
    },
  },
};
</script>
