const TeamHome = () => import('@/views/Team/TeamHome.vue');
const TeamUsers = () => import('@/views/Team/TeamMembers.vue');
const TeamUsage = () => import('@/views/Team/TeamUsage.vue');
const Integrations = () => import('@/views/Settings/Integrations.vue');

const routes = [
  {
      path: "home",
      name: "Team home",
      component: TeamHome,
      meta: {
        title: "Team"
      }
  },
  {
      path: "users",
      name: "Users",
      component: TeamUsers,
      meta: {
        title: "Team members"
      }
  },
  {
      path: "usage",
      name: "Usage",
      component: TeamUsage,
      meta: {
        title: "Usage"
      }
  },
  {
      path: "integrations",
      name: "Integrations",
      component: Integrations,
      meta: {
        title: "Integrations"
      }
  }
]

export default routes;
