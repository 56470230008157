<template>
  <avatar :username="reporter" :size="getSize"></avatar>
</template>


<script>
import Avatar from 'vue-avatar'

export default {
  name: "EventContributorAvatars",
  props: ["reporter", "size"],
  components: {
    Avatar
  },
  computed: {
    getSize() {
      if (this.$props.size) {
        return this.$props.size
      } else {
        return 25
      }
    }
  }
};
</script>
