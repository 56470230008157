import axios from 'axios'

const state = {
    user: {
        user_permissions: [],
        groups: [],
    },
    teams: []
}

const mutations = {
    setUser(state, user) {
        state.user = user
    },
    addTeam(state, team) {
        state.teams = [...state.teams, team]
    },
    setTeams(state, teams) {
        state.teams = teams
    },
    setSelectedTeam(state, team_id) {
        state.user.current_team_id = team_id;
        state.user.default_team = team_id;
    },
}

const getters = {
    isSuperuser: state => {
        return state.user.is_superuser === true
    },
    user: state => {
        return state.user
    },
    firstName: state => {
        return state.user.first_name
    },
    lastName: state => {
        return state.user.last_name
    },
    fullName: state => {
        return `${state.user.first_name} ${state.user.last_name}`
    },
    teams: state => {
        return state.teams
    },
    currentTeam: state => {
        return state.teams.find(team => team.id === state.user.default_team)
    },
    hasCurrentTeam: state => {
        return Boolean(state.user.default_team)
    },
    hasFeatureEnabled: state => flag => {
        // const team = state.teams.find(team => team.id == state.user.current_team_id);
        
        // if (team) {
        //     const feature_flag = team.features.find(x => x.feature === flag)
        //     if (feature_flag) {
        //         return feature_flag.active
        //     }
        // }
        return true
        return false
    },
    isOnboarded: state => {
        return state.user.onboarding_status === 'ONBOARDING_COMPLETED'
    },
    isAdmin: (state, getters) => {
        return getters.currentTeam.role === 'ADMIN'
    },
}

const actions = {
    userDetail({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_API_ROOT + 'accounts/me/', {
                    withCredentials: true,
                })
                .then(response => {
                    commit('setUser', response.data)
                    dispatch('autoUpdateUserTimezone')
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updateUser({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(process.env.VUE_APP_API_ROOT + 'accounts/me/', payload, {
                    withCredentials: true,
                })
                .then(response => {
                    commit('setUser', response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    autoUpdateUserTimezone({ commit, getters }) {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        if (!getters.user.is_auto_timezone) {
            return Promise.resolve()
        }

        if (getters.user.default_timezone == timezone) {
            return Promise.resolve()
        }

        return new Promise((resolve, reject) => {
            axios
                .patch(
                    process.env.VUE_APP_API_ROOT + 'accounts/me/',
                    { default_timezone: timezone },
                    {
                        withCredentials: true,
                    }
                )
                .then(response => {
                    commit('setUser', response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getTeams({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_API_ROOT + 'teams/?limit=1000', {
                    withCredentials: true,
                })
                .then(response => {
                    commit('setTeams', response.data.results)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updateTeam({ commit, getters }, payload) {
        return new Promise((resolve, reject) => {
            let endpoint = `${process.env.VUE_APP_API_ROOT}teams/${getters['currentTeam'].id}/`
            axios
                .patch(endpoint, payload, { withCredentials: true })
                .then(response => {
                    let teams = [...getters['teams']].map(team => {
                        if (team.id === response.data.id) {
                            return response.data
                        } else return team
                    })
                    commit('setTeams', teams)
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    createTeam({ dispatch, getters }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_API_ROOT + 'teams/', payload, {
                    withCredentials: true,
                })
                .then(() => dispatch('init', { root: true }))
                .then(() => {
                    resolve(getters.currentTeam)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    switchTeam({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    process.env.VUE_APP_API_ROOT + 'accounts/me/switch_team/',
                    {
                        team_id: payload,
                    },
                    { withCredentials: true }
                )
                .then(response => {
                    commit('setUser', response.data) // update user details since we have it anyway.
                    commit('setSelectedTeam', response.data.default_team) // triggers anything watching for this mutation
                })
                .then(() => resolve())
                .catch(error => {
                    reject(error)
                })
        })
    },
    setPassword(context, payload) {
        const url = `${process.env.VUE_APP_API_ROOT}accounts/set_password/`

        return new Promise((resolve, reject) => {
            axios
                .post(url, payload)
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
