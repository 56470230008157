<template>
  <Layout>
    <PageHeader slot="header">
      <template slot="page-title">
        <div class="tw-flex tw-items-center tw-space-x-4">
          <PageHeaderBackButton/>
          <div class="tw-flex-1 tw-min-w-0">
            <h1 class="tw-font-light tw-leading-7 tw-text-gray-900 tw-text-2xl sm:tw-truncate" style="margin-bottom: 0">
              {{ event.title }}
            </h1>
            <div class="tw-mt-1">
              <EventTags :tags="event.tags"></EventTags>
            </div>
            <!-- <h3
              id="modal-headline"
              class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900 tw-my-auto"
            >
              Where is the incident?
            </h3>
            <div class="tw-mt-2">
              <p class="tw-text-sm tw-text-gray-500" style="margin-bottom: 0px">
                Incident location is -37.8717, 145.0252.
              </p>
            </div> -->
          </div>
        </div>
      </template>
      <template slot="page-buttons">
        <p class="tw-text-lg tw-font-light tw-text-orange-400 tw-my-1 tw-mr-4" style="margin-bottom: 0">
          {{ event.time_ago }}
        </p>
        <EventActionDropdown :incident="event" />
      </template>
    </PageHeader>

    <div v-if="errors" class="alert alert-danger" v-text="errors"></div>
    <div class="row tw-mt-6">
      <div class="col-lg-4">
        <EventMapCardLoading
          v-if="isLoading && event.length == 0"
        ></EventMapCardLoading>
        <EventMapCard
          v-else
          :lat="event.latitude"
          :lng="event.longitude"
          height="265px"
        ></EventMapCard>
        <!-- <img  class="card w-100" :src="event.map_url"/> -->

        <EventDetailCardLoading v-if="isLoading"></EventDetailCardLoading>
        <EventDetailCard v-else :event="event"></EventDetailCard>
        <WeatherAttachmentCard
          v-if="!isLoading && event.temp"
          :weather="event"
        ></WeatherAttachmentCard>
      </div>
      <div class="col-lg-8">
        <b-tabs>
          <b-tab active>
            <template v-slot:title>
              <icon :icon="['fal', 'stream']" fixed-width class="mr-2" />
              Timeline
            </template>
            <div class="mt-3 mb-2">
              <small class="text-muted pr-2">Suggested actions</small>
              <ImMonitoringQuickButton
                v-on:updateTimeline="updateTimeline"
                class="mr-2"
              ></ImMonitoringQuickButton>
              <ImRespondingQuickButton
                v-on:updateTimeline="updateTimeline"
                class="mr-2"
              ></ImRespondingQuickButton>
              <ESOCalledQuickButton
                v-on:updateTimeline="updateTimeline"
                class="mr-2"
              ></ESOCalledQuickButton>
            </div>
            <EventActivityCard
              :id="event.id"
              :solution="event.solution"
              :title="event.title"
              :update="update_timeline"
              v-on:updated="update_timeline = false"
            ></EventActivityCard>
          </b-tab>

          <b-tab>
            <template v-slot:title>
              <icon :icon="['fal', 'images']" fixed-width class="mr-2" />
              Attachments
            </template>
            <AttachmentsTable :attachments="attachments"></AttachmentsTable>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <EventMoreModal :eventID="event.id"></EventMoreModal>
  </Layout>
</template>

<script>
import EventDetailCard from "@/components/Events/EventDetailCard";
import EventDetailCardLoading from "@/components/Events/EventDetailCardLoading";
import EventMapCard from "@/components/Events/EventMapCard";
import EventMapCardLoading from "@/components/Events/EventMapCardLoading";
import EventActivityCard from "@/components/Events/EventActivityCard";
import EventMoreModal from "@/components/Events/EventMoreModal";
import AttachmentsTable from "@/components/Events/AttachmentsTable";
import WeatherAttachmentCard from "@/components/Events/WeatherAttachmentCard";
import ImMonitoringQuickButton from "@/components/Events/Actions/ImMonitoringQuickButton";
import ImRespondingQuickButton from "@/components/Events/Actions/ImRespondingQuickButton";
import ESOCalledQuickButton from "@/components/Events/Actions/ESOCalledQuickButton";
import EventActionDropdown from "@/components/Events/Actions/EventActionDropdown";
import EventTags from "@/components/Events/Meta/EventTags";
import PageHeaderBackButton from "@/components/ui/PageHeader/PageHeaderBackButton.vue";

export default {
  name: "view-event",
  components: {
    EventDetailCard,
    EventDetailCardLoading,
    EventMapCard,
    EventMapCardLoading,
    EventActivityCard,
    EventMoreModal,
    WeatherAttachmentCard,
    ImMonitoringQuickButton,
    ImRespondingQuickButton,
    ESOCalledQuickButton,
    AttachmentsTable,
    EventActionDropdown,
    EventTags,
    PageHeaderBackButton
  },
  data() {
    return {
      event: [],
      attachments: [],
      tags_selected: null,
      tags_available: [],
      description: "",
      severity: "",
      control_effectiveness: "",
      errors: "",
      isLoading: true,
      update_timeline: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    updateTimeline(event) {
      this.update_timeline = true;
    },
    async fetchAttachments() {
      try {
        let uri =
          process.env.VUE_APP_API_ROOT +
          "incidents/" +
          this.$route.params.eventID +
          "/attachments/";
        var response = await this.$http.get(uri);
        this.attachments = response.data.results;
      } catch (e) {
        // catch exception
        console.log(e);
      }
    },
  },
  async created() {
    try {
      let uri =
        process.env.VUE_APP_API_ROOT +
        "incidents/" +
        this.$route.params.eventID +
        "/";
      const response = await this.$http.get(uri);
      this.event = response.data;
    } catch (e) {
      // catch exception
      console.log(e);
    }
    this.isLoading = false;
    this.fetchAttachments(); // this is on the other tab so doesnt matter if it loads after. TODO: move this to only load on tab swtich
  },
  mounted: function () {},
};
</script>