<template>
    <button
        type="button"
        @click="$emit('click')"
        class="tw-w-full tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
        role="menuitem"
    >

        <slot />
    </button>
</template>

<script>
export default {
    name: "AppDropdownItem",
};
</script>
