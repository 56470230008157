<template>
  <form @submit.prevent="onSubmit">
    <div class="tw-mb-3">
      <div class="tw-space-y-6 tw-px-2">
        <FormError v-for="error, i in errors" type="error" :text="error" :key="i"/>

        <div class="form-group">
          <textarea
            class="form-control"
            name="description"
            placeholder="Describe what has happened in detail..."
            rows="5"
            v-model="description"
            @keydown="errors = ''"
          />
        </div>
        <div class="tw-flex tw-flex-col tw-justify-stretch tw-space-y-2 lg:tw-space-y-0 lg:tw-justify-start lg:tw-flex-row">
          <button
            type="submit"
            class="btn-orange lg:tw-mr-2"
            v-bind:class="{ 'disabled' : isLoading }"
          >Save</button>
          <button type="button" class="btn-gray" @click="$router.go(-1)">Cancel</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import FormError from './FormError.vue';

export default {
  name: "AddEventDescriptionForm",
  components: {
    FormError
  },
  data() {
    return {
      description: "",
      event: "",
      errors: "",
      isLoading: false
    };
  },
  methods: {
    onSubmit() {
      this.postMessage();
    },
    async postMessage() {
      this.isLoading = true;
      this.event = this.$route.params.eventID;

      axios
        .patch(
          process.env.VUE_APP_API_ROOT +
            "incidents/" +
            this.$route.params.eventID +
            "/",
          this.$data
        )
        .then(response => {
          this.$emit("completed", response.data.data);
          this.$router.back();
        })
        .catch(error => {
          // handle authentication and validation errors here
          if (error.response) {
            this.errors = error.response.data.message;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getIncident() {
      this.event = this.$route.params.eventID;
      axios
        .get(
          process.env.VUE_APP_API_ROOT +
            "incidents/" +
            this.$route.params.eventID +
            "/",
        )
        .then(response => {
            this.isLoading = false;
            this.description = response.data.description
        })
        .catch(() => {});
    }
  },
  created() {
      this.getIncident()
  }
};
</script>
