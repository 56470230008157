<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "app",
  computed: {
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn'];
    }
  },
  methods: {},
  mounted: function() {
  }
};
</script>

<style src="./assets/tailwind.css"></style>

<style lang="scss">
/* Import Bootstrap Vue Styles */
@import "~bootstrap-vue/dist/bootstrap-vue.css";
// Import Main styles for this application
@import "assets/scss/style";
</style>
