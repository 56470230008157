<template>
    <Dropdown class="z-5">
        <template slot="toggler">
            <button
                type="button"
                class="tw-w-8 tw-h-8 tw-inline-flex tw-items-center tw-justify-center tw-text-gray-400 tw-rounded hover:tw-text-gray-500 hover:tw-bg-gray-200 focus:tw-outline-none focus:tw-bg-gray-200"
            >
                <span class="tw-sr-only">Open options</span>
                <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-5 tw-w-5" viewBox="0 0 20 20" fill="currentColor">
  <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
</svg>
            </button>
        </template>
        <DropdownContent>
            <DropdownItem @click="$router.push({ name: 'Rename', params: { eventID: incident_id } })"><icon :icon="['fal', 'pen']" fixed-width class="tw-mr-2"/> Edit name</DropdownItem>
            <DropdownItem v-if="hasFeatureEnabled('incident_resolve')" @click="$router.push({ name: 'Mark resolved', params: { eventID: incident_id } })"><icon :icon="['fal', 'check-double']" fixed-width class="tw-mr-2"/> Resolve</DropdownItem>
            <DropdownItem @click="$emit('downloadPDF')"><icon :icon="['fal', 'file-pdf']" fixed-width class="tw-mr-2"/> Download report</DropdownItem>
        </DropdownContent>
    </Dropdown>
</template>


<script>
import { mapGetters } from "vuex";
import Dropdown from "@/components/ui/Dropdown/Dropdown.vue";
import DropdownContent from "@/components/ui/Dropdown/DropdownContent.vue";
import DropdownItem from "@/components/ui/Dropdown/DropdownItem.vue";

export default {
    props: ["incident_id"],
    components: {
        Dropdown,
        DropdownContent,
        DropdownItem
    },
    computed: {
        ...mapGetters("user", ["hasFeatureEnabled"]),
    },
};
</script>
