<template>
  <div>
    <div class="row">
      <div class="col">
        <div v-if="isLoading" class="text-center py-3">
          <clip-loader :loading="isLoading" color="#63c2de"></clip-loader>
        </div>
        <div v-if="events.length !== 0">
            <EventCard
            v-for="event in events"
            :key="event.id"
            :event="event"
            ></EventCard>
        </div>
        <ZeroIncidents v-if="!isLoading && events.length < 1"/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import EventCard from "@/components/Events/EventCard";
import ZeroIncidents from "@/components/Events/ZeroData/ZeroIncidents";

export default {
  name: "events-mobile",
  components: {
    ClipLoader,
    EventCard,
    ZeroIncidents
  },
  data() {
    return {
      isLoading: true,
      events: [],
    };
  },
  computed: {
    openEvents: function () {
      return this.events.filter(function (e) {
        return e.closed_at === null;
      });
    },
    resolvedEvents: function () {
      return this.events.filter(function (e) {
        return e.closed_at != null;
      });
    },
  },
  methods: {
    async getIncidents() {
      try {
        let uri = process.env.VUE_APP_API_ROOT + "incidents/";
        const response = await axios.get(uri);
        this.events = response.data.results;
        this.isLoading = false;
      } catch (e) {
        // handle the authentication error here
      }
    },
  },
  created() {
    this.getIncidents();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "user/setSelectedTeam") {
        this.isLoading = true;
        this.getIncidents();
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>
