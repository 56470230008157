<template>
  <form @submit.prevent="onSubmit">
    <div class="row mb-2">
      <div class="col">
        <button
          type="button"
          class="btn btn-light btn-block pt-3 pb-2 mb-3"
          @click="updateSeverity('extreme')"
          v-bind:class="{ 'disabled' : isLoading }"
        >
          <h4><i class="fas fa-camera"></i> Take photo/video</h4>
        </button>
        <button
          type="button"
          href
          class="btn btn-light btn-block pt-3 pb-2 mb-3"
          @click="updateSeverity('high')"
          v-bind:class="{ 'disabled' : isLoading }"
        >
          <h4><i class="fas fa-images"></i> Choose from camera roll</h4>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";

export default {
  name: "change-severity",
  data() {
    return {
      isLoading: false,
      severity: "",
      
    };
  },
  methods: {
    async updateSeverity(severity) {
      this.isLoading = true;
      this.severity = severity;
      axios
        .put(
          process.env.VUE_APP_API_ROOT +
            "events/" +
            this.$route.params.eventID +
            "/severity/",
          this.$data
        )
        .then(response => {
          this.$emit("completed", response.data.data);
          this.$router.push({
            name: 'Incident',
            params: { eventID: this.$route.params.eventID }
          });
        })
        .catch(error => {
          // handle authentication and validation errors here
          this.errors = error.response.data.errors;
        });
    }
  }
};
</script>
