<template>
  <div class="shadow-sm bg-white mb-4">
    <map-loader :map-config="mapConfig" :lat="lat" :lng="lng" :mapHeight="height">
      <template slot-scope="scopeProps">
        <map-marker
          :key="1"
          :lat="lat"
          :lng="lng"
          :google="scopeProps.google"
          :map="scopeProps.map"
        />
      </template>
    </map-loader>
  </div>
</template>

<script>
import axios from "axios";
import MapLoader from "@/components/Map/MapLoader.vue";
import MapMarker from "@/components/Map/MapMarker";
import MapSettings from "@/lib/mapSettings";

export default {
  name: "EventMapCard",
  components: {
    MapLoader,
    MapMarker
  },
  props: ["lat", "lng", "height"],
  data() {
    return {};
  },
  computed: {
    mapConfig() {
      return {
        ...MapSettings(),
        zoom: 18,
        mapTypeControl: false,
        scaleControl: true,
        zoomControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false
        //center: {lat: this.$props.lat, lng: this.$props.lng}
      };
    }
  },
  methods: {
    // gpsLocation() {
    //   var location = this.$props.lat + ", " + this.$props.long
    //   console.log(location)
    //   return location
    // },
  }
};
</script>

<style scoped>
#map {
  height: 100px;
  width: 100%;
}
</style>
