<template>
  <div class="tw-mb-6">
    <div class="tw-flex tw-mb-4">
      <TableSearchField
        class="my-auto"
        placeholder="Search events by name, description, and more ..."
        name="search"
        label="Search"
        :value="search"
        :hideLabel="true"
        @textchange="onChange"
      />
      <button
        class="tw-flex tw-justify-end tw-my-auto tw-ml-6 tw-text-sm hover:tw-text-gray-700"
        :class="[hasMoreOptions ? 'tw-text-gray-700' : 'tw-text-gray-500']"
        @click="toggleFilters"
      >
        <span class="tw-mr-2 tw-font-medium">Filters</span>
        <FontAwesomeIcon
          :icon="hasMoreOptions ? ['fal', 'chevron-up'] : ['fal', 'chevron-down']"
          class="my-auto"
        />
      </button>
      <slot name="buttons"></slot>
    </div>

    <div
      class="tw-grid tw-items-center tw-gap-4 tw-mb-4 sm:tw-grid-cols-4 xl:tw-grid-cols-6"
      v-if="hasMoreOptions"
    >
      <SeveritySelectDropdown
        class="tw-w-full"
        @select="onChange"
        name="severity"
        :value="severity"
        :isFilter="true"
      />
      <FormMultiSelect
        @select="onChange"
        label="Tags"
        name="tags"
        :value="tags"
        :options="tag_options.map(item => item.name)"
      />
      <FormMultiSelect
        label="Reactions"
        name="reactions"
        :value="reactions"
        :options="reaction_options"
        @select="onChange"
      >
        <template v-slot:option="{ option }">
          <EventReaction :reaction="option" :size="25" class="tw-mx-auto tw-mt-1.5"></EventReaction>
        </template>
      </FormMultiSelect>
      <FormDateField
        class="sm:w-full"
        :value="created_at_gte"
        label="From"
        name="created_at_gte"
        @change="onChange"
      />
      <FormDateField
        class="sm:w-full"
        label="To"
        :value="created_at_lte"
        name="created_at_lte"
        @change="onChange"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import FormMultiSelect from "../ui/forms/FormMultiSelect";
import FormDateField from "../ui/forms/FormDateField";
import SeveritySelectDropdown from "../Inputs/SeveritySelectDropdown.vue";
import TableSearchField from "../ui/Table/TableSearchField.vue";
import EventReaction from './Meta/EventReaction.vue';

export default {
  name: "ReportFilters",
  components: {
    FormMultiSelect,
    FormDateField,
    FontAwesomeIcon,
    SeveritySelectDropdown,
    TableSearchField,
    EventReaction
  },
  data() {
    return {
      search: undefined,
      severity: "ALL",
      tags: [],
      reactions: [],
      created_at_gte: undefined,
      created_at_lte: undefined,

      hasMoreOptions: false,

      // these hold all options for the fields
      // decide on where and when to fetch these three from server
      tag_options: [],
      reaction_options: [],

      tags_ids: []
    };
  },
  methods: {
    toggleFilters() {
      this.hasMoreOptions = !this.hasMoreOptions;
    },
    async filterCases() {
      // gather names of fields to be filtered
      let filters = [
        "severity",
        "search",
        "tags",
        "reaction",
        "created_at_gte",
        "created_at_lte"
      ];

      // array to store parts of query string
      let queryString = [];

      // extract ids from selected options - server only works with ids
      this.tags_ids = this.mapToId(
        this.tag_options,
        this.tags
      );

      // build queryString parts
      filters.forEach(item => {
        let param = item
        let value = this[item];

        if (param === "severity" && value === "ALL") {
          value = "";
        } else if (param === "reaction") {
          // array is named reactions but api field is reaction
          value = this["reactions"]
        } else if (param === "tags") {
          value = this["tags_ids"]
        }

        if (value) {
          // do not add undefined values or empty arrays to queryString
          if (Array.isArray(value)) {
            if (value.length > 0) {
              value.forEach((elem, i) => {
                queryString.push(`${param}=${value[i]}`);
              });
            }
          } else {
            queryString.push(`${param}=${value}`);
          }
        }
      });

      // join query string and emit to parent
      queryString = queryString.join("&");

      this.$emit("updateQuery", queryString);
    },
    onChange(name, value) {
      this[name] = value;
      this.filterCases();
    },
    mapToId(options, selections) {
      let selected_ids = [];
      options.map(item => {
        if (selections.includes(item.name)) {
          selected_ids.push(item.id);
        }
      });
      return selected_ids;
    },
    fetchTags() {
        this.isLoading = true;
        try {
            let uri = `${process.env.VUE_APP_API_ROOT}tags/?limit=100&offset=0`;
            axios.get(uri).then((res) => {
              this.tag_options = res.data.results
            });
        } catch (e) {
            // handle the authentication error here
        }
    },
    loadResources() {
      this.fetchTags()
      this.reaction_options = [
        ":slightly_smiling_face:",
        ":slightly_frowning_face:",
        ":neutral_face:",
        ":thinking_face:",
        ":fearful:",
        ":triumph:"
      ]
    }
  },
  created() {
    this.loadResources();
    // if (this.$store.state.cached) {
    //   // check if resources have been cached and load them
    //   this.loadResources();
    // } else {
    //   // if not cache them and then load
    //   this.$store
    //     .dispatch("cacheCaseResources")
    //     .then(() => this.loadResources());
    // }
  }
};
</script>
