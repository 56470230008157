const Login = () => import('@/views/Auth/Login.vue');
const Register = () => import('@/views/Auth/Register.vue');
const ResetPassword = () => import('@/views/Auth/ResetPassword.vue');
const ResetPasswordConfirm = () => import('@/views/Auth/ResetPasswordConfirm.vue');
import Logout from "@/views/Auth/Logout";

import { requireUnauthenticated, redirectLogout } from './route-guards.js';

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: requireUnauthenticated,
    meta: {
      guestOnly: true
    },
  },
  {
    path: "/invitation/:token",
    name: "Register",
    component: Register
  },
  {
    path: "/password/reset/",
    name: "Reset password",
    component: ResetPassword,
    meta: {
      title: "Reset password",
    }
  },
  {
    path: "/password/reset/confirm/:uid/:token/",
    name: "Confirm reset password",
    component: ResetPasswordConfirm,
    meta: {
      title: "Confirm reset password",
    }
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter: redirectLogout,
  }
]

export default routes;
