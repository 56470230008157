<template>
  <div
    class="tw-fixed tw-top-0 tw-w-full tw-z-10 tw-flex-shrink-0 tw-flex tw-h-14 tw-bg-black lg:tw-hidden"
  >
    <button
      v-if="$route.meta.showBackButton"
      @click="$router.back()"
      class="tw-px-4 tw-text-white focus:tw-outline-none"
    >
      <span class="tw-sr-only">Open sidebar</span>
      <icon :icon="['fal', 'angle-left']" size="4x" class="tw-h-6 tw-w-6"></icon>
    </button>
    <button
      v-else
      @click="openSidebar()"
      class="tw-px-4 tw-text-white focus:tw-outline-none"
    >
      <span class="tw-sr-only">Open sidebar</span>
      <icon :icon="['fal', 'bars']" size="2x" class="tw-h-6 tw-w-6"></icon>
    </button>
    <!-- Search bar -->
    <div
      class="tw-flex-1 tw-px-4 tw-flex tw-justify-between sm:tw-px-6 lg:tw-max-w-6xl lg:tw-mx-auto lg:tw-px-8"
    >
      <div class="tw-flex-1 tw-flex">
        <div class="tw-mx-auto tw-my-2">
          <span
            v-if="!$route.meta.showBackButton"
            class="tw-text-3xl tw-font-extralight tw-text-orange-500 tw-align-middle"
            >risky.<span class="tw-text-white">live</span></span
          >
          <span v-else class="tw-text-3xl tw-font-extralight tw-text-white ">{{ $route.meta.title }}</span>
        </div>
      </div>
      <div class="tw-ml-4 tw-flex tw-items-center md:tw-ml-6 tw-w-6">
        <!-- <icon
          :icon="['fal', 'ellipsis-h']"
          size="2x"
          class="tw-h-6 tw-w-6 tw-text-white"
        ></icon> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openSidebar() {
      this.$store.commit("openSidebar");
    },
  },
};
</script>

<style>
</style>