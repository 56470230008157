<template>
    <div>
        <div class="tw-mb-6 tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2 lg:tw-grid-cols-4">
            <MetricCard
                :loading="isLoading"
                :value="total_count"
                color="red"
                label="All events"
            />
        </div>
        <IncidentTableFilters @updateQuery="updateQuery">
            <template slot="buttons">
                <IncidentTableExportDropdown
                    @downloadCSV="getCSV"
                    class="tw-mb-1 tw-ml-6"
                />
            </template>
        </IncidentTableFilters>
        <section class="mb-6">
            <!-- <ZeroReportsCard v-if="cases.length == 0" /> -->
            <!-- <ReportsTable v-else :reports="cases" :loading="loading" /> -->
            <incident-table
                :incidents="events"
                :loading="isLoading"
            ></incident-table>
            <TablePagination
                :total_count="total_count"
                :showing_count="events.length"
                :offset="offset"
                :is_first="offset === 0"
                :is_last="events.length < limit"
                @pageup="pageUp"
                @pagedown="pageDown"
            />
        </section>
    </div>
</template>

<script>
import axios from "axios";
import BounceLoader from "vue-spinner/src/BounceLoader.vue";
// import SeverityDropdown from "@/components/Inputs/SeverityDropdown";
import IncidentTable from "@/components/Events/IncidentTable";
import MetricCard from "@/components/ui/Dashboard/MetricCard"
import TablePagination from "@/components/ui/Table/TablePagination"
import IncidentTableFilters from "@/components/Events/IncidentTableFilters"
import IncidentTableExportDropdown from "@/components/Events/IncidentTableExportDropdown"

export default {
    name: "events-desktop",
    components: {
        // SeverityDropdown,
        IncidentTable,
        IncidentTableFilters,
        IncidentTableExportDropdown,
        BounceLoader,
        MetricCard,
        TablePagination
    },
    data() {
        return {
            isLoading: true,
            events: [],
            hasMoreFilters: false,
            loading: true,
            offset: 0,
            limit: 15,
            query: "",
            total_count: 0,
        };
    },
    methods: {
         onMoreFilters() {
            this.hasMoreFilters = !this.hasMoreFilters;
        },
        updateQuery(newQuery) {
            this.query = newQuery;
            this.offset = 0;
            this.fetchIncidents();
        },
        pageUp() {
            this.offset += this.limit;
            this.fetchIncidents();
        },
        pageDown() {
            if (this.offset === 0) return;
            let newOffset = this.offset - this.limit;
            this.offset = newOffset < 0 ? 0 : newOffset;
            this.fetchIncidents();
        },
        filterIncidents(event) {
            this.fetchIncidents(event);
        },
        async fetchIncidents() {
            this.isLoading = true;
            try {
                let uri = `${process.env.VUE_APP_API_ROOT}incidents/?limit=${this.limit}&offset=${this.offset}&${this.query}`;
                const response = await axios.get(uri);
                this.events = response.data.results;
                this.total_count = response.data.count
            } catch (e) {
                // handle error here
            } finally {
                this.isLoading = false;
            }
        },
        getCSV() {
            this.$http
                .get(`${process.env.VUE_APP_API_ROOT}incidents/csv/?${this.query}`)
                .then(response => {
                const fileURL = window.URL.createObjectURL(
                    new Blob([response.data], { type: "text/csv" })
                );
                const anchor = document.createElement("a");
                anchor.href = fileURL;
                // anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(blob);
                anchor.target = "_blank";
                anchor.setAttribute("download", "report.csv");
                document.body.appendChild(anchor);
                anchor.click();
                })
                .catch(error => {});
            },
    },
    created() {
        this.fetchIncidents();
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === "user/setSelectedTeam") {
                this.isLoading = true;
                this.events = [];
                this.fetchIncidents();
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>
