<template>
  <div class="tw-flex tw-space-x-3 tw-w-full">
    <input
      type="range"
      name="control_effectiveness"
      v-model="value"
      class="custom-range"
      min="0"
      max="100"
      step="1"
      value="50"
      id="control_effectivness"
      @input="setSliderValue"
    />
    <h4 class="tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center tw-align-middle tw-w-10">
      <span id="slider-value" value>50%</span>
    </h4>
  </div>
</template>

<script>
export default {
  name: "SliderInput",
  props: ["control_effectivness"],
  data() {
    return {
      value: 50
    };
  },
  watch: {
    value: function(val, oldVal) {
      document.querySelector("#slider-value").innerHTML = val + "%";
    }
  },
  computed: {
      setSliderValue(event) {
          this.$emit("updateCE", this.value);
      }
  }
};
</script>

<style>
</style>