<template>
  <li
    id="radiogroup-option-1"
    tabindex="1"
    role="radio"
    @click="selectStep()"
    class="tw-mb-4 tw-group tw-relative tw-bg-white tw-rounded-lg tw-shadow-sm tw-cursor-pointer focus:tw-outline-none"
  >
    <div
      class="tw-rounded-lg tw-px-4 tw-py-4 tw-bg-white tw-border tw-border-gray-100 hover:tw-border-gray-200"
    >
      <div class="tw-flex tw-items-center tw-space-x-3">
        <div
          class="tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center tw-h-10 tw-w-10 tw-rounded-full tw-bg-teal-100 sm:tw-mx-0 sm:tw-h-10 sm:tw-w-10"
        >
          <icon
            :icon="['fal', 'tachometer-alt']"
            class="tw-h-6 tw-w-6 tw-text-teal-700"
          ></icon>
        </div>
        <div class="tw-flex-1 tw-min-w-0">
          <h3
            class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900 tw-my-auto"
            id="modal-headline"
          >
            How effective are the current controls?
            
          </h3>
        </div>
      </div>
      <div class="tw-flex tw-mt-4 tw-mb-1">
        <SliderInput
          :control_effectiveness="control_effectiveness"
          @updateCE="updateCE"
          :errors="errors"
          @updateErrors="updateErrors"
          @nextStep="nextStep()"
          @click="selectStep()"
        ></SliderInput>
      </div>
    </div>
    <div
      class="tw-absolute tw-inset-0 tw-rounded-lg tw-border-2 tw-pointer-events-none"
      v-bind:class="[
        selected ? 'tw-border-orange-500' : 'tw-border-transparent',
      ]"
    ></div>
  </li>
</template>

<script>
import SliderInput from "@/components/Inputs/SliderInput";

export default {
  props: ["selected", "control_effectiveness", "errors"],
  components: {
    SliderInput,
  },
  methods: {
    selectStep() {
      this.$emit("nextStep", 6);
    },
    nextStep() {
      this.$emit("nextStep", 7);
    },
    updateCE(value) {
      this.$emit("updateCE", value);
    },
    updateErrors(value) {
      this.$emit("updateErrors", value);
    },
  },
};
</script>

<style>
</style>