<template>
  <div class="tw-bg-white tw-shadow tw-px-4 tw-py-5 sm:tw-rounded-lg sm:tw-p-6">
    <div class="">
      <div class="tw-relative md:tw-col-span-1">
        <h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
          Basic information
        </h3>
        <p class="tw-mt-1 tw-text-sm tw-text-gray-500">
          General information about your team
        </p>
        <button v-if="isAdmin" class="tw-absolute tw-top-0 tw-right-0 tw-py-1 tw-px-2" @click="editing = !editing">
          <icon v-if="!editing" :icon="['fal','pen']" fixed-width class="m-0" style="font-size: 0.8rem" />
          {{ editing ? "Cancel" : "Edit" }}
        </button>
      </div>
        <TeamDetailsForm v-if="editing" :team="team" @cancel="editing = false" />
      

      <div v-else>
        <div class="tw-my-8">
          <h4 class="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
            Team name
          </h4>
          <p>{{ team.name }}</p>
        </div>

        <div class="tw-my-8">
          <h4 class="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
            Basic info
          </h4>
          <p>{{ team.basic_info || "Currently no info" }}</p>
        </div>
      </div>
      
    </div>
  </div>
</template>


<script>
import ParagraphSkeleton from "@/components/ui/ParagraphSkeleton.vue";
import ZeroEventInfoPanel from "@/components/Team/ZeroEventInfoPanel.vue";
import TeamDetailsForm from "@/vue-prism/team/TeamDetailsForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "TeamInfo",
  components: {
    ParagraphSkeleton,
    ZeroEventInfoPanel,
    TeamDetailsForm
  },
  data() {
    return {
      editing: false,
      errors: "",
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      team: "user/currentTeam",
      isAdmin: "user/isAdmin"
    }),
  }
};
</script>
