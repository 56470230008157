<template>
  <li
    id="radiogroup-option-1"
    tabindex="1"
    role="radio"
    @click="selectStep()"
    class="tw-mb-4 tw-group tw-relative tw-bg-white tw-rounded-lg tw-shadow-sm tw-cursor-pointer focus:tw-outline-none"
  >
    <div
      class="tw-rounded-lg tw-px-4 tw-py-4 tw-bg-white tw-border tw-border-gray-100 hover:tw-border-gray-200"
    >
      <div class="tw-flex tw-items-center tw-space-x-3">
        <div
          class="tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center tw-h-10 tw-w-10 tw-rounded-full tw-bg-green-100 sm:tw-mx-0 sm:tw-h-10 sm:tw-w-10"
        >
          <icon
            :icon="['fal', 'location-arrow']"
            class="tw-h-6 tw-w-6 tw-text-green-600"
          ></icon>
        </div>
        <div class="tw-flex-1 tw-min-w-0">
          <h3
            class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900 tw-my-auto"
            id="modal-headline"
          >
            What is the location?
          </h3>
          <div class="tw-mt-2">
            <p
              v-if="findingLocation"
              class="tw-text-sm tw-text-gray-500"
              style="margin-bottom: 0"
            >
              <clip-loader
                :loading="findingLocation"
                color="#888888"
                size="14px"
                class="tw-inline tw-mr-1 tw-align-middle"
              ></clip-loader>
              Please share your location
            </p>
            <p
              v-if="!findingLocation && foundLocation && !customLocationSet"
              class="tw-text-sm tw-text-gray-500"
              style="margin-bottom: 0"
            >
              Your location is {{ roundedLat }}, {{ roundedLng }}.
            </p>
            <p
              v-if="!findingLocation && customLocationSet"
              class="tw-text-sm tw-text-gray-500"
              style="margin-bottom: 0"
            >
              Event location is {{ roundedLat }}, {{ roundedLng }}.
            </p>
            <p
              v-if="!findingLocation && deniedLocation"
              class="tw-text-sm tw-text-red-500"
              style="margin-bottom: 0"
            >
              <icon :icon="['fal', 'exclamation-triangle']" class="tw-h-3 tw-w-3 tw-inline"></icon>
              Location not available. Please enable location sharing
              <span v-if="isThisMobile"> via the settings app.</span>
              <span v-else> or choose location on the map.</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tw-absolute tw-inset-0 tw-rounded-lg tw-border-2 tw-pointer-events-none"
      v-bind:class="[
        selected ? 'tw-border-orange-500' : 'tw-border-transparent',
      ]"
    ></div>
  </li>
</template>

<script>
import { isMobileOnly } from 'mobile-device-detect';
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  props: ["selected", "latitude", "longitude", "customLocation", "errors", "validate"],
  components: {
    ClipLoader,
  },
  data() {
    return {
      findingLocation: true,
      foundLocation: false,
      customLocationSet: false,
      deniedLocation: false,
      lat: null,
      lng: null,
      options: {
        enableHighAccuracy: true, //defaults to false
        timeout: 15000, //defaults to Infinity
      },
    };
  },
  computed: {
    roundedLat() {
      return parseFloat(this.lat.toFixed(8));
    },
    roundedLng() {
      return parseFloat(this.lng.toFixed(8));
    },
    isThisMobile(){
      return isMobileOnly
    }
  },
  watch: {
    latitude(newVal, oldVal) {
      this.lat = newVal;
      this.setCustomLocation();
    },
    longitude(newVal, oldVal) {
      this.lng = newVal;
      this.setCustomLocation();
    },
    customLocation(newVal, oldVal) {
      if (newVal === true) {
        this.setCustomLocation();
      }
    },
    validate(newValue, oldValue) {
      if (newValue === true) {
          this.validateField()
      }
    },
  },
  methods: {
    selectStep() {
        if(isMobileOnly && this.deniedLocation) {
            // custom hook for mobile devices where location unavailable still..
            this.$router.push("/location")
        }
        this.$emit('nextStep', 1)
        this.requestLocation()
    },
    refresh() {
      this.$emit("updateLocation", this.$data);
    },
    validateField() {
        if(this.lat !== null && this.lng !== null) {
            this.$emit("updateErrors", { name: "location", error: false });
        } else {
            this.$emit("updateErrors", { name: "location", error: true });
        }
    },
    setCustomLocation() {
      this.customLocationSet = true;
      this.findingLocation = false;
      this.foundLocation = false;
      this.deniedLocation = false;
      this.validateField();
      this.$emit("nextStep", 2);
    },
    setLocation(lat, lng) {
      this.lat = lat;
      this.lng = lng;
      this.findingLocation = false;
      this.customLocationSet = false;
      this.deniedLocation = false;
      this.foundLocation = true;
      this.validateField();
      this.refresh();
      this.$emit("nextStep", 2);
    },
    requestLocation() {
      console.log("requesting location..");

      this.findingLocation = true;
      this.foundLocation = false;
      this.customLocationSet = false;

      this.$getLocation(this.options)
        .then((coordinates) => {
          this.setLocation(coordinates.lat, coordinates.lng);
        })
        .catch((err) => {
          console.log(err);
          this.findingLocation = false;
          this.foundLocation = false;
          this.deniedLocation = true;
        });
    },
  },
  mounted() {
    this.requestLocation();
    // this.$watchLocation(this.options)
    //   .then((coordinates) => {
    //     console.log("watching location..");
    //     this.setLocation(coordinates.lat, coordinates.lng);
    //   })
    //   .catch((err) => {
    //     this.findingLocation = false;
    //     this.foundLocation = false;
    //     this.deniedLocation = true;
    //     this.$router.push("/location");
    //   });
  },
};
</script>

<style>
</style>