import axios from "axios";

const state = {
  navbarTitle: "risky",
  sidebarOpen: false,
  online: navigator.onLine,
  appOnline: true,
  appLoading: true,
  bannerOpen: false,
  cached: false,
  error: {}
};

const mutations = {
  setTitle(state, title) {
    state.navbarTitle = title;
    document.title = title + " | " + "risky";
  },
  openSidebar(state) {
    state.sidebarOpen = true;
  },
  toggleSidebar(state) {
    state.sidebarOpen = !state.sidebarOpen;
  },
  hideBanner(state) {
    state.bannerOpen = false;
  },
  closeSidebar(state) {
    state.sidebarOpen = false;
    if (document.body.classList.contains("sidebar-show")) {
      document.body.classList.remove("sidebar-show");
    }
  },
  setNetwork(state, value) {
    state.appOnline = value;
  },
  setError(state, newError) {
    return (state.error = newError);
  }
};

const getters = {
  showSidebar(state) {
    return state.sidebarOpen;
  },
  showBanner(state) {
    return state.bannerOpen;
  },
  loading(state) {
    return state.appLoading;
  },
  error(state) {
    return state.error;
  },
  resourcesCached(state) {
    return state.cached;
  }
};

const actions = {
  init({ dispatch }) {
    return new Promise((resolve, reject) => {
      dispatch("auth/refresh")
        .then(res => {
          return Promise.all([
            dispatch("user/userDetail"),
            dispatch("user/getTeams")
          ]);
        })
        .then(res => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  clearData({ commit }) {
    commit("user/setUser", {});
    commit("user/setTeams", []);
  },
  getResource(context, path) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_API_ROOT}${path}`)
        .then(res => {
          if (Object.keys(res.data).includes("results")) {
            resolve(res.data.results);
          } else resolve(res.data);
        })
        .catch(err => reject(err));
    });
  },
  getPaginatedResource(context, path) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_API_ROOT}${path}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => reject(err));
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
