<template lang="html">
  <div class="tw-mt-2">
    <button
      type="submit"
      @click.prevent="$emit('submit')"
      class="btn-orange"
      v-bind:class="{ disabled: loading }"
    >
      {{ loading ? "Saving..." : "Submit" }}
    </button>
    <button
      type="button"
      @click="$emit('cancelchanges')"
      class="tw-ml-2 tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-900 tw-bg-gray-100 hover:tw-bg-gray-200 focus:tw-outline-none"
    >
      Cancel
    </button>
  </div>
</template>

<script>
export default {
  name: "FormActions",
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
