<template>
    <div
        @click="toggle"
        v-on-clickaway="away"
        class="relative"
    >
        <slot name="profile">
            <div>
                <button
                    type="button"
                    class="group tw-w-full tw-rounded-md tw-px-3.5 tw-py-2 tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-900 hover:tw-text-white hover:tw-bg-black-200 focus:tw-bg-black-200 focus:tw-text-white focus:tw-outline-none active:tw-bg-black-200 active:tw-text-gray-800 tw-transition tw-ease-in-out tw-duration-150"
                    aria-haspopup="true"
                    aria-expanded="true"
                >
                    <div class="tw-flex tw-w-full tw-justify-between tw-items-center">
                        <div class="tw-flex tw-items-center tw-justify-between tw-space-x-3">
                            <avatar
                                :username="full_name"
                                :size="35"
                                class="tw-w-10 tw-h-10 tw-rounded-full tw-flex-shrink-0"
                            ></avatar>
                            <div class="tw-flex-1  tw-text-left">
                                <h2 class="tw-text-white tw-text-sm tw-leading-5 tw-font-medium tw-mb-0">{{ full_name }}</h2>
                                <p class="tw-text-gray-300 tw-text-sm tw-leading-5 tw-font-light tw-truncate tw-mb-0">
                                    <icon :icon="['fal', 'hashtag']" class="tw-mr-1"/>{{ current_team }}
                                </p>
                            </div>
                        </div>
                        <svg
                            class="tw-h-5 tw-w-5 tw-text-gray-300 group-hover:tw-text-gray-300"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </div>
                </button>
            </div>
        </slot>
        <slot />
    </div>
</template>

<script>
import Avatar from "vue-avatar";
import { mixin as clickaway } from "vue-clickaway";

export default {
    name: "ProfileDropdown",
    components: {
        Avatar
    },
    mixins: [clickaway],
    provide() {
        return {
            sharedState: this.sharedState
        };
    },
    data() {
        return {
            sharedState: {
                active: false
            }
        };
    },
    computed: {
        full_name: function () {
            return this.$store.getters['user/fullName'];
        },
        current_team() {
            if (this.$store.getters['user/currentTeam']) {
                return this.$store.getters['user/currentTeam'].name;
            }
        },
    },
    methods: {
        toggle() {
            this.sharedState.active = !this.sharedState.active;
        },
        away() {
            this.sharedState.active = false;
        }
    }
};
</script>
