<template>
  <div id="attachments" class="tw-block tw-flex tw-mb-4">
    <!-- <b-progress v-if="uploading" :value="100" variant="info" animated="animate" class="mb-3">
      <b-progress-bar :value="100">Uploading attachment</b-progress-bar>
    </b-progress>
    <b-progress v-if="uploading == 'FAILED'" :value="100" variant="danger" class="mb-3">
      <b-progress-bar :value="100">Error uploading attachment</b-progress-bar>
    </b-progress> -->
    <!-- <Lightbox
      id="mylightbox"
      :images="attachments"
      :image_class=" 'tw-h-10 tw-w-10' "
      :options="opts"
    /> -->
    <CoolLightBox 
      :items="imgs" 
      :index="index"
      @close="index = null"
      :zIndex="1050"
      :closeOnClickOutsideMobile="true"
      :enableScrollLock="true"
    >
    </CoolLightBox>

    <div class="tw-flex">
      <div
        class="tw-h-16 tw-w-16 tw-block tw-rounded-lg tw-shadow tw-mr-2"
        v-for="(image, imageIndex) in imgs"
        :key="imageIndex"
        @click="index = imageIndex"
        :style="{ backgroundImage: 'url(' + image + ')' }"
        style="background-size: cover; background-repeat: no-repeat;"
      ></div>
    </div>

    <!-- <div v-if="attachments.length > 0" @click="openViewerHandle()">
      <AttachmentCard
        v-for="image in attachments"
        :key="image.id"
        :image="image"
        @click="openViewerHandle()"
      ></AttachmentCard>
    </div> -->
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import AttachmentCard from "@/components/Events/AttachmentCard";

export default {
  name: "EventAttachments",
  props: ["attachments", "uploading"],
  components: {
    AttachmentCard,
    CoolLightBox,
  },
  data() {
    return {
      imgPreviewer: false,
      index: null,
      opts: {
        closeText : 'X',
        overlay: true, //show an overlay or not
        captions: false, //show captions if availabled or not
        className: 'tw-bg-black-400', // adds a class to the wrapper of the lightbox
        animationSpeed: 50, //how long takes the slide animation
        animationSlide: true, //weather to slide in new photos or not, disable to fade
        docClose: true, //closes the lightbox when clicking outside,
        maxZoom: 10, // maximum zoom level on pinching
        fadeSpeed: 100, //the duration for fading in and out in milliseconds
      },
    };
  },
  computed: {
    imgs() {
      return this.$props.attachments.map((item) => item.media);
    },
  },
  methods: {
    openViewerHandle() {
      console.log("open");
      this.imgPreviewer = true;
      console.log(this.imgPreviewer);
    },
    closeViewerHandle() {
      this.imgPreviewer = false;
    },
  },
};
</script>

<style>
.sl-overlay {
  background-color: rgba(0, 0, 0, 1) !important;
  opacity: 0.9;
}
.sl-wrapper .sl-close {
  color: #fff;
  font-size: 1.25rem;
}
.my-gallery a img {
  @apply tw-block tw-h-16 tw-w-16 tw-rounded-lg tw-shadow tw-mr-1
}
</style>