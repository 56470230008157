<template>
  <form @submit.prevent="onSubmit">
    <div class="tw-mb-3">
      <div class="tw-space-y-6 tw-px-2">
        <div v-if="errors" class="alert alert-danger" v-text="errors"></div>
        <div class="form-group">
          <textarea
            class="form-control"
            name="resolution"
            rows="12"
            placeholder="How was this incident resolved... (optional)"
            v-model="resolution"
            @keydown="errors = ''"
          ></textarea>
        </div>
        <div class="tw-flex tw-flex-col tw-justify-stretch tw-space-y-2 lg:tw-space-y-0 lg:tw-justify-start lg:tw-flex-row">
          <button
            type="submit"
            class="btn-orange lg:tw-mr-2"
          v-bind:class="{ 'disabled' : isLoading }"
        >Mark resolved</button>
        <button class="btn-gray" @click="$router.go(-1)">Cancel</button>
      </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";

export default {
  name: "add-resolution",
  data() {
    return {
      resolution: "",
      broadcast: true,
      event: "",
      errors: "",
      isLoading: false
    };
  },
  methods: {
    onSubmit() {
      if (this.isLoading) return;

      this.postMessage();
    },
    async postMessage() {
      this.isLoading = true;
      this.event = this.$route.params.eventID;

      axios
        .post(
          process.env.VUE_APP_API_ROOT +
            "incidents/" +
            this.$route.params.eventID +
            "/resolve/",
          {resolution: this.resolution}
        )
        .then(response => {
          this.$emit("completed", response.data.data);
          
          this.$router.back();
        })
        .catch(error => {
          this.errors = error.response.data.message;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
