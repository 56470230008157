<template>
  <b-dropdown id="ddown1" class="mt-1" variant="light">
    <template slot="button-content">
      Export
    </template>
    <b-dropdown-item @click="getCSVReport()"><icon :icon="['fal', 'file-csv']" fixed-width/> Export (CSV)</b-dropdown-item>
    <!-- <b-dropdown-item><icon :icon="['fal', 'file-pdf']" fixed-width/> Export (PDF)</b-dropdown-item> -->
  </b-dropdown>
</template>

<script>
export default {
  name: 'export-dropdown',
  methods: {
    async getCSVReport() {
      this.$http({
          url: `${process.env.VUE_APP_API_ROOT}incidents/csv/`,
          method: 'GET',
          responseType: 'blob',
      }).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "report.csv");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style>
</style>