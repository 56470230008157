<template>
  <div class="tw-w-full">
    <label class="sr-only">What needs to be done about it?</label>
    <div class="tw-mt-1 tw-relative tw-rounded-md tw-shadow-sm">
      <textarea
        v-model="solution_text"
        @keydown.enter="nextField()"
        @keydown.enter.prevent
        @keydown.tab="nextField()"
        @keydown.tab.prevent
        type="text"
        ref="solution"
        name="solution"
        rows="3"
        class="tw-block tw-w-full tw-pr-10 tw-shadow-sm focus:tw-outline-none focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full sm:tw-text-sm tw-border tw-border-gray-300 tw-rounded-md"
        style="padding-top: 0.5rem; padding-bottom: 0.5rem; padding-left: 0.75rem;"
        placeholder="What needs to be done about it..."
      ></textarea>
      <div
        v-if="isError"
        class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-pointer-events-none"
      >
        <!-- Heroicon name: exclamation-circle -->
        <svg
          class="tw-h-5 tw-w-5 tw-text-red-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <p
      class="tw-mt-2 tw-text-sm tw-text-red-600"
      v-if="isError && error"
      style="margin-bottom: 0"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  name: "SolutionInput",
  props: ["focus", "errors", "validate", "solution"],
  data() {
    return {
      solution_text: "",
      error: "",
      isError: false,
      fieldName: "solution",
    };
  },
  watch: {
    solution_text(newValue, oldValue) {
      this.validateField();
      this.$emit("updateSolution", newValue);
    },
    validate(newValue, oldValue) {
      if (newValue === true) {
        this.validateField();
        this.$emit("updateSolution", this.solution_text);
      }
    },
    focus(newValue, oldValue) {
      if (newValue === true) {
        this.$refs[this.fieldName].focus();
      }
    },
  },
  methods: {
    validateField() {
      if (this.solution_text.length >= 100) {
        //   Check if under 50 characters
        this.isError = true;
        this.error = "Must be less than 100 characters.";
        this.$emit("updateErrors", { name: this.fieldName, error: true });
      } else {
        //   Clear errors
        this.isError = false;
        this.error = "";
        this.$emit("updateErrors", { name: this.fieldName, error: false });
      }
    },
    nextField() {
      this.$emit("nextField");
      this.$refs[this.fieldName].blur();
    },
  },
};
</script>