<template>
  <fieldset class="tw-w-full">
  <ul class="tw-space-y-4" role="radiogroup" aria-labelledby="radiogroup-label" style="margin-bottom: 0">
    <li @click="select(opt.value)" v-for="(opt, i) in options" v-bind:key="opt.value" :id="`radiogroup-option-${i}`" :tabindex="i" role="radio" aria-checked="true" class="tw-group tw-relative tw-bg-white tw-rounded-lg tw-shadow-sm tw-cursor-pointer focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-offset-2 focus:tw-ring-orange-500">
      <div class="tw-rounded-lg tw-border tw-border-gray-300 tw-bg-white tw-px-4 tw-py-4 hover:tw-border-gray-400 tw-flex">
        <div class="tw-mt-2 tw-flex-shrink-0 tw-text-sm sm:tw-mt-0 sm:tw-block tw-mr-4">
          <icon :icon="opt.icon" size="2x" class="tw-text-orange-500"/>
        </div>
        <div class="tw-flex tw-items-center">
          <div class="tw-text-sm">
            <p class="tw-font-medium tw-text-gray-900" style="margin-bottom: 0">
              {{ opt.label }}
            </p>
          </div>
        </div>
      </div>
      <!-- On: "tw-border-orange-500", Off: "tw-border-transparent" -->
      <div v-if="selected == opt.value" class="tw-border-orange-500 tw-absolute tw-inset-0 tw-rounded-lg tw-border-2 tw-pointer-events-none" aria-hidden="true"></div>
    </li>
  </ul>
</fieldset>
</template>

<script>
export default {
    props: ["selected"],
    data() {
        return {
            options: [
                {
                    label: "Feedback or suggestions",
                    value: "improvement",
                    icon: ['fal', 'comments']
                },
                {
                    label: "Report an issue",
                    value: "bug",
                    icon: ['fal', 'exclamation-triangle']
                },
                {
                    label: "Get support",
                    value: "support",
                    icon: ['fal', 'user-headset']
                }
            ]
        }
    },
    methods: {
        select(value) {
            this.$emit("select", value)
        }
    }
}
</script>

<style>

</style>