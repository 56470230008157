<template>
    <div>
        <FormTextField
            name="emergency_info"
            label="Emergency info"
            :value="emergency_info"
            @textchange="updateValue"
            text_type="long"
            :error="errors.emergency_info"
        />

        <FormActions
            @submit="postForm"
            :loading="loading"
            @cancelchanges="refreshValues"
        />
    </div>
</template>

<script>
import FormTextField from '../../vue-prism/components/form/FormTextField.vue'
import FormActions from '../../vue-prism/components/form/FormActions.vue'

export default {
    name: 'TeamEmergencyInfoForm',
    props: {
        team: {
            type: Object,
            default: () => ({
                name: '',
                emergency_info: '',
            }),
        },
    },
    data() {
        return {
            emergency_info: this.team.emergency_info,
            loading: false,
            errors: {},
        }
    },
    watch: {
        team(newVal) {
            this.emergency_info = newVal.emergency_info
        }
    },
    components: {
        FormTextField,
        FormActions,
    },
    methods: {
        updateValue(name, value) {
            this[name] = value
        },
        refreshValues() {
      this.errors = {};
      this.emergency_info = this.team.emergency_info || "";
      this.$emit("cancel");
    },
        postForm() {
            if (this.loading) return
            this.loading = true

            this.$store
                .dispatch('user/updateTeam', { emergency_info: this.emergency_info })
                .then((response) => {
                    // potential updates here
                    this.refreshValues()
                })
                .catch((error) => {
                    // set global error alert
                    if (error.response) {
                        const { data } = error.response.data
                        /** 405 error could be handled better **/
                        Object.keys(data).map((field) => {
                            this.errors[field] = data[field][0]
                        })
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
    },
}
</script>
