<template>
  <div class="animated fadeIn">
    <div class="tw-mt-8 tw-mb-4 tw-px-2">
      <div class="">
        <h3>Set title for incident</h3>
      </div>
    </div>
    <RenameEventForm></RenameEventForm>
  </div>
</template>

<script>
import RenameEventForm from "@/components/Events/Forms/RenameEventForm";

export default {
  name: "rename",
  components: {
    RenameEventForm
  }
};
</script>
