<template>
  <div class="tw-w-full">
    <b-form-radio-group
      buttons
      id="risk_level"
      v-model="selected"
      name="radio-sub-component"
      class="tw-w-full d-flex"
      @input="setSeverity"
    >
      <b-form-radio
        v-for="btn in risk_levels"
        :key="btn.key"
        :button-variant="btn.variant"
        :value="btn.key"
        class="flex-fill"
        >{{ btn.label }}</b-form-radio
      >
    </b-form-radio-group>
    <p
      class="tw-mt-2 tw-text-sm tw-text-red-600"
      v-if="isError && error"
      style="margin-bottom: 0 !important"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  name: "SeverityInput",
  props: ["severity", "validate"],
  data() {
    return {
      selected: null,
      risk_levels: [
        {
          label: "Low",
          key: "low",
          variant: "outline-success",
          state: false,
        },
        {
          label: "Medium",
          key: "medium",
          variant: "outline-warning",
          state: false,
        },
        {
          label: "High",
          key: "high",
          variant: "outline-caution",
          state: false,
        },
        {
          label: "Extreme",
          key: "extreme",
          variant: "outline-danger",
          state: false,
        },
      ],
      error: "",
      isError: false,
      fieldName: "severity",
    };
  },
  watch: {
    validate(newValue, oldValue) {
      if (newValue === true) {
        this.validateField();
        this.$emit("updateSeverity", this.selected);
      }
    },
  },
  methods: {
    setSeverity(event) {
      this.validateField();
      this.$emit("updateSeverity", this.selected);
    //   this.$emit("nextStep");
    },
    validateField() {
      if (
        this.selected === null ||
        this.selected === "" ||
        this.selected.length === 0
      ) {
        //   Check if a tag is set
        this.isError = true;
        this.error = "Please choose a risk level.";
        this.$emit("updateErrors", { name: this.fieldName, error: true });
      } else {
        //   Clear errors
        this.isError = false;
        this.error = "";
        this.$emit("updateErrors", { name: this.fieldName, error: false });
      }
    },
  },
};
</script>

<style>
</style>