<template>
  <Layout>
    <PageHeader slot="header" title="Events">
      <template slot="page-buttons">
        <router-link :to="{ name: 'New incident' }" class="btn-orange">
          <span class="tw-text-white">
            <icon :icon="['fal', 'plus']" class="tw-mr-1" />New event
          </span>
        </router-link>
      </template>
    </PageHeader>

    <events-desktop v-if="!isMobileOnly"></events-desktop>
    <events-mobile v-else></events-mobile>
  </Layout>
</template>

<script>
import EventsDesktop from "@/views/Events/Events-desktop";
import EventsMobile from "@/views/Events/Events-mobile";
import ExportDropdown from "@/components/Inputs/ExportDropdown";
import { isMobileOnly } from "mobile-device-detect";

export default {
  name: "events",
  components: {
    EventsDesktop,
    EventsMobile,
    ExportDropdown,
  },
  computed: {
    isMobileOnly: function () {
      if (isMobileOnly) {
        return true;
      }
    },
  },
};
</script>
