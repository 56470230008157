<template>
  <div id="ReopenPanel">
    <nav class="navbar bg-white fixed-bottom border-top border-secondary d-lg-none pb-4 pt-2">
      <button class="btn btn-danger btn-lg btn-block" @click="reopen()">Reopen incident</button>
    </nav>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "event-reopen",

  props: ["visible"],
  data() {
    return {
      
    };
  },
  methods: {
    async reopen() {
      this.actionSheetOpen = false;
      axios
        .put(
          process.env.VUE_APP_API_ROOT +
            "events/" +
            this.$route.params.eventID +
            "/reopen/",
          this.$data
        )
        .then(response => {
          // this.$emit("completed", response.data.data);
          this.$router.push({
            name: "Incidents",
          });
        })
        .catch(error => {
          // handle authentication and validation errors here
          this.errors = error.response.data.errors;
        });
    },
  }
};
</script>

<style scoped>
/* .table th, .table td {
  border-top: 1px solid rgba(0,0,0,.125);
} */
</style>
