<template>
  <div class="tw-w-full">
    <label for="email" class="sr-only">What is happening?</label>
    <div class="tw-mt-1 tw-relative tw-rounded-md tw-shadow-sm">
      <input
        v-model="title"
        @keydown.enter="nextField()"
        @keydown.enter.prevent
        @keydown.tab="nextField()"
        @keydown.tab.prevent
        type="text"
        ref="title"
        class="tw-block tw-w-full tw-pr-10 tw-shadow-sm focus:tw-outline-none focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
        placeholder="In a few words, what is happening..."
      />
      <!-- <input
        type="text"
        name="email"
        id="email"
        class="tw-block tw-w-full tw-pr-10 tw-border-red-300 tw-text-red-900 tw-placeholder-gray-300 focus:tw-outline-none focus:tw-ring-red-500 focus:tw-border-red-500 sm:tw-text-sm tw-rounded-md"
        placeholder="In a few words, what is happening..."
      /> -->
      <div
        v-if="isError"
        class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-pointer-events-none"
      >
        <!-- Heroicon name: exclamation-circle -->
        <svg
          class="tw-h-5 tw-w-5 tw-text-red-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <p
      class="tw-mt-2 tw-text-sm tw-text-red-600"
      v-if="isError && error"
      style="margin-bottom: 0"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  props: ["focus", "errors", "validate"],
  data() {
    return {
      title: "",
      error: "",
      isError: false,
      fieldName: "title"
    };
  },
  watch: {
    title(newValue, oldValue) {
      this.validateField();
      this.$emit("updateTitle", newValue);
    },
    validate(newValue, oldValue) {
      if (newValue === true) {
        this.validateField();
        this.$emit("updateTitle", this.title);
      }
    },
    focus(newValue, oldValue) {
      if (newValue === true) {
        this.$refs[this.fieldName].focus();
      }
    },
  },
  methods: {
    validateField() {
      if (this.title === "" || this.title === null || this.title.length === 0) {
        //   Check if value set
        this.isError = true;
        this.error = "This field is required.";
        this.$emit("updateErrors", { name: this.fieldName, error: true });
      } else if (this.title.length >= 50) {
        //   Check if under 50 characters
        this.isError = true;
        this.error = "Keep this less than 50 characters.";
        this.$emit("updateErrors", { name: this.fieldName, error: true });
      } else {
        //   Clear errors
        this.isError = false;
        this.error = "";
        this.$emit("updateErrors", { name: this.fieldName, error: false });
      }
    },
    nextField() {
        this.$emit('nextField')
        this.$refs[this.fieldName].blur();
    }
  },
};
</script>

<style>
</style>