<template>
  <router-link
    :to="to"
    class="tw-w-full focus:tw-text-orange-500 hover:tw-text-orange-500 tw-justify-center tw-inline-block tw-text-center tw-pt-3 tw-pb-5"
  >
    <icon
      :icon="icon"
      fixed-width
      class="tw-inline-block tw-mb-1"
      style="font-size: 1.25rem"
      :class="[$route.name == to.name ? active : inactive]"
    />
    <span
      class="tab tab-home tw-block tw-text-xs"
      :class="[$route.name == to.name ? active : inactive]"
      >{{label}}</span
    >
  </router-link>
</template>

<script>
export default {
  props: ["to", "label", "icon"],
  data() {
    return {
      active: "tw-text-orange-500",
      inactive: "tw-text-gray-400",
    };
  },
};
</script>