<template>
  <hooper
    ref="carousel"
    :settings="hooperSettings"
    @click="clickSlide"
    @beforeSlide="log('before')"
    @slide="log('sliding')"
    @afterSlide="log('after slide')"
  >
    <slide
      v-for="item in attachments"
      :key="item.id"
      :index="item.id"
      v-bind:style="{ backgroundImage: 'url(' + item.media + ')' }"
      style="
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      "
      class="tw-w-100 tw-bg-gray-100"
    >
    </slide>
    <slide
      v-bind:style="{ backgroundImage: 'url(' + map + ')' }"
      style="
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      "
      class="tw-w-100 tw-bg-gray-100"
    ></slide>
    <hooper-pagination slot="hooper-addons"></hooper-pagination>
  </hooper>
</template>

<script>
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";

import "hooper/dist/hooper.css";

export default {
  name: "EventMediaCarousel",
  props: ["attachments", "map"],
  components: {
    Hooper,
    Slide,
    HooperPagination,
  },
  data() {
    return {
      hooperSettings: {
        itemsToShow: 1,
        centerMode: true,
        infiniteScroll: true,
      },
    };
  },
  methods: {
    clickSlide(event) {
      console.log("testing slide2");
      if (this.$refs.carousel.isSliding) {
        console.log("testing slide");
        event.preventDefault();
      }
    },
     log(event) {
      console.log(event);
    },
  },
  created() {
    console.log(this.$props.attachments);
    console.log(this.$props.attachments[0]);
  },
};
</script>

<style>
</style>