<template>
  <nav
    class="navbar bg-white fixed-bottom border-top border-secondary d-lg-none pb-3 pt-2 px-2 d-block"
  >
    <!-- <button class="btn btn-primary btn-lg btn-80" @click="addMessage()">Add message</button>
      <button class="btn btn-light btn-lg btn-20" @click="actionSheetOpen = true">
        <i class="fas fa-ellipsis-h"></i>
    </button>-->
    <icon
      @click="$emit('openActionSheet')"
      :icon="['fal','plus-circle']"
      fixed-width
      class="text-risky mr-2"
      style="font-size: 1.8rem;"
    />
    <!-- <icon :icon="['fal','camera']" fixed-width class="text-muted mr-2" style="font-size: 1.5rem;" /> -->
    <AddMessageNavItem></AddMessageNavItem>
  </nav>
</template>

<script>
import AddMessageNavItem from "@/components/Events/Actions/AddMessageNavItem";

export default {
  name: "EventBottomNav-mobile",
  components: {
    AddMessageNavItem
  }
};
</script>

<style>
</style>