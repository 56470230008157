import Vue from 'vue'
import Vuex from 'vuex'

import app from '@/state/app'
import auth from '@/state/auth'
import user from '@/state/user'
import team from '@/state/team'

Vue.use(Vuex);

export default new Vuex.Store ({
  modules: {
    app,
    auth,
    user,
    team
  },
  plugins: [],
})
