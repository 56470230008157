<template>
  <a @click="imResponding()" href="#" class="btn btn-sm btn-outline-secondary text-muted">
    <icon :icon="['fal','walking']" /> I'm responding
  </a>
</template>

<script>
export default {
  name: "ImRespondingQuickButton",
  methods: {
    async imResponding() {
      this.$http
        .post(
          process.env.VUE_APP_API_ROOT +
            "incidents/" +
            this.$route.params.eventID +
            "/responding/",
          this.$data
        )
        .then(response => {
          this.$emit("updateTimeline");
        })
        .catch(error => {
          // handle authentication and validation errors here
          this.errors = error.response.data.errors;
        });
    }
  }
};
</script>

<style>
</style>