<template>
  <div class="tw-h-screen tw-flex">
    <OffcanvasSidebar />
    <SidebarContainer :navItems="nav" />
    <div
      class="tw-flex tw-flex-col tw-w-0 tw-flex-1 focus:tw-outline-none"
      tabindex="0"
    >
      <top-bar></top-bar>
      <div class="tw-py-8 lg:tw-py-0 tw-overflow-y-auto">
        <router-view></router-view>
      </div>
      <bottom-bar></bottom-bar>
    </div>
    <support-modal></support-modal>
  </div>
</template>

<script>
import Vue from "vue";
import nav from "@/_nav2";
import BottomBar from "@/components/ui/Nav/BottomBar.vue";
import TopBar from "@/components/ui/Nav/TopBar.vue";
import SidebarContainer from "@/containers/SidebarContainer";
import SidebarProfileComponent from "@/components/SidebarProfileComponent";
import OffcanvasSidebar from "@/components/ui/Nav/OffcanvasSidebar.vue";
import SupportModal from "@/lib/supportModal.js"; // Vue Plugin

/**
 * Registering on click outside event for non button elements
 * - Used in Multiselect component
 * */
Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

export default {
  name: "DefaultContainer",
  components: {
    TopBar,
    BottomBar,
    OffcanvasSidebar,
    SidebarProfileComponent,
    SidebarContainer,
  },
  data() {
    return {
      nav: nav.items,
      user: "test",
    };
  },
  computed: {
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(
        (route) => route.name || route.meta.label
      );
    },
  },
};
</script>
