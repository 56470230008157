<template>
    <div class="tw-relative tw-w-full tw-h-80 tw-min-h-min tw-max-h-full tw-max-w-full tw-flex">
        <div
            @dragenter="draggingOver = true"
            @dragleave="draggingOver = false"
            @drop="draggingOver = false"
            @dragend="draggingOver = false"
            class="tw-flex-grow tw-relative hover:tw-bg-gray-100 tw-border-orange-500 hover:tw-border-orange-300 hover:tw-cursor-pointer tw-transition-colors tw-m-2 tw-rounded-md tw-border tw-border-dashed tw-cursor-pointer"
            :class="draggingOver ? 'tw-bg-gray-100 tw-border-orange-300' : ''"
        >
            <input type="file" :accept="fileTypes.join(', ')" class="tw-opacity-0 tw-absolute tw-inset-0 tw-cursor-pointer" @change="onUpdate" ref="fileInput" />
            <div class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-2 tw-cursor-pointer">
                <div v-if="file.url && file.type.startsWith('image/')" class="tw-h-3/4 tw-flex tw-flex-grow tw-bg-red-300">
                    <img :src="file.url" class="tw-max-h-full tw-flex-grow" />
                </div>
                <h4 
                    class="tw-relative tw-flex tw-justify-center tw-flex-wrap tw-items-center tw-text-base tw-font-medium md:tw-font-normal md:tw-text-xl tw-m-0"
                    @click.prevent="$refs.fileInput.click()"
                    :class="file.url ? 'tw-pr-9' : ''"
                >
                    <span v-if="!file.url" class="tw-mx-2">
                        <icon :icon="['fal','upload']" size="lg" />
                    </span>
                    <span class="tw-max-w-full tw-break-normal tw-text-center">{{ displayText }}</span>
                    <button v-if="file.url" type="button" class="tw-mx-2 tw-absolute tw-text-xl tw-top-0 tw-right-0 tw-bottom-0" @click.stop.prevent="clearFile" >
                        <icon :icon="['fal','times-circle']" size="lg" class="tw-text-red-500" />
                    </button>
                </h4>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "FileUpload",
    props: {
        fileTypes: { type: Array, default: () => [] },
        message: { type: Object, default: () => ({}) },
        textPrompt: { type: String, default: "Click / Drag and Drop to upload a file" }
    },
    data() {
        return {
            file: {
                url: "",
                name: "",
                type: ""
            },
            draggingOver: false
        }
    },
    methods: {
        async onUpdate(e) {
            let file = undefined;

            if (e.target.files && e.target.files[0]) {
                file = e.target.files[0];
                const url = await this.generateUrl(e.target.files[0]);

                this.file = {
                    url: url,
                    name: file.name,
                    type: file.type
                }
            } else {
                file = undefined;

                this.file = {
                    url: "",
                    name: "",
                    type: ""
                }
            }

            this.$emit("change", file);
        },
        generateUrl (file) {
            let reader = new FileReader();

            return new Promise((resolve) => {
            reader.readAsDataURL(file);

            reader.onloadend = function () {
                    if (typeof reader.result === 'string') {
                        resolve(reader.result);
                    } else {
                        resolve("");
                    }
                }
            });
        },
        clearFile () {
            this.$refs.fileInput.value = null;

            this.file = {
                    url: "",
                    name: "",
                    type: ""
                }
            
            this.$emit("change", undefined);
        }
    },
    computed: {
        displayText() {
            if (this.file.url && this.file.name) {
                if (this.file.name.length < 25) {
                    return this.file.name
                } else {
                    return this.file.name.substr(0, 25) + "...";
                }
            }
            return this.textPrompt;
        }
    },
    watch: {
        message(newVal) {
            switch (newVal.action) {
                case 'choose':
                    this.$refs.fileInput.click();
                    break;
                case 'change':
                    this.clearFile();
                    this.$refs.fileInput.click();
                    break;
                default: // use this to clear file
                    this.clearFile();
            }
        }
    }
}
</script>
