<template lang="html">
  <div class="sm:w-5/12 w-full">
    <label class="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
      {{ label ? label : "Date" }} <span v-if="requiredField" class="text-sm text-pink-500">*</span>
    </label>
    <div class="relative flex rounded-md shadow-xs">
      <input
        type="date"
        v-model="input_value"
        v-on:input="updateParent"
        class="field"
        :max="max"
      />
      <InvalidInput
      :message="date_error"
      :show="date_error">
      </InvalidInput>
    </div>
  </div>
</template>

<script>
import InvalidInput from "@/components/ui/InvalidInput";
export default {
  name: 'FormDateField',
  components: {
    InvalidInput
  },
  props: ['value', 'label', 'requiredField', 'name', 'error', 'max'],
  data() {
    return {
      input_value: null
    }
  },
  computed: {
    date_error() {
      if (this.error) {
        return this.error;
      }
      return ""
    }
  },
  methods: {
    initDate() {
      if (this.value) {
        this.input_value = this.value.split("/").reverse().join("-");
      }
    },
    updateParent() {
      this.$emit('change', this.name, this.input_value);
    }
  },
  mounted() {
    this.initDate();
  }
}
</script>