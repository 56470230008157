const SettingsHome = () => import('@/views/Settings/SettingsHome.vue');
const Security = () => import('@/views/Settings/Security.vue');
const Support = () => import('@/views/Settings/Support.vue');
const Profile = () => import('@/views/Settings/Profile.vue');
const Notifications = () => import('@/views/Settings/Notifications.vue');
// const About = () => import('@/views/Settings/About.vue');

const routes = [
  {
    path: "home",
    name: "Settings home",
    component: SettingsHome,
    meta: {
      title: "Settings"
    }
 },
//  {
//     path: "about",
//     name: "About app",
//     component: About,
//     meta: {
//       title: "About",
//       showBackButton: true,
//     }
// },
 {
     path: "profile",
     name: "Profile",
     component: Profile,
     meta: {
       title: "Profile",
       showBackButton: true,
     }
 },
 {
     path: "security",
     name: "Security",
     component: Security,
     meta: {
       title: "Security",
       showBackButton: true,
     }
 },
{
    path: "notifications",
    name: "Notifications",
    component: Notifications,
    meta: {
      title: "Notifications",
      showBackButton: true,
    }
},
{
    path: "support",
    name: "Help & support",
    component: Support,
    meta: {
      title: "Help & support",
      showBackButton: true,
    }
}
]

export default routes;
