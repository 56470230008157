<template>
  <vue-content-loading v-bind="$attrs" :width="200" :height="25">
    <rect x="0" y="0" rx="3" ry="3" width="150" height="10" />
    <rect x="0" y="15" rx="3" ry="3" width="100" height="7" />
  </vue-content-loading>
</template>

<script>
import VueContentLoading from "vue-content-loading";

export default {
  components: {
    VueContentLoading
  }
};
</script>