<template>
  <tr>
    <td width="1%" class="px-0 pt-3 align-top">
      <avatar :username="item.created_user" size="25"></avatar>
    </td>
    <td>
      <span>
        <b>{{item.created_user}}</b>
        <span class="text-muted ml-1">{{ item.time_ago }}</span>
      </span>
      <br />
      <span v-html="item.message"></span>
    </td>
  </tr>
</template>

<script>
import Avatar from "vue-avatar";
export default {
  name: "timeline-item",
  props: ["item"],
  components: {
    Avatar
  }
};
</script>

<style>
</style>