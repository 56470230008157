<template>
  <div id="MoreModal" class="overlay h-0">
    <div class="overlay-content justify-content-center text-center">
      <div class="container-fluid">
        <h1 class="text-dark mb-4">Choose an action...</h1>
        <button type="button" class="btn btn-block btn-light py-3 text-primary" @click="responding()">
          <i class="fas fa-2x fa-walking float-left"></i>
          <h3>I'm responding</h3>
        </button>
        <button type="button" class="btn btn-block btn-light py-3 text-dark">
          <i class="fas fa-2x fa-images float-left"></i>
          <h3>Add photo or video</h3>
        </button>
        <button type="button" class="btn btn-block btn-light py-3 text-danger" @click="esoCalled()">
          <i class="fas fa-2x fa-phone-volume float-left"></i>
          <h3>Call Emergency Services</h3>
        </button>
        <button type="button" class="btn btn-block btn-light py-3 text-success">
          <i class="fas fa-2x fa-check-double float-left"></i>
          <h3>Mark as resolved</h3>
        </button>

        <h1 class="text-dark mt-4 mb-4">Update severity...</h1>
        <button type="button" class="btn btn-dark pt-2 pb-1 px-3 mb-1" @click="updateSeverity('extreme')">
          <h4>Extreme</h4>
        </button>
        <button type="button" href class="btn btn-danger pt-2 pb-1 px-3 mb-1" @click="updateSeverity('high')">
          <h4>High</h4>
        </button>
        <button type="button" href class="btn btn-warning pt-2 pb-1 px-3 mb-1" @click="updateSeverity('medium')">
          <h4>Medium</h4>
        </button>
        <button type="button" href class="btn btn-success pt-2 pb-1 px-3 mb-1" @click="updateSeverity('low')">
          <h4>Low</h4>
        </button>
        <button type="button" class="btn btn-block btn-link mt-4" @click="closeNav()">
          <i class="fas fa-5x fa-times text-muted"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventMoreModal",
  props: ["eventID"],
  data() {
    return {
      severity: "",
      
    };
  },
  methods: {
    closeNav() {
      document.getElementById("MoreModal").style.height = "0%";
    },
    async updateSeverity(severity) {
      this.severity = severity;
      this.closeNav();
      axios
        .put(process.env.VUE_APP_API_ROOT + "events/" +  this.$route.params.eventID + "/severity/", this.$data)
        .then(response => {
          this.$emit("completed", response.data.data);
          this.$router.go();
        })
        .catch(error => {
          // handle authentication and validation errors here
          this.errors = error.response.data.errors;
        });
    },
    async responding() {
      this.closeNav();
      axios
        .put(process.env.VUE_APP_API_ROOT + "events/" +  this.$route.params.eventID + "/responding/", this.$data)
        .then(response => {
          this.$emit("completed", response.data.data);
          this.$router.go();
        })
        .catch(error => {
          // handle authentication and validation errors here
          this.errors = error.response.data.errors;
        });
    },
    async esoCalled() {
      this.closeNav();
      axios
        .put(process.env.VUE_APP_API_ROOT + "events/" +  this.$route.params.eventID + "/esoCalled/", this.$data)
        .then(response => {
          this.$emit("completed", response.data.data);
          window.location.href = 'tel:000'
          this.$router.go();
        })
        .catch(error => {
          // handle authentication and validation errors here
          this.errors = error.response.data.errors;
        });
    }
  }
};
</script>

<style scoped>
.overlay {
  height: 0%;
  width: 100%;
  position: fixed;
  z-index: 2000;
  bottom: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  overflow-y: hidden;
  transition: 0.5s;
}
.overlay-content {
  position: relative;
  top: 3%;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}
.overlay a {
  transition: 0.3s;
}
</style>