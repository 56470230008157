<template>
  <div class="animated fadeIn">
    <div v-if="!isMobileOrTablet()" class="tw-mt-8 tw-mb-4 tw-px-2">
      <div class="">
        <h2>Update incident risk level</h2>
      </div>
    </div>
    <ChangeSeverityForm></ChangeSeverityForm>
  </div>
</template>

<script>
import ChangeSeverityForm from "@/components/Events/Forms/ChangeSeverityForm";

export default {
  name: "change-severity",
  components: {
    ChangeSeverityForm
  }
};
</script>
