<template>
  <div class="card shadow border-0 bg-white rounded mb-3">
    <div class="row no-gutters">
      <div
        class="col-4 rounded-left"
        v-bind:style="{ backgroundImage: 'url(' + image.media + ')' }"
        style="background-size: cover; background-repeat: no-repeat;"
      ></div>
      <div class="col-8">
        <div class="card-block p-4">
          <h5 class="card-title">
            {{ image.time_ago }}
            
          </h5>
          <!-- <avatar :username="image.uploader.name" size="25"></avatar> -->
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Avatar from 'vue-avatar'

export default {
  name: "AttachmentCard",
  props: ["image"],
  components: {
    Avatar
  },
  computed: {}
};
</script>
