<template>
  <team-info-card class="tw-mt-4"></team-info-card>
</template>

<script>
import TeamInfoCard from '@/components/Team/TeamInfoCard.vue';

export default {
  components: { TeamInfoCard },
};
</script>

<style>

</style>