<template>
    <router-link
        :to="to"
        class="tw-block tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus:tw-bg-gray-100 focus:tw-text-gray-900"
        role="menuitem"
    >
        <slot />
    </router-link>
</template>

<script>
export default {
    name: "ProfileDropdownItem",
    props: ["to"],
};
</script>
