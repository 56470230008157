<template lang="html">
    <div class="sm:tw-col-span-3">
        <label v-if="!hideLabel" class="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
            {{ label }}
            <span v-if="requiredField" class="tw-text-sm tw-text-pink-500">*</span>
        </label>
        <div class="tw-relative tw-flex tw-mt-1 tw-rounded-md tw-shadow-sm">
            <textarea
                v-if="long_text"
                name="name"
                rows="8"
                cols="80"
                v-model="input_value"
                v-on:input="updateParent"
                maxlength="5000"
                :placeholder="placeholder"
                class="field"
                :class="{ 'tw-border-red-500': !!error }"
            >
            </textarea>
            <input
                v-else
                type="text"
                v-model="input_value"
                v-on:input="updateParent"
                maxlength="150"
                :placeholder="placeholder"
                class="field"
                :class="{ 'tw-border-red-500': !!error }"
            />
            <p
                class="tw-absolute tw-left-1.5 tw-top-full tw-px-2 tw-text-sm tw-text-red-500 tw-rounded-b"
                :class="error ? '' : 'tw-invisible'"
            >
                {{ error }}
            </p>
        </div>
    </div>
</template>

<script>

export default {
    name: 'FormTextField',
    components: {
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        label: String,
        requiredField: Boolean,
        hideLabel: Boolean,
        name: {
            type: String,
            required: true,
        },
        error: {
            type: String,
            default: "",
        },
        text_type: {
            type: String,
            default: 'short',
        },
        placeholder: String,
    },
    data() {
        return {
            input_value: this.value
        }
    },
    computed: {
        long_text() {
            return this.text_type && this.text_type === 'long'
        },
    },
    methods: {
        updateParent() {
            this.$emit('textchange', this.name, this.input_value)
        },
    },
    watch: {
        value: function() {
            this.input_value = this.value
        },
    },
}
</script>
