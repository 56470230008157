<template>
  <form
    @submit.prevent="validateForm"
    class="tw-grid tw-grid-cols-1 tw-gap-y-6 sm:tw-grid-cols-2 sm:tw-gap-x-8"
  >
    <div
      v-if="isError"
      class="sm:tw-col-span-2 tw-rounded-md tw-bg-red-50 tw-p-4"
    >
      <div class="tw-flex">
        <div class="tw-flex-shrink-0">
          <svg
            class="tw-text-red-600 tw-w-6 tw-h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
        </div>
        <div class="tw-ml-3 tw-flex-1 md:tw-flex md:tw-justify-between">
          <p class="tw-text-sm tw-text-red-700" style="margin-bottom: 0">
            Unable to submit feedback at this time. Please email
            <a href="mailto:sam@riskfacilitator.com">sam@riskfacilitator.com</a>
            for support.
          </p>
        </div>
      </div>
    </div>
    <div class="sm:tw-col-span-2">
      <label
        for="company"
        class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
        >Short summary</label
      >
      <div class="tw-mt-1">
        <input
          v-model="title"
          ref="title"
          type="text"
          name="title"
          id="title"
          class="tw-py-3 tw-px-4 tw-block tw-w-full tw-shadow-sm focus:tw-ring-orange-500 focus:tw-border-orange-500 tw-border-gray-300 tw-rounded-md"
        />
      </div>
      <p
        v-if="errors.includes('title')"
        class="tw-mt-2 tw-text-sm tw-text-red-600"
        style="margin-bottom: 0"
      >
        This field is required
      </p>
    </div>
    <div class="sm:tw-col-span-2">
      <label
        for="message"
        class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
        >Type of feedback</label
      >
      <feedback-type-radio-group
        :selected="feedback_type"
        @select="selectFeedbackType"
      ></feedback-type-radio-group>
    </div>
    <div class="sm:tw-col-span-2">
      <label
        for="message"
        class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
        >Priority</label
      >
      <feedback-priority-dropdown
        :selected="priority"
        @select="selectPriority"
      ></feedback-priority-dropdown>
    </div>
    <div class="sm:tw-col-span-2">
      <label
        for="message"
        class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
        >Detailed description (include how we can reproduce if reporting an
        issue)
      </label>
      <div class="tw-mt-1">
        <textarea
          v-model="description"
          id="description"
          name="description"
          rows="2"
          class="tw-py-3 tw-px-4 tw-block tw-w-full tw-shadow-sm focus:tw-ring-orange-500 focus:tw-border-orange-500 tw-border-gray-300 tw-rounded-md"
        ></textarea>
      </div>
    </div>
    <div class="sm:tw-col-span-2">
      <button
        type="submit"
        class="tw-w-full tw-inline-flex tw-items-center tw-justify-center tw-mb-1 tw-px-4 tw-py-2 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-base tw-font-medium tw-text-white tw-bg-orange-600 hover:tw-bg-orange-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-orange-500"
      >
        Submit
      </button>
      <button
        type="button"
        @click="$emit('close')"
        class="tw-w-full tw-inline-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-base tw-font-medium tw-text-gray-800 tw-bg-gray-100 hover:tw-bg-gray-200 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-orange-500"
      >
        Close
      </button>
    </div>
  </form>
</template>

<script>
import FeedbackTypeRadioGroup from "@/components/Help/FeedbackTypeRadioGroup.vue";
import FeedbackPriorityDropdown from "@/components/Help/FeedbackPriorityDropdown.vue";
import { deviceDetect } from "mobile-device-detect";

export default {
  components: {
    FeedbackTypeRadioGroup,
    FeedbackPriorityDropdown,
  },
  data() {
    return {
      feedback_type: "improvement",
      priority: 4,
      title: "",
      description: "",
      device_info: deviceDetect(),
      page_path: this.$route.fullPath,
      page_name: this.$route.name,
      errors: [],
      isError: false,
      isSubmitting: false,
    };
  },
  methods: {
    selectFeedbackType(value) {
      this.feedback_type = value;
    },
    selectPriority(value) {
      this.priority = value;
    },
    validateForm() {
      this.errors = [];

      // Check patrol strength
      if (this.title === "" || this.title === null) {
        this.errors.push("title");
      }

      if (this.errors.length === 0) {
        return this.onSubmit();
      }
    },
    async onSubmit() {
      if (this.isSubmitting === false) {
        this.isSubmitting = true;
        this.$http
          .post(process.env.VUE_APP_API_ROOT + "feedback/", this.$data)
          .then((response) => {
            this.isSubmitting = false;
            this.$emit("success");
            //   reset form
            this.title = "";
            this.description = "";
            this.priority = 4;
            this.feedback_type = "improvement";
          })
          .catch((error) => {
            // handle authentication and validation errors here
            //   this.errors = error.response.data.errors;
            this.isError = true;
            this.isSubmitting = false;
          });
      }
    },
  },
  mounted() {
    this.$refs["title"].focus();
  },
};
</script>

<style>
</style>