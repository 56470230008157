<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    v-cloak
    :class="{ 'tw-hidden': !visible }"
    class="tw-fixed tw-z-10 tw-inset-0 tw-overflow-y-auto"
    style="z-index: 1050 !important"
  >
    <div
      class="tw-flex tw-items-end tw-justify-center tw-min-h-screen tw-pt-4 tw-px-4 tw-pb-20 tw-text-center sm:tw-block sm:tw-p-0"
    >
      <!--
      Background overlay, show/hide based on modal state.

      Entering: "tw-ease-out tw-duration-300"
        From: "tw-opacity-0"
        To: "tw-opacity-100"
      Leaving: "tw-ease-in tw-duration-200"
        From: "tw-opacity-100"
        To: "tw-opacity-0"
    -->
      <transition
        enter-active-class="tw-ease-out tw-duration-300"
        enter-class="tw-opacity-0"
        enter-to-class="tw-opacity-100"
        leave-active-class="tw-ease-in tw-duration-200"
        leave-class="tw-opacity-100"
        leave-to-class="tw-opacity-0"
      >
        <div
          @click="hide()"
          class="tw-fixed tw-inset-0 tw-transition-opacity"
          aria-hidden="true"
        >
          <div
            class="tw-absolute tw-inset-0 tw-bg-gray-500 tw-opacity-75"
          ></div>
        </div>
      </transition>

      <!-- This element is to trick the browser into centering the modal tw-contents. -->
      <span
        class="tw-hidden sm:tw-inline-block sm:tw-align-middle sm:tw-h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <!--
      Modal panel, show/hide based on modal state.

      Entering: "tw-ease-out tw-duration-300"
        From: "tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
        To: "tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
      Leaving: "tw-ease-in tw-duration-200"
        From: "tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
        To: "tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
    -->
      <transition
        enter-active-class="tw-ease-out tw-duration-300"
        enter-class="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
        enter-to-class="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
        leave-active-class="tw-ease-in tw-duration-200"
        leave-class="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
        leave-to-class="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
      >
        <div
          class="tw-inline-block tw-align-bottom tw-bg-white tw-rounded-lg tw-px-4 tw-pt-5 tw-pb-4 tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all sm:tw-my-8 sm:tw-align-middle sm:tw-max-w-xl sm:tw-w-full sm:tw-p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <FeedbackSuccess v-if="success" @close="hide()" />
          <div v-else>
            <div
              class="tw-mx-auto tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-full tw-bg-blue-100"
            >
              <svg
                class="tw-h-6 tw-w-6 tw-text-blue-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
                ></path>
              </svg>
            </div>
            <div class="tw-mt-3 tw-text-center sm:tw-mt-5">
              <h3
                class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900"
                id="modal-headline"
              >
                Submit feedback or issues
              </h3>
              <div class="tw-mt-2">
                <p class="tw-text-sm tw-text-gray-500">
                  Your feedback is valuable in helping improve our products.
                </p>
              </div>
            </div>
            <support-form
              @close="hide()"
              @success="success = true"
              class="tw-mt-8"
            ></support-form>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import SupportForm from "@/components/Help/SupportForm.vue";
import SupportModal from "@/lib/supportModal.js";
import FeedbackSuccess from "@/components/Help/FeedbackSuccess.vue";

export default {
  components: {
    SupportForm,
    FeedbackSuccess,
  },
  data() {
    return {
      visible: false,
      success: false,
      title: "",
      text: "",
      // adding callback function variable
      onConfirm: {},
    };
  },
  methods: {
    hide() {
      this.visible = false;
      this.success = false;
    },
    confirm() {
      // we must check if this.onConfirm is function
      if (typeof this.onConfirm === "function") {
        // run passed function and then close the modal
        this.onConfirm();
        this.hide();
      } else {
        // we only close the modal
        this.hide();
      }
    },
    show(params) {
      // making modal visible
      this.visible = true;
      // setting title and text
      //   this.title = params.title;
      //   this.text = params.text;
      // setting callback function
      //   this.onConfirm = params.onConfirm;
    },
  },
  beforeMount() {
    // here we need to listen for emited events
    // we declared those events inside our plugin
    SupportModal.EventBus.$on("show", (params) => {
      this.show(params);
    });
  },
};
</script>

<style>
</style>