<template>
  <div class="animated fadeIn">
    <div class="tw-mt-8 tw-mb-4 tw-px-2">
      <div class="">
        <h3>Mark this incident as resolved</h3>
      </div>
    </div>
    <!-- <ResolveEventForm></ResolveEventForm> -->
  </div>
</template>

<script>
import ResolveEventForm from "@/components/Events/Forms/ResolveEventForm";

export default {
  name: "mark-resolved",
  data() {
      return {

      }
  },
  components: {
    ResolveEventForm
  },
  mounted() {
      this.$http
        .post(
          process.env.VUE_APP_API_ROOT +
            "incidents/" +
            this.$route.params.eventID +
            "/resolve/",
          this.$data
        )
        .then(response => {
          this.$emit("completed", response.data.data);
          this.$router.push({ name: "Incidents" });
        })
        .catch(error => {
          // handle authentication and validation errors here
          this.errors = error.response.data.errors;
        });
  }
};
</script>
