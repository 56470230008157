<template>
  <div class="tw-z-10 tw-relative tw-block tw-text-left ">
    <div class=" ">
      <button
        @click="isOpen = !isOpen"
        @blur="close()"
        type="button"
        class="tw-inline-flex tw-rounded-md tw-shadow-sm tw-px-4 tw-py-2 tw-bg-gray-100 tw-text-sm tw-font-medium tw-text-gray-700 hover:tw-bg-gray-200 focus:tw-outline-none focus:tw-bg-gray-200"
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
      >
        <div class="tw-flex-grow">
        <!-- <icon :icon="['fal', 'circle']" :class="`text-${currentStatus.color}-500 tw-mr-2 tw-h-5 tw-w-5`" /> -->
        <span :class="`tw-inline-block tw-h-3 tw-w-3 tw-mr-2 tw-rounded-full tw-align-middle tw-bg-${currentStatus.color}-500`"></span>
        {{ currentStatus.label }}
        </div>
        <svg class="tw-flex-shrink-0 tw--mr-1 tw-ml-2 tw-h-5 tw-w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </button>
    </div>

    <!--
    Dropdown panel, show/hide based on dropdown state.

    Entering: "tw-transition tw-ease-out tw-duration-100"
      From: "tw-transform tw-opacity-0 tw-scale-95"
      To: "tw-transform tw-opacity-100 tw-scale-100"
    Leaving: "tw-transition tw-ease-in tw-duration-75"
      From: "tw-transform tw-opacity-100 tw-scale-100"
      To: "tw-transform tw-opacity-0 tw-scale-95"
  -->
    <transition
      enter-active-class="tw-transition tw-ease-out tw-duration-75"
      enter-class="tw-transform tw-opacity-0 tw-scale-95"
      enter-to-class="tw-transform tw-opacity-100 tw-scale-100"
      leave-active-class="tw-transition tw-ease-in tw-duration-75"
      leave-class="tw-transform tw-opacity-100 tw-scale-100"
      leave-to-class="tw-transform tw-opacity-0 tw-scale-95"
    >
      <div
        v-show="isOpen"
        class="tw-origin-top-left tw-absolute tw-left-0 tw-mt-2 tw-w-56 tw-rounded-md tw-shadow-lg tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-divide-y tw-divide-gray-100"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        <div class="tw-py-1">
          <button
            @click="$emit('select', status.id)"
            type="button"
            class="tw-group tw-w-full tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
            role="menuitem"
            v-for="status in statuses"
            v-bind:key="status.id"
          >
          <!-- <icon :icon="['fas', 'circle']" :class="`mr-3 tw-h-5 tw-w-5 text-${status.color}-400 group-hover:text-${status.color}-500`"/> -->
          <span :class="`tw-inline-block tw-h-3 tw-w-3 tw-mr-2 tw-rounded-full tw-align-middle tw-bg-${status.color}-500 tw-group-hover:tw-bg-${status.color}-500`" :style="`background-color: ${status.bg} !important;`"></span>
            {{ status.label }}
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["selected"],
  data() {
    return {
      isOpen: false,
      statuses: [
          { id: 4, label: 'Normal', color: 'blue', bg: '#3B82F6'},
          { id: 3, label: 'Medium', color: 'green', bg: '#10B981'},
          { id: 2, label: 'High', color: 'yellow', bg: '#F59E0B'},
          { id: 1, label: 'Urgent', color: 'red', bg: '#EF4444'},
          
          
          
      ]
    };
  },
  computed: {
      currentStatus() {
          return this.statuses.find(result => result.id == this.selected)
      }
  },
  methods: {
    close() {
      setTimeout(() => {
        this.isOpen = false;
      }, 200);
    },
  },
};
</script>

<style>
</style>