<template>
  <v-select v-model="selected" label="label" index="code" value="code" :options="severities" placeholder="All severities" @input="emitToParent" style="max-width: 170px"></v-select>
</template>

<script>
export default {
  name: "severity-dropdown",
  data() {
    return {
      selected: [],
      severities: [
          {label: 'Low', code: 'low'},
          {label: 'Medium', code: 'medium'},
          {label: 'High', code: 'high'},
          {label: 'Extreme', code: 'extreme'},
      ],
    };
  },
  methods: {
    emitToParent(event) {
      if (this.selected !== null) {
        this.$emit("filterIncidents", this.selected.code);
      } else {
        this.$emit("filterIncidents", null);
      }
      // console.log(this.selected);
    }
  },
};
</script>

<style>
</style>