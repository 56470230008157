<template>
    <transition :duration="150">
        <div
            v-show="showSidebar"
            class="lg:tw-hidden"
        >
            <div class="tw-fixed tw-inset-0 tw-flex tw-z-40">
                <transition
                    enter-active-class="transition-opacity duration-150 ease-linear"
                    enter-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="transition-opacity duration-150 ease-linear"
                    leave-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <div
                        v-show="showSidebar"
                        @click="toggleSidebar()"
                        class="tw-fixed tw-inset-0"
                        aria-hidden="true"
                    >
                        <div class="tw-absolute tw-inset-0 tw-bg-gray-600 tw-opacity-75"></div>
                    </div>
                </transition>
                <transition
                    enter-active-class="transition duration-150 ease-in-out transform"
                    enter-class="-translate-x-full"
                    enter-to-class="translate-x-0"
                    leave-active-class="transition duration-150 ease-in-out transform"
                    leave-class="translate-x-0"
                    leave-to-class="-translate-x-full"
                >
                    <div
                        v-show="showSidebar"
                        class="tw-relative tw-flex-1 tw-flex tw-flex-col tw-max-w-xs tw-w-full tw-pt-8 tw-pb-4 tw-bg-black-500"
                    >
                        <div
                            @click="toggleSidebar()"
                            class="tw-absolute tw-top-0 tw-right-0 tw--mr-12 tw-pt-2"
                        >
                            <button
                                type="button"
                                class="tw-ml-1 tw-flex tw-items-center tw-justify-center tw-h-10 tw-w-10 tw-rounded-full focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-white"
                            >
                                <span class="tw-sr-only">Close sidebar</span>
                                <!-- Heroicon name: x -->
                                <svg
                                    class="tw-h-6 tw-w-6 tw-text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div class="tw-flex-shrink-0 tw-flex tw-flex-col tw-px-6">
                            <avatar
                                :username="full_name"
                                :size="60"
                            ></avatar>
                            <div class="tw-mt-4 tw-text-white tw-text-2xl tw-font-extralight">{{ full_name }}</div>
                            <!-- <router-link to="/" class="tw-text-4xl tw-font-extralight">
              <span class="tw-text-orange-500">risky.</span>
              <span class="tw-text-white">live</span>
            </router-link> -->
                        </div>
                        <div class="tw-mt-5 tw-flex-1 tw-h-0 tw-overflow-y-auto">
                            <nav class="tw-px-4">
                                <div class="tw-space-y-1">
                                    <!-- <router-link
                                        :to="{ name: 'Event info' }"
                                        class="tw-text-gray-300 hover:tw-bg-black-200 hover:tw-text-white tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-base tw-font-light tw-rounded-md"
                                    >
                                        <icon
                                            :icon="['fal', 'info-circle']"
                                            class="tw-mr-2 tw-h-6 tw-w-6 tw-text-gray-400 group-hover:tw-text-gray-300"
                                            fixed-width
                                        ></icon><span class="tw-text-gray-300 hover:tw-bg-black-200 hover:tw-text-white">Event info</span>
                                    </router-link>
                                    <router-link
                                        :to="{ name: 'Emergency info' }"
                                        class="tw-text-orange-500 hover:tw-bg-black-200 hover:tw-text-orange-500 tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-base tw-font-light tw-rounded-md"
                                    >
                                        <icon
                                            :icon="['fal', 'phone-volume']"
                                            class="tw-mr-2 tw-h-6 tw-w-6 tw-text-orange-500 group-hover:tw-text-orange-500"
                                            fixed-width
                                        ></icon><span class="tw-text-orange-500 hover:tw-bg-black-200 hover:tw-text-white">Emergency info</span>
                                    </router-link> -->
                                    <router-link
                                        :to="{ name: 'Add message' }"
                                        class="tw-text-gray-300 hover:tw-bg-black-200 hover:tw-text-white tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-base tw-font-light tw-rounded-md"
                                    >
                                        <icon
                                            :icon="['fal', 'comments']"
                                            class="tw-mr-2 tw-h-6 tw-w-6 tw-text-gray-400 group-hover:tw-text-gray-300"
                                            fixed-width
                                        ></icon><span class="tw-text-gray-300 hover:tw-bg-black-200 hover:tw-text-white">Send message</span>
                                    </router-link>
                                    <router-link
                                        :to="{ name: 'Submit feedback' }"
                                        class="tw-text-gray-300 hover:tw-bg-black-200 hover:tw-text-white tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-base tw-font-light tw-rounded-md"
                                    >
                                        <icon
                                            :icon="['fal', 'bullhorn']"
                                            class="tw-mr-2 tw-h-6 tw-w-6 tw-text-gray-400 group-hover:tw-text-gray-300"
                                            fixed-width
                                        ></icon><span class="tw-text-gray-300 hover:tw-bg-black-200 hover:tw-text-white">Have feedback?</span>
                                    </router-link>
                                    <router-link
                                        :to="{ name: 'Logout' }"
                                        class="tw-text-gray-300 hover:tw-bg-black-200 hover:tw-text-white tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-base tw-font-light tw-rounded-md"
                                    >
                                        <icon
                                            :icon="['fal', 'sign-out']"
                                            class="tw-mr-2 tw-h-6 tw-w-6 tw-text-gray-400 group-hover:tw-text-gray-300"
                                            fixed-width
                                        ></icon><span class="tw-text-gray-300 hover:tw-bg-black-200 hover:tw-text-white">Sign out</span>
                                    </router-link>
                                </div>
                                <div class="tw-mt-10">
                                    <p class="tw-px-2 tw-text-xs tw-font-medium tw-text-white tw-uppercase tw-tracking-wider">
                                        Switch teams
                                    </p>
                                    <sidebar-teams-list class="tw-mt-2 tw-space-y-1"></sidebar-teams-list>
                                </div>
                            </nav>
                        </div>
                    </div>
                </transition>
                <div
                    class="tw-flex-shrink-0 tw-w-14"
                    aria-hidden="true"
                >
                    <!-- Dummy element to force sidebar to shrink to fit close icon -->
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Avatar from "vue-avatar";
import SidebarTeamsList from '@/components/ui/Nav/Sidebar/SidebarTeamsList.vue';

export default {
    components: {
        Avatar,
        SidebarTeamsList,
    },
    data() {
        return {
            activeClasses: "tw-bg-black-300 tw-text-white",
            inactiveClasses:
                "tw-text-gray-300 hover:tw-bg-black-200 hover:tw-text-white",
            textActiveClasses: "tw-text-white hover:tw-text-white",
            textInactiveClasses: "tw-text-gray-300 hover:tw-text-white",
            iconActiveClasses: "tw-text-gray-300",
            iconInactiveClasses: "tw-text-gray-400 group-hover:tw-text-gray-300",
        };
    },
    computed: {
        showSidebar() {
            return this.$store.getters.showSidebar;
        },
        currentRoute() {
            return this.$route.path;
        },
        full_name() {
            return this.$store.getters['user/fullName'];
        },
    },
    methods: {
        toggleSidebar() {
            this.$store.commit("toggleSidebar");
        },
        feedbackForm() {
            this.$store.commit("toggleSidebar");
            //   window.Userback.open();
        },
    },
};
</script>