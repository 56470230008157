<template>
    <div class="tw-overflow-hidden tw-px-4 tw-py-12 sm:tw-p-12">
      <div class="tw-text-center tw-whitespace-normal ">
        <div
          class="tw-mx-auto tw-flex tw-items-center tw-justify-center tw-h-20 tw-w-20 tw-rounded-full tw-bg-orange-200 tw-mb-4"
        >
          <icon :icon="['fal', 'check-double']" size="2x" class="tw-text-orange-600" />
        </div>
        <h3
          class="tw-mt-2 tw-text-3xl tw-leading-8 tw-font-light tw-tracking-tight tw-text-orange-500 sm:tw-text-3xl sm:tw-leading-10"
        >
          No incidents reported!
        </h3>
        <p
          class="tw-mt-4 tw-max-w-2xl tw-text-xl tw-font-light tw-leading-7 tw-text-gray-500 lg:tw-mx-auto tw-mb-6"
        >
          To get started with risky.live, report things occur across your team such as incidents, accidents, occurrences or observations.
        </p>
      </div>
    </div>
</template>

<script>
export default {};
</script>

<style>
</style>