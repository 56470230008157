<template>
    <div class="tw-bg-white tw-overflow-hidden tw-shadow tw-rounded-lg">
        <div class="tw-px-4 tw-py-5 sm:tw-p-6">
            <dt class="tw-text-lg tw-font-light tw-text-gray-900">
                {{ label }}
            </dt>
            <dd class="tw-mt-1 tw-flex tw-justify-between tw-items-baseline md:tw-block lg:tw-flex">
                <clip-loader :loading="loading" color="#63c2de"></clip-loader>
                <div v-if="!loading" :class="`tw-flex tw-items-baseline tw-text-5xl tw-font-light tw-text-${color}-600`">
                    {{ value }}
                </div>

                <!-- <div class="tw-inline-flex tw-items-baseline tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-sm tw-font-medium tw-bg-green-100 tw-text-green-800 md:tw-mt-2 lg:tw-mt-0">
                    <svg
                        class="tw--ml-1 tw-mr-0.5 tw-flex-shrink-0 tw-self-center tw-h-5 tw-w-5 tw-text-green-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                            clip-rule="evenodd"
                        />
                    </svg>
                    <span class="tw-sr-only">
                        Increased by
                    </span>
                    12%
                </div> -->
            </dd>
        </div>

    </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
    props: ["value", "label", "color", "loading"],
    components: {
        ClipLoader
    }
}
</script>

<style>

</style>