export default {
    items: [
        {
            name: 'New event',
            to: { name: 'New incident' },
            icon: ['fal', 'plus'],
        },
        {
            name: 'All events',
            to: { name: 'Incidents' },
            icon: ['fal', 'search'],
            // badge: {
            //     variant: 'secondary',
            //     text: '3'
            // }
        },
        {
            name: 'Insights',
            to: { name: 'Dashboard' },
            icon: ['fal', 'analytics'],
        },
        {
            name: 'Activity',
            to: { name: 'Activity' },
            icon: ['fal', 'stream'],
        },
        // {
        //     name: 'Map',
        //     url: '/map',
        //     icon: 'fal fa-map-marker-alt'
        // },

        {
            name: 'Add message',
            to: { name: 'Add message' },
            icon: ['fal', 'comments'],
        },
        // {
        //     title: true,
        //     name: 'Settings',
        //     class: '',
        //     wrapper: {
        //         element: '',
        //         attributes: {}
        //     }
        // },
        // {
        //     name: 'Switch event',
        //     url: '/switch-team',
        //     icon: 'fal fa-users',
        //     // class: 'd-lg-none'
        // },
        {
            name: 'Team',
            url: '/team',
            to: { name: 'Team' },
            icon: ['fal', 'users'],
        },
        // {
        //     name: 'Settings',
        //     to: { name: 'Settings' },
        //     icon: ['fal', 'cog'],
        // },
        // {
        //     name: 'Help & support',
        //     to: { name: 'Help & support' },
        //     icon: ['fal', 'question-circle'],
        //     exact: true
        // },
    ]
}
