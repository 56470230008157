<template>
  <form @submit.prevent="onSubmit">
    <div class="tw-mb-3">
      <div class="tw-space-y-6 tw-px-2">
        <FormError v-for="error, i in errors" type="error" :text="error" :key="i"/>

        <div class="form-group">
          <textarea
            ref="message"
            class="form-control"
            name="message"
            rows="8"
            placeholder="Type your message here..."
            v-model="message"
            @keydown="errors = ''"
            v-focus
          ></textarea>
        </div>
        <div class="form-group">
            <span class="switch">
              <input type="checkbox" class="switch" id="notification" name="notification" v-model="notification" checked>
              <label for="notification"></label>
            </span>
            <label class="ml-4 align-top">Notify my team</label>
          </div>
        <div class="tw-flex tw-flex-col tw-justify-stretch tw-space-y-2 lg:tw-space-y-0 lg:tw-justify-start lg:tw-flex-row">
          <button
          type="submit"
          class="btn-orange lg:tw-mr-2"
          v-bind:class="{ 'disabled' : isLoading }"
        >Add message</button>
        <button class="btn-gray" @click="$router.go(-1)">Cancel</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import FormError from "./FormError.vue";

export default {
  name: "add-message",
  components: {
    FormError
  },
  data() {
    return {
      message: "",
      notification: true,
      event: "",
      errors: [],
      isLoading: false
    };
  },
  methods: {
    onSubmit() {
      if (this.isLoading) return;

      this.postMessage();
    },
    async postMessage() {
      this.isLoading = true;
      this.event = this.$route.params.eventID;

      axios
        .post(process.env.VUE_APP_API_ROOT + "activity/", this.$data)
        .then(response => {
          this.$emit("completed", response.data.data);
          
          this.$router.back()
        })
        .catch(error => {
          // handle authentication and validation errors here
          if (error.response) {
            this.errors = error.response.data.message;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
