<template>
  <Layout>
    <PageHeader slot="header" title="Activity">
      <template slot="page-buttons">
        <router-link :to="{ name: 'Add message' }" class="btn-gray">
          Add message
        </router-link>
      </template>
    </PageHeader>

    <ActivityTimeline class="mx-0"></ActivityTimeline>
  </Layout>
</template>

<script>
import axios from "axios";
import Layout from "../../components/Layout.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import ActivityTimeline from "@/components/Activity/ActivityTimeline";

export default {
  name: "activity",
  components: {
    Layout,
    PulseLoader,
    ActivityTimeline,
  },
  data() {
    return {
      isLoading: true,
      activity: [],
    };
  },
  async created() {
    // try {
    //   let uri = process.env.VUE_APP_API_ROOT + 'activity'
    //   const response = await axios.get(uri)
    //   this.activity = response.data.data
    //   this.isLoading = false
    // } catch (e) {
    //   // handle the authentication error here
    // }
  },
};
</script>
