<template>
  <tr @click="updateSeverity()">
    <th scope="row" width="1%" class="pl-3 pr-2 text-center">
      <icon :icon="['fal','exchange-alt']" rotation="90" />
    </th>
    <td class="pl-0">Update risk level</td>
  </tr>
</template>

<script>
export default {
  name: "UpdateSeverityActionButton",
  methods: {
    updateSeverity() {
      this.$emit("closeActionSheet");
      this.$router.push({
        name: "Change severity",
        params: { eventID: this.$route.params.eventID }
      });
    }
  }
};
</script>

<style>
</style>