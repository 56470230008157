<template>
  <div class="text-center mt-4 tw-overflow-y-auto">
    <FormError v-for="error, i in errors" type="error" :text="error" :key="i"/>

    <FileUpload @change="updateSelectedFile" :message="message" :fileTypes="['image/*', 'video/*', 'audio/*']" />

    <div class="tw-px-2 tw-flex tw-flex-col tw-items-stretch tw-space-y-2 md:tw-flex-row md:tw-space-y-0 md:tw-space-x-2">
      <button
        v-if="!file"
        type="button"
        class="btn-gray"
        @click="message = { action: 'choose' }"
      >
        <icon :icon="['fal','camera']" size="xl" />
        <span class="tw-ml-2">Choose a file</span>
      </button>

      <button
        v-if="file"
        type="button"
        class="btn-orange"
        @click="upload()"
        v-bind:class="{ 'disabled' : isLoading }"
      >
        <span :class="[ isLoading ? 'd-none' : '' ]">
          <icon :icon="['fal','upload']" size="xl" />
          <span class="tw-ml-2">Confirm Upload</span>
        </span>
        <pulse-loader :loading="isLoading" color="#f8f8f8"></pulse-loader>
      </button>

      <button
        v-if="file"
        type="button"
        class="btn-gray"
        @click="message = { action: 'change' }"
      >
        <icon :icon="['fal','camera']" size="xl" />
        <span class="tw-ml-2">Choose a different file</span>
      </button>

    </div>
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import FileUpload from "@/components/ui/Inputs/FileUpload.vue";

export default {
  name: "UploadImageForm",
  props: [],
  components: {
    PulseLoader,
    FileUpload
  },
  data() {
    return {
      isLoading: false,
      file: undefined,
      message: undefined,
      errors: []
    };
  },
  mounted() {
    this.file = undefined;
  },
  methods: {
    updateSelectedFile(file) {
      this.file = file;
    },
    upload() {
      if (this.isLoading) return;
      
      this.isLoading = true;
      var formData = new FormData();

      // If no file attached, halt.
      if (!this.file) {
        return;
      }

      var imageFile = this.file;
      this.$emit("update:image", this.file);
      formData.append("media", imageFile);

      this.$http
        .post(
          process.env.VUE_APP_API_ROOT +
            "incidents/" +
            this.$route.params.eventID +
            "/attachments/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        )
        .then(response => {
          this.$emit("uploading", false);
          this.$emit("update-attachments", response.data);
          this.$router.back();
        })
        .catch(error => {
          this.errors = error.response.data.message;
          this.$emit("uploading", "FALSE");
          this.file = undefined;
          this.message = { action: "clear" };
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style>
</style>