<template>
  
</template>

<script>
export default {
  created: function() {
    this.$store.dispatch("auth/logout").then(() => {
      this.$router.push("/login");
    });
  }
};
</script>