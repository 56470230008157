import Vue from "vue";
import Router from "vue-router";
import store from "@/state/store.js";

// Containers
import AppLayout from "@/components/App.vue";

import SwitchTeam from "@/views/Auth/SwitchTeam-mobile";

// Team & billing
const TeamContainer = () => import('@/views/Team/TeamContainer.vue');
import TeamInfo from "@/views/Team/TeamInfo";
import TeamEmergencyInfo from "@/views/Team/TeamEmergencyInfo";
// auth related routes
import authRoutes from './auth-routes.js';
// children routes
import incidentsSubroutes from './incidents-subroutes.js';
import settingsSubroutes from './settings-subroutes.js';
import teamSubroutes from './team-subroutes.js';
import activitySubroutes from './activity-subroutes';
// App pages
const Dashboard = () => import('@/views/Dashboard.vue');
const SettingsContainer = () => import('@/views/Settings/SettingsContainer.vue');
const SubmitFeedback = () => import('@/views/Settings/SubmitFeedback.vue');

// import Map from "@/views/Map";
// System pages
import RequestLocationPermission from "@/views/Errors/RequestLocationPermission";
import Error404 from "@/views/Errors/Page404.vue";
import ReportIncidentExternal from "@/views/ReportIncidentExternal.vue";
// navigation guards
import { requireAuthenticated, requireUnauthenticated } from './route-guards.js';

Vue.use(Router);

const router = new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    ...authRoutes,
    {
      path: "/",
      redirect: "/incidents",
      name: "Home",
      component: AppLayout,
      beforeEnter: requireAuthenticated,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: "insights",
          name: "Dashboard",
          component: Dashboard,
          meta: {
            title: "Insights"
          }
        },
        {
          path: "location",
          name: "Location request",
          component: RequestLocationPermission,
          meta: {
            title: "Location required",
            showBackButton: false,
            hideBottomBar: true
          }
        },
        {
          path: "incidents",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: incidentsSubroutes
        },
        // {
        //   path: "map",
        //   name: "Map",
        //   component: Map
        // },
        {
          path: "activity",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: activitySubroutes
        },
        {
          path: "/switch-team",
          name: "Switch team",
          component: SwitchTeam,
          meta: {
            title: "Switch team",
            showBackButton: true,
          }
        },
        {
          path: "/event/info",
          name: "Event info",
          component: TeamInfo,
          meta: {
            title: "Event info"
          }
        },
        {
          path: "/event/emergency",
          name: "Emergency info",
          component: TeamEmergencyInfo,
          meta: {
            title: "Emergency info"
          }
        },
        {
            path: "team",
            name: "Team",
            redirect: "team/home",
            component: TeamContainer,
            meta: {
              title: "Team"

            },
            children: teamSubroutes
        },
        {
            path: "settings",
            name: "Settings",
            redirect: "settings/profile",
            component: SettingsContainer,
            meta: {
              title: "Settings"

            },
            children: settingsSubroutes
        },
        {
            path: "feedback",
            name: "Submit feedback",
            component: SubmitFeedback,
            meta: {
              title: "Send feedback",
              showBackButton: true,
            }
        },
      ]
    },
    {
      path: "/report/:token",
      name: "Report incident",
      component: ReportIncidentExternal,
      beforeEnter: requireUnauthenticated,
      meta: {
        guestOnly: true
      },
    },
    {
        path: "*",
        redirect: "/",
        name: "Not found",
        component: Error404
    }
  ]
});


// router.afterEach((to, from) => {
//     // FIXME: LATER.
//   // tempory fix so sidebar always closes on route change
//   // if (document.body.classList.contains('sidebar-show')) {
//   document.body.classList.remove('sidebar-show')
//   // }
// })

router.afterEach((to, from) => {
  store.commit("closeSidebar");
  if (to.meta.title != null) {
    if (store.state.navbarTitle !== to.meta.title) {
      store.commit("setTitle", to.meta.title, { root: true });
    }
  }
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     store.dispatch("auth/srefresh", () => {
//         if (store.getters.isLoggedIn) {
//             next();
//             return;
//         } else {
//             next("/login");
//         }
//     });
//   } else {
//     next();
//   }
//   next();
// });

export default router;
