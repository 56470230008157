import axios from "axios";

const state = {
  users: []
};

const mutations = {
  setUsers(state, payload) {
    state.users = payload;
  }
};

const getters = {
};

const actions = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
