<template>
    <table class="table table-hover table-borderless">
      <tbody>
        <tr v-if="loading">
          <th colspan="9" class="text-center py-5">
            <clip-loader :loading="loading" color="#63c2de"></clip-loader>
          </th>
        </tr>
        <tr v-if="!loading && attachments.length == 0">
          <th colspan="9" class="text-center text-muted py-5">
            <icon :icon="['fal', 'images']" fixed-width size="4x" class="mb-3 text-muted"/>
            <h3>No attachments</h3>
          </th>
        </tr>
        <tr v-for="img in attachments" :key="img.id">
          <td width="1%">
            <div
                class="rounded img-thumbnail"
                :style="{ 'backgroundImage': `url(${img.media})` }"
                style="background-size: cover; background-repeat: no-repeat; height: 110px; width: 150px;"
              ></div>
          </td>
          <td>
            {{ img.time }}
          </td>
          <td class="align-middle">
            <a :href="img.media" target="_blank" class="float-right">Download <icon :icon="['fal', 'cloud-download']" fixed-width/></a>
          </td>
        </tr>
      </tbody>
    </table>
</template>

<script>
import axios from "axios";
import Avatar from 'vue-avatar'
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  name: "AttachementsTable",
  props: ["loading", "attachments"],
  components: {
    ClipLoader,
  },
};
</script>

<style>
</style>