<template>
  <div class="animated fadeIn">
    <div class="tw-mt-8 tw-mb-4 tw-px-2">
      <div class="">
        <h3>Edit description</h3>
      </div>
    </div>
    <AddEventDescriptionForm></AddEventDescriptionForm>
  </div>
</template>

<script>
import AddEventDescriptionForm from "@/components/Events/Forms/AddEventDescriptionForm";

export default {
  name: "description",
  components: {
    AddEventDescriptionForm
  }
};
</script>
