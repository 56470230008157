<template>
    <div class="tw-block tw-mt-5">
        <div class="tw-align-middle tw-inline-block tw-min-w-full">
            <table class="tw-min-w-full">
                <thead>
                    <tr>
                        <slot name="header"></slot>
                    </tr>
                </thead>
                <tbody class="tw-bg-white">
                    <slot name="rows"></slot>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {

}
</script>


<style>
table th:first-child {
    @apply tw-rounded-l-md;
}

table th:last-child {
    @apply tw-rounded-r-md;
}
</style>
