<template>
  <nav
    v-if="!$route.meta.hideBottomBar"
    id="bottom-navigation"
    class="lg:tw-hidden tw-block tw-fixed tw-inset-x-0 tw-bottom-0 tw-z-10 tw-bg-black"
  >
    <div id="tabs" class="tw-flex tw-justify-between">
      <BottomNavButton
        :to="{ name: 'Incidents' }"
        label="Events"
        :icon="['fal', 'search']"
      />
      <BottomNavButton
        :to="{ name: 'Dashboard' }"
        label="Insights"
        :icon="['fal', 'analytics']"
      />
      <router-link
        :to="{ name: 'New incident' }"
        class="tw-w-full focus:tw-text-orange-500 hover:tw-text-orange-500 tw-justify-center tw-inline-block tw-text-center tw-pt-2 tw-pb-1"
      >
        <icon
          :icon="['fal', 'plus-circle']"
          fixed-width
          class="tw-inline-block tw-text-orange-500"
          style="font-size: 2.5rem"
          :class="[$route.name == 'New incident' ? active : inactive]"
        />
      </router-link>
      <BottomNavButton
        :to="{ name: 'Activity' }"
        label="Activity"
        :icon="['fal', 'stream']"
      />
      <BottomNavButton
          :to="{ name: 'Settings home' }"
          label="Account"
          :icon="['fal', 'user-circle']"
      />
    </div>
  </nav>
</template>

<script>
import BottomNavButton from "@/components/ui/Nav/BottomNavButton.vue";
import Avatar from "vue-avatar";

export default {
  components: {
    BottomNavButton,
    Avatar,
  },
  data() {
    return {
      active: "tw-text-orange-500",
      inactive: "tw-text-gray-500",
    };
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
    full_name() {
      return this.$store.getters['user/fullName'];
    },
    profile_label() {
      if (this.$store.getters['user/currentTeam']) {
        return this.$store.getters['user/currentTeam'].name;
      } else {
        return this.$store.getters['user/fullName'];
      }
    },
  },
};
</script>