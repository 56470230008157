import axios from "axios";
import VueCookies from "vue-cookies";

const state = {
  token: null
};

const mutations = {
  setToken (state, token) {
    state.token = token
  },
  removeToken (state) {
    state.token = null
  }
};

const getters = {
  isLoggedIn: state => {
    return state.token !== null;
  },
};

const actions = {
  login({ dispatch }, credentials) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_ROOT + "auth/token/legacy/",
        data: credentials,
        withCredentials: true,
        method: "POST"
      })
        .then(res => {
          const refresh = res.data.refresh;
          const access = res.data.access;
          createSession(refresh, access);
          resolve(res);
        })
        .catch(err => {
          removeSession();
          dispatch("clearData", null, { root: true });
          reject(err);
        });
    });
  },
  refresh({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      const refresh = VueCookies.get(`api_token`);

      if ( refresh ) {
        axios({
          url: process.env.VUE_APP_API_ROOT + "auth/token/refresh/",
          data: { refresh: refresh },
          withCredentials: true,
          method: "POST"
        })
          .then(res => {
            const access = res.data.access;

            createSession(refresh, access);
            commit('setToken', access);
            resolve(res);
          })
          .catch(err => {
            removeSession();
            dispatch("clearData", null, { root: true });
            reject(err);
          });
      } else {
        reject();
      }
    });
  },
  logout({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      removeSession();
      commit("removeToken");
      dispatch("clearData", null, { root: true });
      resolve();
    });
  }
};

// ===
// Private helpers
// ===
function removeSession() {
  // remove any cookies we have stored
  VueCookies.remove(`api_token`);
  // remove default header
  delete axios.defaults.headers.common["Authorization"];
}

function createSession(refresh, access) {
  // Set cookies in case a different API domain
  VueCookies.set(`api_token`, refresh);
  // Set token for future axios requests
  axios.defaults.headers.common["Authorization"] = "Bearer " + access;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
