<template>
  <form @submit.prevent="onSubmit">
    <div class="tw-mb-3">
      <div class="tw-space-y-6 tw-px-2">
        <button
          type="button"
          class="btn btn-danger btn-block pt-3 pb-2 mb-3"
          @click="updateSeverity('extreme')"
          v-bind:class="{ 'disabled' : isLoading }"
        >
          <h4>Extreme</h4>
        </button>
        <button
          type="button"
          href
          class="btn btn-caution btn-block pt-3 pb-2 mb-3"
          @click="updateSeverity('high')"
          v-bind:class="{ 'disabled' : isLoading }"
        >
          <h4>High</h4>
        </button>
        <button
          type="button"
          href
          class="btn btn-warning btn-block pt-3 pb-2 mb-3"
          @click="updateSeverity('medium')"
          v-bind:class="{ 'disabled' : isLoading }"
        >
          <h4>Medium</h4>
        </button>
        <button
          type="button"
          href
          class="btn btn-success btn-block pt-3 pb-2 mb-3"
          @click="updateSeverity('low')"
          v-bind:class="{ 'disabled' : isLoading }"
        >
          <h4>Low</h4>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";

export default {
  name: "change-severity",
  data() {
    return {
      isLoading: false,
      severity: "",
      
    };
  },
  methods: {
    async updateSeverity(severity) {
      if (this.isLoading) return;

      this.isLoading = true;
      this.severity = severity;

      axios
        .patch(
          process.env.VUE_APP_API_ROOT +
            "incidents/" +
            this.$route.params.eventID +
            "/",
          this.$data
        )
        .then(response => {
          this.$emit("completed", response.data.data);
          this.$router.back();
        })
        .catch(error => {
          this.errors = error.response.data.message;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
