<template lang="html">
  <div class="tw-w-40">
    <label class="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
      Risk Level
    </label>
    <div class="tw-relative tw-mt-1">
      <button type="button"
              class="tw-relative tw-flex tw-items-center tw-w-full tw-py-2 tw-pl-3 tw-pr-10 tw-text-left tw-bg-white tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-cursor-default focus:tw-outline-none focus:tw-ring-1 focus:tw-border-orange-500 focus:tw-ring-orange-500 sm:tw-text-sm" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label"
              @click="toggleOptions"
              @blur="hideOptions">
        <span class="tw-flex tw-items-center tw-pr-4" :class="selectColor(input_value)">
          <icon
            :icon="['fal', selectIcon(input_value)]"
            fixed-width
            class="tw-h-5 tw-w-5"
            :class="selectColor(input_value)"
            style="font-size: 1.15rem"
          />
        </span>
        <span class="tw-block tw-font-normal tw-truncate">
          {{toTitleCase(input_value) === "" ? "All" : toTitleCase(input_value)}}
        </span>
        <svg class="tw-absolute tw-right-0 tw-w-5 tw-h-5 tw-text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </button>

      <ul class="tw-absolute tw-z-10 tw-w-full tw-py-1 tw-mt-1 tw-overflow-auto tw-text-base tw-bg-white tw-rounded-md tw-shadow-lg tw-max-h-60 tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none sm:tw-text-sm" tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3"
      :class="{'tw-hidden': !active}">
        <li v-for="option in options"
            class="tw-relative tw-flex tw-py-2 tw-pl-3 tw-text-gray-900 tw-cursor-pointer tw-select-none tw-pr-9"
            @mousedown="setValue(option)"
            :class="input_value === option ? active_option_style : inActive_option_style">
          <span class="tw-flex tw-items-center tw-pr-4 tw-text-white" :class="selectColor(option)">
             <icon
              :icon="['fal', selectIcon(option)]"
              fixed-width
              class="tw-h-5 tw-w-5"
              :class="selectColor(option)"
              style="font-size: 1.15rem"
            />
          </span>
          <span class="tw-block tw-font-normal tw-truncate"
                :class="input_value === option ? 'tw-text-gray-700' : 'tw-text-gray-700'">
            {{toTitleCase(option) === "" ? "All" : toTitleCase(option)}}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
    name: "SelectSeverity",
    props: {
        value: {
            type: String,
            default: "",
        },
        isFilter: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            input_value: this.value,
            options: this.isFilter
                ? ["", "low", "medium", "high", "extreme"]
                : ["low", "medium", "high", "extreme"],
            active: false,
            active_option_style: "tw-bg-gray-100 tw-text-gray-700",
            inActive_option_style:
                "tw-bg-white tw-text-black hover:tw-bg-gray-100",
        };
    },
    computed: {},
    methods: {
        selectColor(value) {
            switch (value) {
                case "low":
                    return "tw-text-green-500";
                case "medium":
                    return "tw-text-yellow-500";
                case "high":
                    return "tw-text-orange-500";
                case "extreme":
                    return "tw-text-red-500";
                default:
                    return "tw-text-gray-400";
            }
        },
        selectIcon(value) {
            switch (value) {
                case "low":
                    return "signal-2";
                case "medium":
                    return "signal-2";
                case "high":
                    return "signal-3";
                case "extreme":
                    return "signal-4";
                default:
                    return "signal-4";
            }
        },
        updateParent() {
            this.$emit("select", "severity", this.input_value);
        },
        toggleOptions() {
            this.active = !this.active;
        },
        hideOptions() {
            this.active = false;
        },
        setValue(value) {
            this.input_value = value;
            this.updateParent();
            this.hideOptions();
        },
        toTitleCase(str) {
            return (
                str &&
                str.replace(/\w\S*/g, function (txt) {
                    return (
                        txt.charAt(0).toUpperCase() +
                        txt.substr(1).toLowerCase()
                    );
                })
            );
        },
    },
};
</script>
