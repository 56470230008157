<template>
    <div id="severity" class="tw-inline">
        <span
            v-if="severity == 'low'"
            class="tw-group tw-text-sm tw-font-medium tw-text-green-500"
        >
            <icon
                :icon="['fal','signal-2']"
                fixed-width
                class="tw-h-5 tw-w-5 tw-text-green-500"
                style="font-size: 1.25rem"
            />
            <span v-if="label" class="tw-align-middle">Low</span>
        </span>

        <span
            v-if="severity == 'medium'"
            class="tw-group tw-text-sm tw-font-medium tw-text-yellow-500"
        >
            <icon
                :icon="['fal','signal-2']"
                fixed-width
                class="tw-h-5 tw-w-5 tw-font-medium tw-text-yellow-500"
                style="font-size: 1.25rem"
            />
            <span v-if="label" class="tw-align-middle">Medium</span>
        </span>

        <span
            v-if="severity == 'high'"
            class="tw-group tw-text-sm tw-font-medium tw-text-orange-500"
        >
            <icon
                v-if="severity == 'high'"
                :icon="['fal','signal-3']"
                fixed-width
                class="tw-h-5 tw-w-5 tw-font-medium tw-text-orange-500"
                style="font-size: 1.25rem"
            />
            <span v-if="label" class="tw-align-middle">High</span>
        </span>

        <span
            v-if="severity == 'extreme'"
            class="tw-group tw-text-sm tw-font-medium tw-text-red-500"
        >
            <icon
                :icon="['fal','signal-4']"
                fixed-width
                class="tw-h-5 tw-w-5 tw-text-red-500"
                style="font-size: 1.25rem"
            />
            <span v-if="label" class="tw-align-middle">Extreme</span>
        </span>
    </div>
</template>

<script>
export default {
    name: "EventSeverityTag",
    props: ["severity", "label"],
    computed: {}
};
</script>
