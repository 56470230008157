<template>
    <nav class="tw-flex tw-items-center tw-justify-between tw-py-3" aria-label="Pagination">
        <div class="tw-hidden sm:tw-block">
            <p class="tw-text-sm tw-font-normal tw-text-gray-400">
                Showing
                {{ " " }}
                <span class="">{{ first }}</span>
                <span class="">-</span>
                <span class="">{{ last }}</span>
                {{ " " }}
                <span class="">of</span>
                {{ " " }}
                <span class="">{{ total_count }}</span>
                {{ " " }}
                results
            </p>
        </div>
        <div class="tw-flex tw-justify-between tw-flex-1 sm:tw-justify-end">
            <button
                @click="prevPage"
                class="tw-relative tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-rounded-md sm:tw-px-2"
                :class="[is_first ? inactiveClasses : activeClasses]"
            >

                <icon :icon="['fal', 'angle-left']" class="tw-hidden sm:tw-inline-block" style="font-size: 1.2rem" fixed-width/>
                <span class="tw-inline-block sm:tw-hidden">Previous</span>
            </button>
            <button
                @click="nextPage"
                class="tw-relative tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-ml-3 tw-text-sm tw-font-medium tw-rounded-md sm:tw-px-2"
                :class="[is_last ? inactiveClasses : activeClasses]"
            >
                <icon :icon="['fal', 'angle-right']" class="tw-hidden sm:tw-inline-block" style="font-size: 1.2rem" fixed-width/>
                <span class="tw-inline-block sm:tw-hidden">Next</span>
            </button>
        </div>
    </nav>
</template>

<script>
export default {
    name: "TablePagination",
    props: {
        total_count: {
            type: Number,
            default: 0,
        },
        showing_count: {
            type: Number,
            default: 0,
        },
        offset: {
          type: Number,
          default: 0
        },
        is_first: {
            type: Boolean,
            default: false,
        },
        is_last: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        activeClasses() {
            return "tw-text-gray-700 tw-bg-gray-100 hover:tw-bg-gray-200";
        },
        inactiveClasses() {
            return "tw-cursor-default tw-text-gray-400 tw-bg-gray-50 hover:tw-bg-gray-50";
        },
        first() {
          return this.offset + 1;
        },
        last() {
          return this.offset + this.showing_count;
        }
    },
    methods: {
        nextPage() {
            if (this.is_last) return;
            this.$emit("pageup");
        },
        prevPage() {
            if (this.is_first) return;
            this.$emit("pagedown");
        },
    }
};
</script>
