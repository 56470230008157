<template>
  <div class="shadow-sm p-4 border-0 mb-3">
    <h5 class="card-title text-dark">
      Weather
      <img :src="'https://openweathermap.org/img/wn/' + weather.weather_icon + '@2x.png'" class="float-right align-middle " width="35px" height="35px" style="object-fit: cover;">
    </h5>
    <h4 class="mb-0 text-primary"><strong>{{ weather.temp }}° C</strong></h4>
    <p class="mb-2 text-dark"><small>Wind {{ weather.wind }} km/h | Humidity {{ weather.humidity }}%</small></p>
    <p class="mb-0 text-dark">
        <Emoji :data="index" emoji=":high_brightness:" :size="20" set="twitter" class="align-middle"></Emoji> {{ weather.sunrise }} <Emoji :data="index" emoji=":crescent_moon:" :size="20" set="twitter" class="ml-3 align-middle"></Emoji> {{ weather.sunset }}</p>
  </div>
</template>


<script>
import data from 'emoji-mart-vue-fast/data/all.json'
import { Emoji, EmojiIndex } from 'emoji-mart-vue-fast'
import 'emoji-mart-vue-fast/css/emoji-mart.css'

export default {
  name: "WeatherAttachmentCard",
  props: ["weather"],
  components: {
    Emoji
  },
  data() {
      return {
          index: new EmojiIndex(data),
      }
  }
};
</script>
