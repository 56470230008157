<template>
  <!-- Static sidebar for desktop -->
  <div class="tw-hidden lg:tw-flex lg:tw-flex-shrink-0">
    <div class="tw-flex tw-flex-col tw-w-64">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="tw-flex tw-flex-col tw-h-0 tw-flex-1 tw-bg-black-800">
        <div
          class="tw-flex-1 tw-flex tw-flex-col tw-pt-5 tw-pb-4 tw-overflow-y-auto"
        >
          <div class="tw-flex tw-items-center tw-flex-shrink-0 tw-px-7">
            <!-- <img class="tw-h-8 tw-w-auto" src="@assets/images" alt="Workflow"> -->
            <router-link to="/" class="tw-text-4xl tw-font-extralight focus:tw-outline-none">
                <span class="tw-text-orange-500 ">risky.</span>
                <span class="tw-text-white">live</span>
            </router-link>
          </div>
          <nav
            class="tw-flex tw-flex-col tw-mt-5 tw-px-2 tw-bg-black-800 tw-h-full"
          >
            <div class="tw-flex-1 tw-flex-grow tw-space-y-1">
              <router-link
                v-for="item in navItems"
                v-bind:key="item.name"
                :to="item.to"
                v-bind:class="[
                  currentRoute.includes(item.url)
                    ? activeClasses
                    : inactiveClasses,
                ]"
                class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-sm tw-rounded-md"
              >
                <icon
                  :icon="item.icon"
                  class="tw-mx-3 tw-h-6 tw-w-6"
                  fixed-width
                  v-bind:class="[
                    currentRoute.includes(item.url)
                      ? iconActiveClasses
                      : iconInactiveClasses,
                  ]"
                ></icon
                ><span
                  v-bind:class="[
                    currentRoute.includes(item.url)
                      ? textActiveClasses
                      : textInactiveClasses,
                  ]"
                  >{{ item.name }}</span
                >
              </router-link>
            </div>
            <div class="tw-flex-shrink-0 tw-space-y-1">
                
              <button
                @click="$modal.show()"
                class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-sm tw-rounded-md tw-w-full tw-text-gray-300 hover:tw-bg-black-200 hover:tw-text-white focus:tw-outline-none"
              >
                <icon
                  :icon="['fal', 'bullhorn']"
                  class="tw-mx-3 tw-h-6 tw-w-6"
                  fixed-width
                ></icon
                ><span
                  class=""
                  >Have feedback?</span
                >
              </button>
              <router-link
                :to="{ name: 'Help & support' }"
                v-bind:class="[
                  currentRoute.includes('support')
                    ? activeClasses
                    : inactiveClasses,
                ]"
                class="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-sm tw-rounded-md"
              >
                <icon
                  :icon="['fal', 'question-circle']"
                  class="tw-mx-3 tw-h-6 tw-w-6"
                  fixed-width
                  v-bind:class="[
                    currentRoute.includes('support')
                      ? iconActiveClasses
                      : iconInactiveClasses,
                  ]"
                ></icon
                ><span
                  v-bind:class="[
                    currentRoute.includes('support')
                      ? textActiveClasses
                      : textInactiveClasses,
                  ]"
                  >Help & support</span
                >
              </router-link>
            </div>
          </nav>
        </div>
        <div class="tw-px-3 tw-mb-4 tw-relative tw-inline-block tw-text-left">
        <ProfileDropdown>
            <ProfileDropdownContent>
                <div class="tw-py-1">
                    <span
                    class="tw-block tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-text-gray-500"
                    role="menuitem"
                    >Switch team</span>
                    <div class="tw-pb-1">
                    <ProfileDropdownTeamsList/>
                    </div>
                    <!-- <ProfileDropdownItem :to="{name: 'Profile'}" class="tw-pt-2">
                        <icon :icon="['fal', 'plus']" class="tw-mr-1.5"/>Add team
                    </ProfileDropdownItem> -->
                </div>
                
                <div class="tw-border-t tw-border-gray-100"></div>
                <div class="tw-py-1">
                    <ProfileDropdownItem :to="{name: 'Profile'}">Account & Password</ProfileDropdownItem>
                    <ProfileDropdownItem :to="{name: 'Logout'}">Sign out</ProfileDropdownItem>
                </div>
            </ProfileDropdownContent>
        </ProfileDropdown>
        </div>
        <!-- <div class="tw-flex-shrink-0 tw-flex tw-bg-black-400 tw-p-4">
          <profile-component></profile-component>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import ProfileComponent from "@/components/Auth/ProfileComponent.vue";
import ProfileDropdown from '@/components/ui/Nav/Sidebar/ProfileDropdown.vue';
import ProfileDropdownContent from '@/components/ui/Nav/Sidebar/ProfileDropdownContent.vue';
import ProfileDropdownItem from '@/components/ui/Nav/Sidebar/ProfileDropdownItem.vue';
import ProfileDropdownTeamsList from "@/components/ui/Nav/Sidebar/ProfileDropdownTeamsList.vue";

export default {
  props: ["navItems"],
  components: {
    ProfileComponent,
    ProfileDropdown,
    ProfileDropdownContent,
    ProfileDropdownItem,
    ProfileDropdownTeamsList
  },
  data() {
    return {
      activeClasses: "tw-bg-black-300 tw-text-white",
      inactiveClasses:
        "tw-text-gray-300 hover:tw-bg-black-200 hover:tw-text-white",
      textActiveClasses: "tw-text-white hover:tw-text-white",
      textInactiveClasses: "tw-text-gray-300 hover:tw-text-white",
      iconActiveClasses: "tw-text-gray-300",
      iconInactiveClasses: "tw-text-gray-400 group-hover:tw-text-gray-300",
    };
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
  },
};
</script>

<style>
</style>