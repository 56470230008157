<template>
  <div class="animated fadeIn">
    <view-event-desktop v-if="!isMobileOrTablet()"></view-event-desktop>
    <view-event-mobile v-else></view-event-mobile>
  </div>
</template>

<script>
import ViewEventDesktop from '@/views/Events/ViewEvent-desktop'
import ViewEventMobile from '@/views/Events/ViewEvent-mobile'

export default {
  name: 'view-event',
  components: {
    ViewEventDesktop,
    ViewEventMobile,
  }
}
</script>
