<template>
  <div className="tw-mx-auto tw-min-h-screen tw-bg-white tw-flex">
    <div className="tw-mx-auto tw-max-w-7xl tw-py-12 tw-px-4 sm:tw-py-16 sm:tw-px-6 lg:tw-px-8">
      <div className="tw-mx-auto tw-max-w-3xl tw-divide-y-2 tw-divide-gray-200">
        <ReportIncidentEmbed :token="token"/>
      </div>
    </div>
  </div>
</template>

<script>
import ReportIncidentEmbed from "../components/Events/Create/ReportIncident-embed.vue";

export default {
  name: "ReportIncident",
  components: {
    ReportIncidentEmbed
},
  data() {
    return {
      showBanner: true,
      token: this.$route.params.token,
    };
  }
};
</script>