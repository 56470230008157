<template>
    <div class="text-center">
        <h1 style="font-weight: 100; font-size: 4rem; text-align: center; padding-top: 10%">risky.live</h1>
        <h1 style="font-weight: 100; font-size: 1.8rem; text-align: center;">Something went wrong</h1>
        <router-link :to="{ name: 'Home' }" class="btn-link mt-3">Go back</router-link>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                
            }
        },
    }
</script>

<style scoped>
    body {
        background-color: #000000 !important;
    }
</style>
