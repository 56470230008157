<template>
  <div class="tw-relative tw-z-10 tw-inline-block tw-text-left">
    <div>
      <button
        @click="is_open = !is_open"
        @blur="close"
        type="button"
        class="tw-inline-flex tw-justify-center tw-w-full tw-px-4 tw-py-2.5 tw-text-sm tw-font-medium tw-text-gray-700 tw-bg-gray-100 tw-rounded-md hover:tw-bg-gray-200 focus:tw-outline-none focus:tw-bg-gray-200"
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
      >
        Export
        <icon
          :icon="['fal', 'arrow-down']"
          class="tw-w-5 tw-h-5 tw-ml-2 tw-text-gray-700"
        />
      </button>
    </div>
    <transition
      enter-active-class="tw-transition tw-duration-75 tw-ease-out"
      enter-class="tw-transform tw-scale-95 tw-opacity-0"
      enter-to-class="tw-transform tw-scale-100 tw-opacity-100"
      leave-active-class="tw-transition tw-duration-75 tw-ease-in"
      leave-class="tw-transform tw-scale-100 tw-opacity-100"
      leave-to-class="tw-transform tw-scale-95 tw-opacity-0"
    >
      <div
        v-show="is_open"
        class="tw-absolute tw-w-56 tw-mt-2 tw-origin-top-right tw-bg-white tw-divide-y tw-divide-gray-100 tw-rounded-md tw-shadow-lg xl:tw-right-0 tw-ring-1 tw-ring-black tw-ring-opacity-5"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        <div class="py-1">
          <a
            @click.prevent="$emit('downloadCSV')"
            class="tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 tw-cursor-pointer tw-group hover:tw-bg-gray-100 hover:tw-text-gray-900"
            role="menuitem"
          >
            <icon
              :icon="['fal', 'file-excel']"
              :class="`tw-mr-3 tw-h-5 tw-w-5 group-hover:tw-text-gray-500`"
            />
            Export as CSV
          </a>
          <!-- <a
            @click.prevent="$emit('downloadPDF')"
            class="tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 tw-cursor-pointer tw-group hover:tw-bg-gray-100 hover:tw-text-gray-900"
            role="menuitem"
            download
          >
            <icon
              :icon="['fal', 'file-pdf']"
              :class="`tw-mr-3 tw-h-5 tw-w-5 group-hover:tw-text-gray-500`"
            />
            Export as PDF
          </a> -->
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_open: false,
      incident_id: this.$route.params.event_pk
    };
  },
  methods: {
    close() {
      setTimeout(() => {
        this.is_open = false;
      }, 200);
    },
  },
};
</script>

<style>
</style>
