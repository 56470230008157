import Events from "@/views/Events/Events";
const CreateEvent = () => import('@/views/Events/Create.vue');
import ViewEvent from "@/views/Events/ViewEvent";
import ChangeSeverity from "@/views/Events/Actions/ChangeSeverity-mobile";
import AddMedia from "@/views/Events/Actions/AddMedia-mobile";
import MarkResolved from "@/views/Events/Actions/MarkResolved-mobile";
import Rename from "@/views/Events/Actions/Rename-mobile";
import Description from "@/views/Events/Actions/AddDescription-mobile";
import AddComment from "@/views/Events/Actions/AddComment-mobile";
import AddImage from "@/views/Events/Actions/AddImage-mobile";

const routes = [
  {
    path: "",
    name: "Incidents",
    component: Events,
    meta: {
      title: "Events"
    }
  },
  {
    path: "new",
    name: "New incident",
    component: CreateEvent,
    meta: {
      title: "New events",
      showBackButton: true,
      hideBottomBar: true
    }
  },
  {
    path: "create",
    redirect: "/incidents/new"
  },
  {
    path: ":eventID",
    name: "Incident",
    component: ViewEvent,
    meta: {
      title: "Event",
      showBackButton: true,
      hideBottomBar: true
    }
  },
  {
    path: ":eventID/severity",
    name: "Change severity",
    component: ChangeSeverity,
    meta: {
      title: "Update risk level",
      showBackButton: true,
      hideBottomBar: true
    }
  },
  {
    path: ":eventID/media",
    name: "Add media",
    component: AddMedia,
    meta: {
      title: "Add media",
      showBackButton: true,
      hideBottomBar: true
    }
  },
  {
    path: ":eventID/resolve",
    name: "Mark resolved",
    component: MarkResolved,
    meta: {
      title: "Resolve incident",
      showBackButton: true,
      hideBottomBar: true
    }
  },
  {
    path: ":eventID/comment",
    name: "Add comment",
    component: AddComment,
    meta: {
      title: "Add message",
      showBackButton: true,
      hideBottomBar: true
    }
  },
  {
    path: ":eventID/upload",
    name: "Upload image",
    component: AddImage,
    meta: {
      title: "Upload image",
      showBackButton: true,
      hideBottomBar: true
    }
  },
  {
    path: ":eventID/rename",
    name: "Rename",
    component: Rename,
    meta: {
      title: "Set title",
      showBackButton: true,
      hideBottomBar: true
    }
  },
  {
    path: ":eventID/description",
    name: "Description",
    component: Description,
    meta: {
      title: "Edit summary",
      showBackButton: true,
      hideBottomBar: true
    }
  }
]

export default routes;
