<template>
  <div class="animated fadeIn">
    <div class="tw-mt-8 tw-mb-4 tw-px-2">
      <div class="">
        <h3>Add photo/video</h3>
      </div>
    </div>
    <AddMedia></AddMedia>
    <nav class="navbar bg-white fixed-bottom border-top border-secondary">
      <button type="button" class="btn btn-light btn-lg btn-block" @click="$router.go(-1)">Cancel</button>
    </nav>
  </div>
</template>

<script>
import AddMedia from "@/components/Events/AddMedia";

export default {
  name: "add-media",
  components: {
    AddMedia
  }
};
</script>
