<template>
  <div id="mapcontainer">
    <div id="map" :style="`height: ${mapHeight}`"></div>
    <!-- point 1 -->
    <template v-if="!!this.google && !!this.map">
      <slot :google="google" :map="map"/>
    </template>
  </div>
</template>

<script>
import GoogleMapsApiLoader from "google-maps-api-loader";
import MapProvider from "@/components/Map/MapProvider";

export default {
  props: {
    mapConfig: Object,
    lat: null,
    lng: null,
    // mapWidth: String,
    mapHeight: String
  },
  components: {
    MapProvider
  },
  data() {
    return {
      google: null,
      map: null
    };
  },
  computed: {
    mapConfigWithCentre() {
          return{
            ...this.$props.mapConfig,
            center: new google.maps.LatLng(this.$props.lat, this.$props.lng)
        }
      }
  },
  mounted() {
    // point 3
    
    GoogleMapsApiLoader({
      apiKey: process.env.VUE_APP_GOOGLE_API_KEY
    }).then(google => {
      this.google = google;
      this.initializeMap();
    });
  },
  methods: {
    initializeMap() {
      const mapContainer = this.$el.querySelector("#map"); // point 1
      const { Map } = this.google.maps;
      this.map = new Map(mapContainer, this.mapConfigWithCentre);
    }
  }
};
</script>

<style scoped>
#map {
  width: 100%;
}
</style>
