<template>
  <team-emerg-info-card class="tw-mt-4"></team-emerg-info-card>
</template>

<script>
import TeamEmergInfoCard from '@/components/Team/TeamEmergInfoCard.vue';

export default {
  components: { TeamEmergInfoCard },
};
</script>

<style>

</style>