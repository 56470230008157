<template>
  <tr @click="esoCalled()">
    <th scope="row" width="1%" class="pl-3 pr-2 text-center">
      <icon :icon="['fal','phone-volume']" />
    </th>
    <td class="pl-0">Emergency Services called</td>
  </tr>
</template>

<script>
export default {
  name: "ESOCalledActionButton",
  methods: {
    async esoCalled() {
      this.$emit("closeActionSheet");
      this.$http
        .post(
          process.env.VUE_APP_API_ROOT +
            "incidents/" +
            this.$route.params.eventID +
            "/emergency_services_called/",
          this.$data
        )
        .then(response => {
          this.$emit("updateActivity");
        })
        .catch(error => {
          // handle authentication and validation errors here
          this.errors = error.response.data.errors;
        });
    }
  }
};
</script>

<style>
</style>