<template>
  <div>
    <div class="tw-flex tw-space-x-3">
      <div class="tw-flex-1 sm:tw-mx-0">
        <!-- Upload button -->
        <button
          type="button"
          class="tw-items-center tw-px-4 tw-py-3 tw-border tw-border-transparent tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-gray-100 hover:tw-bg-gray-200 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-500"
          @click="addPhoto()"
        >

          <icon v-show="!isLoading" :icon="['fal', 'camera']" style="height:1rem"></icon>
          <clip-loader
            :loading="isLoading"
            color="#888888"
            size="14px"
            class="tw-inline tw-align-middle"
          ></clip-loader>
        </button>
      </div>
      <div class="tw-flex-1 tw-min-w-0">
        <!-- Image Preview -->
        <div
          v-if="file"
          class="tw-rounded tw-mt-1 tw-h-10 tw-w-10 focus:tw-outline-none tw-ring-2 tw-ring-offset-2 tw-ring-gray-500"
          :style="{ backgroundImage: `url(${file})` }"
          style="background-size: cover; background-repeat: no-repeat"
        ></div>
        <!-- File input (hidden) -->
        <form @submit.prevent="upload" style="display: none !important">
          <input
            type="file"
            name="file"
            ref="fileInput"
            @change="upload()"
            style="opacity: 0; height: 0px; width: 0px"
          />
        </form>
      </div>
    </div>

    <!-- Validation errors -->
    <b-progress v-if="error" :value="100" variant="danger" class="mb-3">
      <b-progress-bar :value="100">Error uploading attachment</b-progress-bar>
    </b-progress>
  </div>
</template>

<script>
import axios from "axios";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  name: "UploadImageInput",
  components: {
    ClipLoader,
  },
  data() {
    return {
      file: "",
      media: 0,
      uploading: "",
      error: false,
      isLoading: false,
    };
  },
  methods: {
    addPhoto() {
      // Opens the file explore on Desktop, opens the camera roll on mobile.
      this.$refs.fileInput.click();
    },
    upload(event) {
      var formData = new FormData();
      // If no file attached, halt.
      if (
        !this.$refs.fileInput.files ||
        this.$refs.fileInput.files.length < 1
      ) {
        return;
      }

      this.isLoading = true;
      var imageFile = this.$refs.fileInput.files[0];
      formData.append("media", imageFile, imageFile.name);

      axios
        .post(process.env.VUE_APP_API_ROOT + "attachments/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.$emit("updateImage", res.data.id);
          this.file = res.data.media;
        })
        .catch((error) => {
          this.error == true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style>
</style>
