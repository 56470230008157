<template>
    <transition
        enter-active-class="tw-transition tw-ease-out tw-duration-100"
        enter-class="tw-transform tw-opacity-0 tw-scale-95"
        enter-to-class="tw-transform tw-opacity-100 tw-scale-100"
        leave-active-class="tw-transition tw-ease-in tw-duration-75"
        leave-class="tw-transform tw-opacity-100 tw-scale-100"
        leave-to-class="tw-transform tw-opacity-0 tw-scale-95"
    >
        <div
            v-if="active"
            class="tw-z-10 tw-mx-3 tw-origin-bottom tw-absolute tw-bottom-16 tw-right-0 tw-left-0 tw-mt-1 tw-rounded-md tw-shadow-lg"
        >
            <div
                class="tw-rounded-md tw-bg-white tw-shadow-xs"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
            >
                <slot />
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "ProfileDropdownContent",
    inject: ["sharedState"],
    computed: {
        active() {
            return this.sharedState.active;
        }
    }
};
</script>