<template>
  <div class="tw-bg-white tw-shadow tw-px-4 tw-py-5 sm:tw-rounded-lg sm:tw-p-6">
    <div v-if="isAdmin" class="tw-relative tw-h-8">
      <button class="tw-absolute tw-top-0 tw-right-0 tw-py-1 tw-px-2" @click="editing = !editing">
          <icon v-if="!editing" :icon="['fal','pen']" fixed-width class="m-0" style="font-size: 0.8rem" />
          {{ editing ? "Cancel" : "Edit" }}
        </button>
    </div>
    <div class="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6">
      <div class="md:tw-col-span-1">
        <h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-red-600">
          Emergency information
        </h3>
        <p class="tw-mt-1 tw-text-sm tw-text-red-500">
          Any emergency information or procedures that should be followed for the event
        </p>
      </div>
      <div class="tw-mt-5 md:tw-mt-0 md:tw-col-span-2">
        <div class="tw-space-y-6">
          <transition 
          enter-active-class="tw-transition tw-ease-out tw-duration-300"
          enter-class="tw-opacity-0"
          enter-to-class="tw-opacity-100"
          appear
          >
          <TeamEmergencyInfoForm :team="currentTeam" v-if="editing" @cancel="editing = false" />
          
          <div v-else class="tw-grid tw-grid-cols-3 tw-gap-6">
            <div
              v-if="currentTeam.emergency_info"
              class="tw-col-span-3 sm:tw-col-span-2"
            >
              <p v-html="currentTeam.emergency_info"></p>
            </div>
            <ZeroEventEmergInfoPanel
              v-if="!currentTeam.emergency_info"
              class="tw-col-span-3 sm:tw-col-span-3"
            />
          </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import ZeroEventEmergInfoPanel from "@/components/Team/ZeroEventEmergInfoPanel.vue";
import TeamEmergencyInfoForm from "./TeamEmergencyInfoForm.vue";

export default {
  name: "TeamEmergencyInfo",
  components: {
    ZeroEventEmergInfoPanel,
    TeamEmergencyInfoForm
},
  data() {
    return {
      editing: false,
    };
  },
 computed: {
    ...mapGetters("user", ["currentTeam", "isAdmin"]),
  }
};
</script>