<template>
  <div id="ActionSheet">
    <EventBottomNav v-on:openActionSheet="openActionSheet"></EventBottomNav>
    <b-modal
      hide-header
      hide-footer
      v-model="actionSheetOpen"
      dialog-class="action-sheet-dialog"
      content-class="action-sheet-content"
      body-class="w-100"
    >
      <h3 class="mt-2 text-risky d-inline">Actions</h3>
      <b-row class="mt-2">
        <table
          class="table table-responsive table-hover table-borderless text-dark mb-2"
          style="font-size: 1.025rem; font-weight: 300 !important;"
        >
          <tbody>
            <!-- <AddMessageActionButton type="sheet" v-on:closeActionSheet="closeActionSheet"></AddMessageActionButton> -->
            <UploadImageActionButton
              v-on:uploading="uploadingStatus"
              v-on:closeActionSheet="closeActionSheet"
              v-on:update-attachments="updateAttachments"
            ></UploadImageActionButton>
            <ImRespondingActionButton v-on:closeActionSheet="closeActionSheet"></ImRespondingActionButton>
            <ImMonitoringActionButton v-on:closeActionSheet="closeActionSheet"></ImMonitoringActionButton>
            <UpdateSeverityActionButton v-on:closeActionSheet="closeActionSheet"></UpdateSeverityActionButton>
            <ESOCalledActionButton v-on:closeActionSheet="closeActionSheet"></ESOCalledActionButton>
            <ResolveActionButton v-if="hasFeatureEnabled('incident_resolve')" v-on:closeActionSheet="closeActionSheet"></ResolveActionButton>
          </tbody>
        </table>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import EventBottomNav from "@/components/Events/EventBottomNav-mobile";
import ImRespondingActionButton from "@/components/Events/Actions/ImRespondingActionButton";
import ImMonitoringActionButton from "@/components/Events/Actions/ImMonitoringActionButton";
import UpdateSeverityActionButton from "@/components/Events/Actions/UpdateSeverityActionButton";
import AddMessageActionButton from "@/components/Events/Actions/AddMessageActionButton";
import ESOCalledActionButton from "@/components/Events/Actions/ESOCalledActionButton";
import UploadImageActionButton from "@/components/Events/Actions/UploadImageActionButton";
import ResolveActionButton from "@/components/Events/Actions/ResolveActionButtonV2";

export default {
  name: "EventActionSheet",
  props: ["visible"],
  components: {
    EventBottomNav,
    ImRespondingActionButton,
    ImMonitoringActionButton,
    UpdateSeverityActionButton,
    AddMessageActionButton,
    ESOCalledActionButton,
    UploadImageActionButton,
    ResolveActionButton
  },
  data() {
    return {
      actionSheetOpen: false
    };
  },
  computed: {
      ...mapGetters("user", ["hasFeatureEnabled"]),
  },
  methods: {
    openActionSheet() {
      this.actionSheetOpen = true;
    },
    closeActionSheet() {
      // console.log("close action sheet 1");
      this.actionSheetOpen = false;
    },
    updateAttachments(event) {
      // console.log("action sheet recieved: update attachments now");
      this.$emit("update-attachments", event);
    },
    uploadingStatus() {
      // console.log("action sheet recieved: uploading now");
      this.$emit("uploading", event);
    }
  }
};
</script>

<style scoped>
/* .table th, .table td {
  border-top: 1px solid rgba(0,0,0,.125);
} */
</style>
