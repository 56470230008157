<template>
  <div class="tw-font-sans">
    <div
      class="tw-mx-auto tw-max-w-3xl tw-flex tw-items-center tw-justify-between tw-my-6"
    >
      <div class="tw-flex-1 tw-min-w-0">
        <h2
          class="tw-font-extralight tw-leading-7 tw-text-gray-900 sm:tw-text-3xl sm:tw-truncate"
        >
          Report incident with <a href="https://riskfacilitator.com/risky" target="_blank"><span class="tw-text-orange-500">risky.</span>live</a>
        </h2>
      </div>
    </div>
    <form v-if="!submitted" @submit.prevent="validateForm" class="tw-mb-12">
      <div class="tw-mx-auto tw-max-w-3xl">
        <div class="">
          <fieldset>
            <ul class="space-y-4" role="radiogroup">
              <LocationPanel
                :selected="step === 1"
                :latitude="latitude"
                :longitude="longitude"
                :customLocation="customLocation"
                :validate="isValidating"
                :errors="errors"
                @updateErrors="updateErrors"
                @updateLocation="updateLocation"
                @nextStep="nextStep"
                class="tw-mt-4 lg:tw-mt-0"
              />

              <IncidentNamePanel
                :selected="step === 2"
                :title="title"
                :errors="errors"
                :validate="isValidating"
                @updateTitle="updateTitle"
                @updateImage="updateImage"
                @updateErrors="updateErrors"
                @nextStep="nextStep"
                class="tw-mt-4 lg:tw-mt-0"
              />
              <ChooseTagsPanel
                :selected="step === 3"
                :tags="tags_ids"
                :errors="errors"
                :validate="isValidating"
                @updateTags="updateTags"
                @updateErrors="updateErrors"
                @nextStep="nextStep"
                class="tw-mt-4 lg:tw-mt-0"
              />
              <ChooseReactionPanel
                :selected="step === 4"
                :reaction="reaction"
                :errors="errors"
                :validate="isValidating"
                @updateReaction="updateReaction"
                @updateErrors="updateErrors"
                @nextStep="nextStep"
                class="tw-mt-4 lg:tw-mt-0"
              />
              <ChooseSeverityPanel
                :selected="step === 5"
                :severity="severity"
                :errors="errors"
                :validate="isValidating"
                @updateSeverity="updateSeverity"
                @updateErrors="updateErrors"
                @nextStep="nextStep"
                class="tw-mt-4 lg:tw-mt-0"
              />
              <!-- <CERatingPanel
                :selected="step === 6"
                :control_effectiveness="control_effectiveness"
                :errors="errors"
                :validate="isValidating"
                @updateCE="updateCE"
                @updateErrors="updateErrors"
                @nextStep="nextStep"
                class="tw-mt-4 lg:tw-mt-0"
              /> -->
              <SuggestActionPanel
                :selected="step === 7"
                :solution="solution"
                :errors="errors"
                :validate="isValidating"
                @updateSolution="updateSolution"
                @updateErrors="updateErrors"
                @nextStep="nextStep"
                class="tw-mt-4 lg:tw-mt-0"
              />
            </ul>
          </fieldset>
          <button
            type="submit"
            class="tw-inline-flex tw-w-full tw-w-auto tw-justify-center tw-py-3 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-text-sm tw-font-medium tw-text-white tw-bg-orange-500 hover:tw-bg-orange-600 focus:tw-outline-none"
            v-bind:class="[isSubmitting ? 'disabled:opacity-50' : '']"
          >
            <clip-loader
              :loading="isSubmitting"
              color="#f8f8f8"
              size="15px"
            ></clip-loader>
            <span :class="[isSubmitting ? 'tw-ml-1' : '']">Submit report</span>
          </button>
        </div>
      </div>
    </form>
    <div v-if="submitted">
    <div    
      class="tw-mx-auto tw-flex tw-items-center tw-justify-center tw-h-32 tw-w-32"
    >
      <IncidentReported v-if="submitted"/>
    </div>
    <div class="tw-mt-3 tw-text-center sm:tw-mt-5">
      <h3
        class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900"
        id="modal-headline"
      >
        Thank you for submitting your report.
      </h3>
      <div class="tw-mt-4">
        <button
            type="submit"
            @click="submitted = false"
            class="tw-inline-flex tw-w-full tw-w-auto tw-justify-center tw-py-3 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-text-sm tw-font-medium tw-text-white tw-bg-orange-500 hover:tw-bg-orange-600 focus:tw-outline-none"
          >
            Report another incident
          </button>
        <!-- <p class="tw-text-sm tw-text-gray-500">
          
        </p> -->
      </div>
    </div>
    </div>
  </div>
</template>

<script>

import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import VueGeolocation from "vue-browser-geolocation";
Vue.use(VueGeolocation);
Vue.use(BootstrapVue)
import "@/lib/vendor/font-awesome";
import { Emoji } from "emoji-mart-vue-fast";
import { isMobileOnly } from 'mobile-device-detect';

import axios from "axios";

import LocationPanel from "@/components/Events/Create/LocationPanel";
import IncidentNamePanel from "@/components/Events/Create/IncidentNamePanel";
import ChooseTagsPanel from "@/components/Events/Create/ChooseTagsPanel";
import ChooseReactionPanel from "@/components/Events/Create/ChooseReactionPanel";
import ChooseSeverityPanel from "@/components/Events/Create/ChooseSeverityPanel";
import CERatingPanel from "@/components/Events/Create/CERatingPanel";
import SuggestActionPanel from "@/components/Events/Create/SuggestActionPanel";
import IncidentReported from "@/components/ui/Illustrations/IncidentReported";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";


export default {
  name: "create-event",
  props: ["token", "context", "user_id", "email", "name", "mobile", "race_name", "country"],
  components: {
    ClipLoader,
    IncidentNamePanel,
    LocationPanel,
    ChooseTagsPanel,
    ChooseReactionPanel,
    ChooseSeverityPanel,
    CERatingPanel,
    SuggestActionPanel,
    Emoji,
    IncidentReported
  },
  data() {
    return {
      submitted: false,
      tags_ids: [],
      tags: [],
      title: "",
      severity: null,
      control_effectiveness: "100",
      solution: "",
      errors: ["location", "title", "tags", "severity", "reaction"],
      media: 0,
      isLoading: false,
      isSubmitting: false,
      isValidating: false,
      currentLoc: null,
      selectedLoc: null,
      reaction: "",
      latitude: null,
      longitude: null,
      customLocation: false,
      step: 1,
    };
  },
  created() {
    Vue.prototype.$http = axios;
  },
  methods: {
    nextStep(value) {
      this.step = value;
    },
    updateErrors(value) {
      //   console.log(value.name);
      if (value.error === true) {
        // Field contains validation error
        if (!this.errors.includes(value.name)) {
          // Add the field from array if it isn't already in it
          this.errors.push(value.name);
        }
      } else {
        // Field passed validation
        if (this.errors.includes(value.name)) {
          // Remove the field from array if it exists
          this.errors.splice(this.errors.indexOf(value.name), 1);
        }
      }
    },
    validateForm() {
      //   this.errors = []; // clear existing errors
      this.isValidating = false; // reset previous checks
      this.isValidating = true; // trigger all child components to recheck validations
      //   console.log(this.errors);
      if (this.errors.length === 0) {
        this.onSubmit();
      }
      //   var errors = this.$watch(
      //     "errors",
      //     function () {
      //         console.log(this.errors)
      //       if (this.errors.length === 0) {
      //         this.onSubmit();
      //       }
      //       if (errors) {

      //           console.log(this.errors)
      //         // if (this.errors.length === 0) {
      //         //   this.onSubmit();
      //         // }
      //         errors();
      //       }

      //     },
      //     { immediate: true }
      //   );
    },
    updateTags(array) {
      var tags_ids = [];
      tags_ids = array.map((item) => item);
      this.tags_ids = tags_ids;
    },
    updateImage(value) {
      this.media = value;
    },
    updateTitle(value) {
      this.title = value;
    },
    updateSeverity(value) {
      this.severity = value;
    },
    updateReaction(value) {
      this.reaction = value;
    },
    updateCE(value) {
      this.control_effectiveness = value;
    },
    updateSolution(value) {
      this.solution = value;
    },
    updateLocation(value) {
      this.latitude = value.lat;
      this.longitude = value.lng;
    },
    updateGmapLocation(value) {
      this.latitude = value.lat();
      this.longitude = value.lng();
      this.customLocation = true;
    },
    async onSubmit() {
      if (this.isSubmitting === false) {
        this.isSubmitting = true;
        console.log(this.$props.context)

        // if (this.$props.context) {
        //   var context = JSON.parse(this.$props.context)
          
        // } else {
        //   var form_data = {
        //     ...this.$data,
        //   }
        // }

        var form_data = {
            ...this.$data,
            context: {
              user_id: this.$props.user_id,
              email:this.$props.email,
              name: this.$props.name,
              mobile: this.$props.mobile,
              race_name: this.$props.race_name,
              country: this.$props.country,
            },
        }

        axios
          .post(process.env.VUE_APP_API_ROOT + "incidents/report_something/?auth_token=" + this.$props.token, form_data)
          .then((response) => {
            this.isSubmitting = false;
            this.$emit("completed", response.data);
            this.submitted = true;
            this.tags_ids = []
            this.title = ""
            this.severity = null
            this.solution = ""
          })
          .catch((error) => {
            // handle authentication and validation errors here
            // this.errors = error.response.data.errors;
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>


<style src="@/assets/tailwind.css"></style>
<style src="emoji-mart-vue-fast/css/emoji-mart.css"></style>

<style lang="scss">
/* Import Bootstrap Vue Styles */
@import "~bootstrap-vue/dist/bootstrap-vue.css";
// Import Main styles for this application
@import "@/assets/scss/style";
</style>
