<template>
  <transition 
        enter-active-class="tw-transition tw-ease-out tw-duration-100"
        enter-class="tw-transform tw-opacity-0 tw-scale-95"
        enter-to-class="tw-transform tw-opacity-100 tw-scale-100"
        leave-active-class="tw-transition tw-ease-in tw-duration-75"
        leave-class="tw-transform tw-opacity-100 tw-scale-100"
        leave-to-class="tw-transform tw-opacity-0 tw-scale-95">
    <div
      v-if="active"
      class="tw-mx-3 tw-origin-top-right tw-absolute tw-right-7 tw-top-0 tw-w-48 tw-mt-1 tw-rounded-md tw-shadow-lg tw-z-10 tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5" role="menu"
    >
      <slot/>
    </div>
  </transition>
</template>

<script>
export default {
  name: "AppDropdownContent",
  inject: ["sharedState"],
  computed: {
    active() {
      return this.sharedState.active;
    }
  }
};
</script>
