<template>
  <div v-click-outside="hideMenu">
    <label id="listbox-label" class="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
      {{ label }}
    </label>
    <div class="tw-relative tw-mt-1">
      <button
        type="button"
        class=" tw-bg-white tw-relative tw-w-full tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-pl-3 tw-pr-10 tw-py-2 tw-text-left tw-cursor-default focus:tw-outline-none focus:tw-ring-1 focus:tw-border-blue-500 focus:tw-ring-blue-500 sm:tw-text-sm tw-h-38 tw-flex tw-place-items-center"
        style="min-height: 38px"
        @click="onMenuToggle"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
      >
        <div class="tw-flex tw-flex-wrap tw--mb-1">
          <span
            class="tw-flex-shrink-0 tw-inline-block tw-px-2 tw-py-1 tw-mb-1 tw-mr-1 tw-text-xs tw-font-medium tw-text-blue-800 tw-bg-blue-100 tw-rounded-sm "
          >
            {{ value.length }} out of {{ options.length }}
          </span>
        </div>
        <svg class="tw-absolute tw-right-0 tw-w-5 tw-h-5 tw-text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </button>
      <ul
        class="tw-absolute tw-z-10 tw-w-full tw-py-1 tw-mt-1 tw-overflow-auto tw-text-base tw-bg-white tw-rounded-md tw-shadow-lg tw-max-h-60 tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none sm:tw-text-sm"
        :class="{ 'tw-hidden': !isOpened }"
        role="listbox"
        aria-labelledby="listbox-label"
        aria-activedescendant="listbox-option-3"
      >
        <li
          v-for="option in options"
          v-bind:key="option"
          class="tw-relative tw-py-2 tw-pl-8 tw-pr-4 tw-text-gray-900 tw-cursor-pointer tw-select-none hover:tw-bg-gray-100"
          id="listbox-option-0"
          role="option"
          @click="onSelect(option)"
        >
          <icon v-if="checkIsSelected(option)" :icon="['fal', 'check']" class="tw-absolute tw-left-0 tw-top-1/4 tw-ml-2.5 tw-h-5 tw-w-5"/>
          <span class="tw-block tw-font-normal tw-truncate tw-ml-2">
            <slot name="option" v-bind="{ option }">
              {{ option }}
            </slot>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: "FormMultiSelect",
  components: {
  },
  props: {
    name: String,
    options: {
      type: Array,
      default: () => []
    },
    value: {
      value: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isOpened: false,
      selected: []
    };
  },
  methods: {
    onMenuToggle() {
      this.isOpened = !this.isOpened;
    },
    hideMenu() {
      this.isOpened = false;
    },
    checkIsSelected(option) {
      return this.selected.includes(option);
    },
    onSelect(option) {
      const isOptionSelected = this.checkIsSelected(option);
      this.selected = isOptionSelected
        ? this.selected.filter(selection => selection !== option)
        : [...this.selected, option];

      this.$emit("select", this.name, this.selected);
    }
  }
};
</script>
