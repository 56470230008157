<template>
    <div class="tw-flex tw-items-center tw-flex-grow tw-h-5">
        <div class="tw-mx-2">
            <icon :icon="['fal', 'search']" class="tw-text-gray-500" />
        </div>
        <input
            type="text"
            v-model="input_value"
            v-on:input="updateParent"
            maxlength="150"
            :placeholder="placeholder"
            class="
                tw-text-sm
                tw-px-1
                tw-py-0.75
                tw-w-full
                tw-bg-transparent
                tw-text-body
                tw-border-0
                tw-placeholder-grey
                focus:tw-outline-none
                tw-text-gray-700
            "
            :class="{ 'tw-text-red-500': field_error[0] }"
        />
        <InvalidInput :message="field_error[1]" :show="field_error[0]">
        </InvalidInput>
    </div>
</template>

<script>
import InvalidInput from "@/components/ui/InvalidInput";

export default {
    name: "TableSearchField",
    components: {
        InvalidInput,
    },
    props: {
        value: {
            type: String,
            default: "",
        },
        label: String,
        requiredField: Boolean,
        hideLabel: Boolean,
        name: {
            type: String,
            required: true,
        },
        error: Array,
        text_type: {
            type: String,
            default: "short",
        },
        placeholder: String,
    },
    data() {
        return {
            input_value: this.value,
            error_message: this.error ? this.error[1] : "",
            error_show: this.error ? this.error[0] : false,
        };
    },
    computed: {
        field_error() {
            if (this.error) {
                this.error_message = this.error[1];
                this.error_show = this.error[0];
                return [this.error_show, this.error_message];
            }
            return [false, ""];
        },
        long_text() {
            return this.text_type && this.text_type === "long";
        },
    },
    methods: {
        updateParent() {
            this.$emit("textchange", this.name, this.input_value);
        },
    },
    watch: {
        value: function () {
            this.input_value = this.value;
        },
    },
};
</script>
