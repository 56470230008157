<template>
  <Layout>
    <PageHeader slot="header" title="Add message" />

    <div class="row mb-3">
      <div class="col">
        <AddEventMessageForm />
      </div>
    </div>
  </Layout>
</template>

<script>
import AddEventMessageForm from "@/components/Events/Forms/AddEventMessageForm";

export default {
    name: "add-message",
    components: {
      AddEventMessageForm
    }
}
</script>
