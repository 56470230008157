<template>
  <Layout>
    <!-- <div class="row pt-3 pb-4">
      <div class="col">
        <h3>Add message to incident</h3>
      </div>
    </div> -->
    <AddEventMessageForm  class="tw-mt-10"></AddEventMessageForm>
  </Layout>
</template>

<script>
import AddEventMessageForm from "@/components/Events/Forms/AddEventMessageForm";
import Layout from '../../../components/Layout.vue';

export default {
  name: "add-comment",
  components: {
    Layout,
    AddEventMessageForm 
  }
};
</script>
