<template>
  <tr @click="imMonitoring()">
    <th scope="row" width="1%" class="pl-3 pr-2 text-center">
      <icon :icon="['fal','mobile-alt']" />
    </th>
    <td class="pl-0">I'm monitoring</td>
  </tr>
</template>

<script>
export default {
  name: "ImMonitoringActionButton",
  methods: {
    async imMonitoring() {
      this.$emit("closeActionSheet");
      this.$http
        .post(
          process.env.VUE_APP_API_ROOT +
            "incidents/" +
            this.$route.params.eventID +
            "/monitoring/",
          this.$data
        )
        .then(response => {
          this.$emit("completed", response.data.data);
          //this.$router.go();
        })
        .catch(error => {
          // handle authentication and validation errors here
          this.errors = error.response.data.errors;
        });
    }
  }
};
</script>

<style>
</style>