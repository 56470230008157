<template>
  <Emoji :data="index" :emoji="reaction" :size="size ? size : 20" set="apple" class="tw-align-middle" style="padding: 0;"/>
</template>


<script>
import data from 'emoji-mart-vue-fast/data/all.json'
import { Emoji, EmojiIndex } from 'emoji-mart-vue-fast'
import 'emoji-mart-vue-fast/css/emoji-mart.css'

export default {
  name: "EventReaction",
  props: ["reaction", "size"],
  components: {
    Emoji
  },
  data() {
      return {
          index: new EmojiIndex(data),
      }
  }
};
</script>
