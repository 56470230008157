<template>
    <span id="tags">
        <span
            v-for="item in tags"
            :key="item.id"
            class="tw-mr-1 tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium tw-bg-gray-100 tw-text-gray-800"
        >
            {{ item.name }}
        </span>
    </span>
</template>

<script>
import axios from "axios";

export default {
    name: "EventTags",
    props: ['tags'],
    components: {

    },
    data() {
        return {
            errors: "",
            isLoading: true
        };
    }
};
</script>
