<template>
    <router-link
      :key="event.id"
      :to="{ name: 'Incident', params: { eventID: event.id}}"
      class="custom-card"
    >
      <div class="border-0 shadow-sm card">
        <div v-bind:style="{ backgroundImage: 'url(' + getImageUrl() + ')' }" style="height: 165px; background-size: cover; background-repeat: no-repeat; background-position: center;" class="w-100 bg-light"></div>
        <div class="card-body">
          <h5 class="card-title">{{ event.title }}</h5>
          <h6 class="m-0 mb-2 card-subtitle text-muted"><EventTimestamp :date_created="moment(event.created_at_local).format('DD/MM/YYYY')" :time_created="moment(event.created_at_local).format('H:mm')"></EventTimestamp></h6>
          <h5>
            <EventTags :tags="event.tags"></EventTags>
          </h5>
          <h5 id="card-meta" class="mt-3 card-text">
            <EventSeverityIndicator :severity="event.severity" class="mr-2"></EventSeverityIndicator>
            <EventCERating v-if="event.control_effectiveness" :ce="event.control_effectiveness" class="mr-2"></EventCERating>
            <EventReaction v-if="event.reaction" :reaction="event.reaction" class="mr-2"></EventReaction>
            <EventContributorAvatars :reporter="event.created_user" class="tw-float-right"></EventContributorAvatars>
          </h5>
        </div>
      </div>
    </router-link>
</template>

<script>
import axios from "axios";
import EventTags from "@/components/Events/Meta/EventTags";
import EventSeverityIndicator from "@/components/Events/Meta/EventSeverityIndicator";
import EventCERating from "@/components/Events/Meta/EventCERating";
import EventReaction from "@/components/Events/Meta/EventReaction";
import EventTimestamp from "@/components/Events/Meta/EventTimestamp";
import EventContributorAvatars from "@/components/Events/Meta/EventContributorAvatars";
import EventStatusTag from "@/components/Events/Meta/EventStatusTag";

export default {
  name: "event-card",
  props: ['event'],
  components: {
    EventTags,
    EventSeverityIndicator,
    EventCERating,
    EventReaction,
    EventTimestamp,
    EventContributorAvatars,
    EventStatusTag
  },
  data() {
    return {
      
    };
  },
  methods: {
    getImageUrl() {
      if (this.event.image_url !== null) {
        return this.event.image_url
      } else {
        return this.event.map_url
      }
    }
  }
};
</script>
