import store from "@/state/store.js";

// authenticate user and initialise data
export function requireAuthenticated(to, from, next) {
  store
    .dispatch("init")
    .then(() => {
      if (!store.getters["auth/isLoggedIn"]) {
        next("/login");
      } else {
        next();
      }
    })
    .catch(err => {
      next("/login");
    });
}

// ensure that user is not logged in
export function requireUnauthenticated(to, from, next) {
  store
    .dispatch("auth/refresh")
    .then(() => {
      if (store.getters["auth/isLoggedIn"]) {
        next("/");
      } else {
        next();
      }
    })
    .catch(() => {
      next();
    });
}

export function redirectLogout(to, from, next) {
  store.dispatch("auth/logout").then(() => {
    next("/login");
  });
}

/**
*** The functions below have not been tailored to risky.live and should be updated to
*** suit risky.live billing and onboarding when needed.
**/

// ensure user has completed onboarding
export function requireOnboarded(to, from, next) {
  if (!store.getters["user/currentTeam"] || !store.getters["billing/hasSubscription"]) {
    next("/onboarding");
  } else {
    next();
  }
}

// select appropriate onboarding step
export function redirectOnboarding() {
  const { getters } = store;

  if (!getters["currentTeam"]) {
    return "/onboarding/1";
  } else if (!getters["billing/hasSubscription"] && getters["billing/selectedPrice"] === null) {
    return "/onboarding/2";
  } else if (!getters["billing/hasSubscription"] && getters["billing/selectedPrice"] !== null) {
    return "/onboarding/3";
  } else if (getters["user/currentTeam"] && getters["billing/hasSubscription"]) {
    return "/onboarding/4";
  }
}

// redirect from inappropriate onboarding step
export function checkOnboardingStage(to, from, next) {
  const dest = to.path,
    new_team = to.query.create_team === "true",
    hasSubscription = store.getters["billing/hasSubscription"],
    team = store.getters["user/currentTeam"],
    { getters } = store;

  switch (dest) {
    case "/onboarding/1":
      // allow only user without a current team to create a team
      team && !new_team ? next(redirectOnboarding()) : next();
      break;
    case "/onboarding/2":
      // allow only user with a team, but without a subscription to
      // select subscription for current team
      team && !hasSubscription && getters["billing/selectedPrice"] === null
        ? next()
        : next(redirectOnboarding());
      break;
    case "/onboarding/3":
      // allow who has selected a subscription/price
      // to setup payment methods.
      team && !hasSubscription && getters["billing/selectedPrice"] !== null
        ? next()
        : next(redirectOnboarding());
      break;
    case "/onboarding/4":
      // allow the final step of onboarding to be shown to users after
      // subscription is added. (This will mean if onboarded users navigate to onboarding they
      // will just go to this page by default - which is acceptable)
      team && hasSubscription ? next() : next(redirectOnboarding());
      break;
    default:
      // for all other paths
      // redirect based on team and subscription status
      next(redirectOnboarding());
      break;
  }
}
