<template>
  <div>
    <h5 v-if="!isLoading && event.closed_at !== null" class="pt-2 pb-3 mb-0 text-center text-success">
      <icon :icon="['fal', 'check-double']"></icon> This event has been resolved!
    </h5>
    <div class="p-4 mb-4 bg-white shadow-sm">
      <h5 class="card-title">
          Summary
          <router-link :to="{ name: 'Description', params: { eventID: this.$route.params.eventID }}" class="float-right text-muted">
            <icon v-if="event.description" :icon="['fal', 'pen']" class="text-muted"></icon>
          </router-link>
      </h5>
      <p v-if="!event.description" >
        <router-link :to="{ name: 'Description', params: { eventID: this.$route.params.eventID }}" class="py-1 btn btn-link btn-block">
          <icon :icon="['fal', 'plus']"></icon>  Add more information
        </router-link>
      </p>
      <p v-else class="text-muted">{{ event.description }}</p>
      <p class="mb-0 text-muted">
        <EventTimestamp :date_created="moment(event.created_at_local).format('DD/MM/YYYY')" :time_created="moment(event.created_at_local).format('H:mm')"></EventTimestamp>
      </p>
    </div>
    <div v-if="!isLoading && event.closed_at !== null" class="p-4 mb-4 shadow-sm bg-light">
      <h5 class="card-title">Resolution</h5>
      <p class="text-muted">{{ event.resolution }}</p>
      <p class="mb-0 text-muted">
        Resolved <icon :icon="['fal', 'clock']" ></icon> {{ event.closed_at }}
      </p>
    </div>
    <div class="p-4 mb-4 bg-white shadow-sm">
      <div class="mb-2 row">
        <div class="col-6">
          <h5 class="card-title">Risk level</h5>
          <EventSeverityIndicator :severity="event.severity" label="True"></EventSeverityIndicator>
        </div>
        <div class="col-6">
          <h5 class="card-title">Time open</h5>
          <h5 class="text-danger">{{ event.time_ago }}</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <h5 class="card-title">Rating</h5>
          <h5 class="mb-0">
            <EventCERating :ce="event.control_effectiveness"></EventCERating>
          </h5>
        </div>
        <div class="col-6">
          <h5 class="card-title">Reaction</h5>
          <h5 class="mb-0">
            <EventReaction :reaction="event.reaction"></EventReaction>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventCERating from "@/components/Events/Meta/EventCERating";
import EventReaction from "@/components/Events/Meta/EventReaction";
import EventTimestamp from "@/components/Events/Meta/EventTimestamp";
import EventSeverityIndicator from "@/components/Events/Meta/EventSeverityIndicator";

export default {
  name: "EventDetailCard",
  props: ["event", "isLoading"],
  components: {
    EventReaction,
    EventCERating,
    EventTimestamp,
    EventSeverityIndicator
  },
  methods: {}
};
</script>
