<template>
  <div>
    <button
      v-for="team in teams"
      v-bind:key="team.id"
      @click="switchCurrentTeam(team.id)"
      class="group tw-flex tw-items-center tw-w-full tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus:tw-bg-gray-100 focus:tw-text-gray-900 tw-cursor-pointer"
    >
      <span class="tw-truncate tw-text-gray-700"><icon :icon="['fal', 'hashtag']" class="tw-mr-1"/>{{ team.name }}</span>
      <span
        v-if="team.id === currentTeamId && changingTeam !== team.id"
        class="tw-w-2.5 tw-h-2.5 tw-ml-auto tw-bg-orange-500 tw-rounded-full"
        aria-hidden="true"
      ></span>
      <clip-loader
        :loading="changingTeam === team.id"
        color="#63c2de"
        size="15px"
        class="tw-ml-auto"
      ></clip-loader>
    </button>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  components: {
    ClipLoader,
  },
  data() {
    return {
      changingTeam: null,
    };
  },
  computed: {
    teams() {
      return this.$store.getters['user/teams'];
    },
    currentTeamId() {
      if (this.$store.getters['user/currentTeam']) {
        return this.$store.getters['user/currentTeam'].id;
      }
    },
  },
  methods: {
    async switchCurrentTeam(teamID) {
      if (teamID !== this.currentTeamId) {
        this.changingTeam = teamID;
        this.$store
          .dispatch("user/switchTeam", this.changingTeam)
          .then((res) => {
            this.changingTeam = null;
            if (this.$route.name !== "Incidents") {
              this.$router.push({ name: "Incidents" });
            } else {
              this.$store.commit("closeSidebar");
            }
          })
          .catch((error) => {
            // handle authentication and validation errors here
            console.log(error);
            this.changingTeam = null;
          });
      } else {
        this.$store.commit("closeSidebar");
      }
    },
  },
};
</script>

<style>
</style>