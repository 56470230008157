<template>
  <tr @click="addMessage()">
    <th scope="row" width="1%" class="pl-3 pr-2 text-center">
      <icon :icon="['fal','comments']" />
    </th>
    <td class="pl-0">Add message</td>
  </tr>
</template>

<script>
export default {
  name: "AddMessageActionButton",
  methods: {
    addMessage() {
      this.$emit("closeActionSheet");
      this.$router.push({
        name: "Add comment",
        params: { eventID: this.$route.params.eventID }
      });
    }
  }
};
</script>

<style>
</style>