<template></template>
<script>
export default {
  props: {
    google: Object,
    map: Object,
    lat: null,
    lng: null
  },
  data() {
    return { marker: null };
  },
  mounted() {
    var myLatLng = new google.maps.LatLng(this.$props.lat, this.$props.lng); 
    const { Marker } = this.google.maps;
    this.marker = new Marker({
      position: myLatLng,
      map: this.map,
      //title: "Child marker!"
    });
  }
};
</script>