<template>
  <Layout id="event">
    <!-- Mobile title -->
    <div class="row py-0">
      <EventMediaCarousel
        v-if="!isLoading && attachments"
        :attachments="attachments"
        :map="event.map_url"
      ></EventMediaCarousel>
    </div>
    <div class="row mt-3 py-0">
      <!-- <div class="col-1" @click="$router.go(-1)">
          <i class="fas fa-angle-left text-secondary pr-1 fa-3x"></i>
      </div>-->
      <div class="col">
        <EventMobileTitleSkeleton v-if="isLoading"></EventMobileTitleSkeleton>
        <span v-else>
          <!-- <EventSeverityIndicator :severity="event.severity" class="d-inline align-text-bottom mr-1"></EventSeverityIndicator> -->
          <h3 class="mb-1 d-inline">{{ event.title }}</h3>
          <router-link
            :to="{ name: 'Rename', params: { eventID: event.id } }"
            class="text-muted float-right mr-2 pt-1 align-middle d-inline"
          >
            <icon :icon="['fal', 'ellipsis-h']" rotation="90" size="2x"></icon>
          </router-link>
        </span>
        <h5>
          <EventStatusTag v-if="!isLoading" :closed_at="event.closed_at" class="tw-inline"></EventStatusTag>
          <EventTags v-if="!isLoading" :tags="event.tags" ></EventTags>
        </h5>
      </div>
    </div>
    <!-- Errors -->
    <!-- <div v-if="errors" class="alert alert-danger" v-text="errors"></div> -->
    <div class="row pt-1">
      <div class="col col-mobile">
        <b-tabs justified>
          <b-tab class="p-3 mb-5" active>
            <!-- <template slot="title">
              <i class="fas fa-info-circle"></i>
            </template>-->
            <b-progress
              v-if="uploadingStatus"
              :value="100"
              variant="info"
              animated="animate"
              class="mb-3"
            >
              <b-progress-bar :value="100">Uploading attachment</b-progress-bar>
            </b-progress>
            <EventDetailCard :event="event" :isLoading="isLoading"></EventDetailCard>
            <!-- <h4 class="card-title">Attachments</h4> -->
            
            <WeatherAttachmentCard v-if="!isLoading && event.temp" :weather="event"></WeatherAttachmentCard>
            <div class="shadow-sm p-4 bg-light mb-3">
              <h5 class="card-title">
                Exact location
                <i class="fas fa-map-marker-alt float-right"></i>
              </h5>
              <p class="mb-0">Lat: {{ event.latitude }}, Long: {{ event.longitude }}</p>
            </div>
            <EventAttachments v-if="attachments" :attachments="attachments" :uploading="uploadingStatus"></EventAttachments>
            <ActivityTimeline v-if="!isLoading" :event_pk="event.id" :solution="event.solution"></ActivityTimeline>
            <p v-if="!isLoading && event.reporter" class="mb-0 pt-1 mb-5 text-center text-muted">
              <i class="fas fa-flag"></i>
              First reported by {{ event.created_user }}
            </p>
          </b-tab>
          <!-- <b-tab class="mb-5">
            <template slot="title">
              <i class="fas fa-stream"></i>
            </template>
          </b-tab>-->
          <!-- <b-tab> -->
          <!-- <template slot="title">
              <i class="fas fa-map-marked"></i>
          </template>-->
          <!-- <img class="card w-100 h-100" :src="staticMap()"/> -->
          <!-- <EventMapCard v-if="!isLoading" :lat="event.lat" :lng="event.long"></EventMapCard> -->
          <!-- </b-tab> -->
        </b-tabs>
      </div>
    </div>
    <EventActionSheet
      v-if="!isLoading && event.closed_at == null"
      v-on:updateActivity="updateActivity = true"
      @update-attachments="updateAttachments"
      @uploading="uploading"
    ></EventActionSheet>
    <!-- <EventReopenPanel v-if="!isLoading && event.closed_at !== null"></EventReopenPanel> -->
  </Layout>
</template>

<script>
import axios from "axios";
import EventMobileTitleSkeleton from "@/components/Events/EventMobileTitleSkeleton";
import EventDetailCard from "@/components/Events/EventDetailCard";
import EventMapCard from "@/components/Events/EventMapCard";
import ActivityTimeline from "@/components/Activity/ActivityTimeline";
import EventMoreModal from "@/components/Events/EventMoreModal";
import EventTags from "@/components/Events/Meta/EventTags";
import EventStatusTag from "@/components/Events/Meta/EventStatusTag";
import EventSeverityIndicator from "@/components/Events/Meta/EventSeverityIndicator";
import EventActionSheet from "@/components/Events/EventActionSheet";
import EventAttachments from "@/components/Events/EventAttachments";
import EventReopenPanel from "@/components/Events/EventReopenPanel";
import WeatherAttachmentCard from "@/components/Events/WeatherAttachmentCard";

import EventMediaCarousel from "@/components/Events/Meta/EventMediaCarousel";

export default {
  name: "view-event",
  components: {
    EventDetailCard,
    EventMapCard,
    ActivityTimeline,
    EventMoreModal,
    EventMobileTitleSkeleton,
    EventTags,
    EventStatusTag,
    EventSeverityIndicator,
    EventAttachments,
    EventActionSheet,
    EventReopenPanel,
    WeatherAttachmentCard,
    EventMediaCarousel
  },
  data() {
    return {
      event: [],
      attachments: [],
      tags_selected: null,
      tags_available: [],
      description: "",
      severity: "",
      control_effectiveness: "",
      errors: "",
      isLoading: true,
      updateEvent: false,
      updateActivity: false,
      uploadingStatus: false
    };
  },
  methods: {
    updateAttachments(e) {
      console.log("updating attachments now")
      this.event.attachments.data.unshift(e);
      console.log(this.event.attachments.data);
    },
    uploading(e) {
      console.log("uploading status toggled")
      this.uploadingStatus = e;
      console.log("uploading status emit");
    }
  },
  async created() {
    // try {
    //   let uri = process.env.VUE_APP_API_ROOT + "tags";
    //   const response = await axios.get(uri);
    //   this.tags_available = response.data.data;
    // } catch (e) {
    //   // catch exception
    // }

    try {
      let uri =
        process.env.VUE_APP_API_ROOT + "incidents/" + this.$route.params.eventID + "/";
      const response = await axios.get(uri);
      this.event = response.data;
    } catch (e) {
      // catch exception
      console.log(e);
    }
    try {
      let uri =
        process.env.VUE_APP_API_ROOT + "incidents/" + this.$route.params.eventID + "/attachments/";
      const response = await axios.get(uri);
      this.attachments = response.data.results;
    } catch (e) {
      // catch exception
      console.log(e);
    }
    this.isLoading = false;
  },
  watch: {
    updateActivity: function(val) {
      this.fullName = val + " " + this.lastName;
    },
    lastName: function(val) {
      this.fullName = this.firstName + " " + val;
    }
  },
  mounted: function() {}
};
</script>